import { useEffect, useContext, useState } from "react";
import UserContext from "../../UserContext";
import { useNavigate } from "react-router-dom";
import EscalavisualanalogicaHorizontal from "../../components/Cuestionarios/EscalavisualanalogicaHorizontal";
import PreguntasColumnasIntercaladas from "../../components/Cuestionarios/PreguntasColumnasIntercaladas";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { FaArrowLeft } from "react-icons/fa";
import TituloyFlecha from "../../components/TituloyFlecha";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import "./CuestionarioSocioDemo.css";
import CheckboxesCuestionarioSD from "../../components/Cuestionarios/CheckboxesCuestionarioSD";

const Cuestionario2 = () => {
  const navigate = useNavigate();
  const [respuestaEscala, setRespuestaEscala] = useState({});
  const email = useContext(UserContext);
  const [t] = useTranslation("global");
  const [todoRespondido, SetTodoRespondido] = useState(true);
  const numero_cuestionario = 2;
  const [parte2, setParte2] = useState(false);
  const [windowWidth] = useState(window.innerWidth);
  // Utilizar un objeto para almacenar las respuestas
  const [respuestas, setRespuestas] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [navigate]);

  const handleRespuestaEscala = (respuesta) => {
    console.log("Respuesta Escala:", respuesta);
    setRespuestaEscala(respuesta);
  };

  const handleRespuestasChange = (nuevasRespuestas) => {
    setRespuestas(nuevasRespuestas);
    // Aquí puedes realizar cualquier otra acción con las respuestas
    console.log("Respuestas: " + nuevasRespuestas);
  };

  function cambiarparte(valor) {
    SetTodoRespondido(true);
    setParte2(valor);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  const mostrarRespuestasEnConsolaMovil = async () => {
    const respuestasEnTexto = questions
      .map((pregunta) => `"${respuestas[pregunta] || " "}"`)
      .join(",") +

      (',"' + respuestaEscala + '"');
      const puntuacion = `"${traducirArray(questions.map(pregunta => respuestas[pregunta] || " ")).join('","')}","${respuestaEscala}"`;

    //console.log("Respuestas en texto:", respuestasEnTexto, puntuacion);
    const hayDosComillasSinValor = /""|\s*" "\s*|\[object Object\]/.test(
      respuestasEnTexto
    );

    if (hayDosComillasSinValor) {
      SetTodoRespondido(false);
    } else {
      SetTodoRespondido(true);
      try {
        const response = await axios.post(t("Recursos.ip") + "/respuesta", {
          numeroCuestionario: numero_cuestionario,
          arrayCuestionario: respuestasEnTexto,
          arrayPuntuacionCuestionario: puntuacion,
          email: email.email,
        });

        console.log(response.data);
        navigate("/ListaCuestionario");
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  // Función para mostrar todas las respuestas en la consola
  const mostrarRespuestasEnConsola = async () => {
    try {
      const puntuacion =
        '"' +
        traducirArray(respuestas).join('","') +
        '"' +
        (',"' + respuestaEscala + '"');

        const resultado = `"${respuestas.join('","')}","${respuestaEscala}"`;


      console.log(resultado, puntuacion);
      const hayDosComillasSinValor = /""|\s*""\s*|\[object Object\]/.test(
        resultado
      );

      if (hayDosComillasSinValor) {
        SetTodoRespondido(false);
      } else {
        SetTodoRespondido(true);
        try {
          const response = await axios.post(t("Recursos.ip") + "/respuesta", {
            numeroCuestionario: numero_cuestionario,
            arrayCuestionario: resultado,
            arrayPuntuacionCuestionario: puntuacion,
            email: email.email,
          });

          console.log(response.data);
          navigate("/ListaCuestionario");
        } catch (error) {
          console.error("Error:", error);
        }
      }
    } catch (error) {
      SetTodoRespondido(false);
    }
  };

  const traducirArray = (array) => {
    return array.map((elemento) => traducirAValor(elemento));
  };

  const traducirAValor = (cadena) => {
    switch (cadena) {
      case t("Cuestionario2.Respuestas.1"):
        return 1;
      case t("Cuestionario2.Respuestas.2"):
        return 2;
      case t("Cuestionario2.Respuestas.3"):
        return 3;
      default:
        return cadena; // Valor por defecto o manejo de error
    }
  };
  const questions = [
    t("Cuestionario2.Preguntas.1"),
    t("Cuestionario2.Preguntas.2"),
    t("Cuestionario2.Preguntas.3"),
    t("Cuestionario2.Preguntas.4"),
    t("Cuestionario2.Preguntas.5"),
    t("Cuestionario2.Preguntas.6"),
    t("Cuestionario2.Preguntas.7"),

    // Puedes seguir agregando más preguntas aquí
  ];
  const answerOptions = [
    t("Cuestionario2.Respuestas.1"),
    t("Cuestionario2.Respuestas.2"),
    t("Cuestionario2.Respuestas.3"),
  ];

  // Función para manejar las respuestas de cada instancia de PreguntaRespuesta
  const handleRespuestaSubmit = (pregunta, respuesta) => {
    // Actualizar el objeto de respuestas con la respuesta correspondiente
    setRespuestas((prevRespuestas) => ({
      ...prevRespuestas,
      [pregunta]: respuesta,
    }));
  };

  return (
    <div>

      <div style={{ display: parte2 ? "none" : "block" }}>
        <TituloyFlecha title={t("Cuestionario2.Titulo")} link={"/Cuestionario/2/intentos"} />

        <div style={{ margin: "auto", width: "80%" }}>
          <p style={{ textAlign: "justify" }}> {t("Cuestionario2.Texto1")}</p>
          <p style={{ textAlign: "justify" }}>{t("Cuestionario2.Texto2")}</p>
        </div>

        <div> {windowWidth > 800 ? (
          <PreguntasColumnasIntercaladas
            questions={questions}
            onRespuestasChange={handleRespuestasChange}
            answerOptions={answerOptions}
          />) : (<CheckboxesCuestionarioSD
            preguntas={questions}
            respuestasComunes={answerOptions}
            onRespuestaSubmit={handleRespuestaSubmit}
          />)}
        </div>
        <br />
        <div style={{ display: "flex" }}>
          <button
            onClick={() => cambiarparte(false)}
            style={{
              marginTop: "30px",
              marginRight: window.innerWidth <= 768 ? "10%" : "-25%",
              width: window.innerWidth <= 768 ? "50%" : "20%",
              backgroundColor: "#A5BBC1",
            }}
            className="link-button"
          >
            {t("Atras")}
          </button>
          <button
            onClick={() => cambiarparte(true)}
            style={{
              marginTop: "30px",
              marginLeftt: window.innerWidth <= 768 ? "-10%" : "25%",
              width: window.innerWidth <= 768 ? "50%" : "20%",
            }}
            className="link-button"
          >
            {t("Continuar")}
          </button>
        </div>
      </div>
      <div style={{ display: parte2 ? "block" : "none" }}>
        <TituloyFlecha title={t("Cuestionario2.Titulo")} link={"/Cuestionario/2/intentos"} />
        <div>
          <p>{t("Cuestionario2.Texto3")}</p>
          <p>{t("Cuestionario2.Texto4")}</p>
        </div>

        <EscalavisualanalogicaHorizontal
          textoDerecha={t("Cuestionario2.Escalas.textoDerecha")}
          textoIzquierda={t("Cuestionario2.Escalas.textoIzquierda")}
          onRespuestaSeleccionada={handleRespuestaEscala}
        />

        <br />
        {!todoRespondido && (
          <div>
            <p style={{ color: "red" }}>{t("Responde-todo")}</p>
          </div>
        )}
        <div style={{ display: "flex" }}>
        <button
            onClick={() => cambiarparte(false)}
            style={{
              marginTop: "30px",
              marginRight: window.innerWidth <= 768 ? "10%" : "-25%",
              width: window.innerWidth <= 768 ? "50%" : "20%",
              backgroundColor: "#A5BBC1",
            }}
            className="link-button"
          >
            {t("Atras")}
          </button>
          {windowWidth > 800 ? (
            <button
              onClick={mostrarRespuestasEnConsola}
              className="link-button"
             style={{
                  backgroundColor:"#51A670",
                  marginTop: "30px",
                  width: window.innerWidth <= 768 ? "50%" : "20%",
                }}
            >
              {t("Confirmar")}
            </button>
          ) : (
            <button
              onClick={mostrarRespuestasEnConsolaMovil}
              className="link-button"
              style={{
                marginTop: "30px",
                backgroundColor:"#51A670",
                width: window.innerWidth <= 768 ? "50%" : "20%",
              }}
            >
              {t("Confirmar")}
            </button>
          )}
         
        </div>
      </div>
      <p style={{marginTop:"-5%"}}>{t("La traducción del CarerQol ha sido posible gracias a ACTIFcare")}</p>
    </div>
  );
};

export default Cuestionario2;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./AgrupacionContenidos.css";
import RellenoContenidos from "./RellenoContenidos";

const AgrupacionContenidos = ({ contenidos }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [t] = useTranslation("global");

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="paginacion-temas-container">
            {contenidos ? (
                <>

                    <div className="containerContenidos">

                        <>
                            {" "}
                            {windowWidth > 800 ? (
                                <div>
                                    <div className="agrupacionMecuido">
                                        {contenidos

                                            .slice(0, Math.min(4, contenidos.length))
                                            .map((contenido, index) => (
                                                <RellenoContenidos
                                                    nombre={contenido.nombre}
                                                    contenido={contenido.contenido}
                                                />
                                            ))}
                                    </div>
                                    <div className="agrupacionMecuido">
                                        {contenidos.length > 4 &&
                                            contenidos

                                                .slice(4)
                                                .map((contenido, index) => (
                                                    <RellenoContenidos
                                                        nombre={contenido.nombre}
                                                        contenido={contenido.contenido}
                                                    />
                                                ))}
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="agrupacionMecuido">
                                        {contenidos

                                            .slice(0, Math.min(2, contenidos.length))
                                            .map((contenido, index) => (
                                                <RellenoContenidos
                                                    nombre={contenido.nombre}
                                                    contenido={contenido.contenido}
                                                />
                                            ))}
                                    </div>
                                    <div className="agrupacionMecuido">
                                        {contenidos.length > 2 &&
                                            contenidos

                                                .slice(2, Math.min(4, contenidos.length))
                                                .map((contenido, index) => (
                                                    <RellenoContenidos
                                                        nombre={contenido.nombre}
                                                        contenido={contenido.contenido}
                                                    />
                                                ))}
                                    </div>
                                    <div className="agrupacionMecuido">
                                        {contenidos.length > 4 &&
                                            contenidos
                                                .slice(4, Math.min(6, contenidos.length))
                                                .map((contenido, index) => (
                                                    <RellenoContenidos
                                                        nombre={contenido.nombre}
                                                        contenido={contenido.contenido}
                                                    />
                                                ))}
                                    </div>
                                    <div className="agrupacionMecuido">
                                        {contenidos.length > 6 &&
                                            contenidos
                                                .slice(6, Math.min(8, contenidos.length))
                                                .map((contenido, index) => (
                                                    <RellenoContenidos
                                                        nombre={contenido.nombre}
                                                        contenido={contenido.contenido}
                                                    />
                                                ))}
                                    </div>
                                </div>
                            )}
                        </>

                    </div>

                </>
            ) : (
                <div style={{ marginTop: "20%" }}>
                    <b>{t("Conectando-BD")}</b>
                </div>
            )}
        </div>
    );
};

export default AgrupacionContenidos;

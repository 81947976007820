import React, { useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import UserContext from "../../UserContext";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

const GenderOptions = ["Masculino", "Femenino", "Otro"];

const Miperfil = () => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const email = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation("global"); // Note the destructuring assignment here

  useEffect(() => {
    // Realiza una petición GET para obtener los datos del usuario
    if (email.email !== null) {
      const fetchUserData = async () => {
        try {
          const response = await axios.get(
            t("Recursos.ip") + "/accounts/getuser",
            {
              params: {
                email: email.email,
              },
            }
          );

          // Establece los valores por defecto en los campos del formulario
          setValue("nombre", response.data.nombre || "");
          setValue("apellidos", response.data.apellidos || "");
          setValue("genero", response.data.sexo || "");
          setValue(
            "fechaNacimiento",
            new Date(response.data.f_nacimiento) || null
          ); // Ajusta según el formato de fecha
        } catch (error) {
          console.error("Error al obtener datos del usuario:", error);
        }
      };

      // Llama a la función para obtener los datos cuando el componente se monta
      fetchUserData();
    }
  }, [email, setValue, t]); // Added `t` to the dependency array

  const onSubmit = async (data) => {
    const formattedDate = format(new Date(data.fechaNacimiento), "yyyy-MM-dd");
    // Agrega los campos que deseas enviar en el cuerpo de la solicitud
    const formData = {
      nombre: data.nombre,
      apellidos: data.apellidos,
      genero: data.genero,
      fechaNacimiento: formattedDate,
      email: email.email,
    };

    try {
      // Realiza la petición PUT con axios
      // Linea para ignorar el warning, realmente es necesario declarar esto aunque podría usarse para saber si se envió correctamente
      // eslint-disable-next-line no-unused-vars
      const response = await axios.put(
        t("Recursos.ip") + "/accounts/update",
        formData
      );

      // Puedes realizar acciones adicionales con la respuesta si es necesario
      navigate("/");
    } catch (error) {
      // Maneja el error, por ejemplo, mostrándolo en la consola
      console.error("Error en la petición PUT:", error);
    }
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 100; // Puedes ajustar este rango según tus necesidades
    const years = [];

    for (let year = currentYear; year >= startYear; year--) {
      years.push(year);
    }

    return years;
  };

  return (
    <div style={{ height: "100%" }}>
      <div className="mensaje-bienvenida">
        <p>{t("Miperfil.Titulo")}</p>
      </div>
      <div className="formulario-container">
        <form onSubmit={handleSubmit(onSubmit)} className="formulario">
          <div>
            <label>{t("Miperfil.Nombre")}:</label>
            <input
              name="nombre"
              {...register("nombre", {
                required: t("Miperfil.CampoObligatorio"),
              })}
            />
            {errors.nombre && (
              <p className="error-message">{errors.nombre.message}</p>
            )}
          </div>

          <div>
            <label>{t("Miperfil.Apellidos")}:</label>
            <input
              {...register("apellidos", {
                required: t("Miperfil.CampoObligatorio"),
              })}
            />
            {errors.apellidos && (
              <p className="error-message">{errors.apellidos.message}</p>
            )}
          </div>

          <div>
            <label>{t("Miperfil.Fecha-Nacimiento")}:</label>
            <Controller
              name="fechaNacimiento"
              control={control}
              render={({ field }) => (
                <>
                  <select
                    {...field}
                    name={field.name}
                    value={
                      field.value
                        ? new Date(field.value).getFullYear().toString()
                        : ""
                    }
                    onChange={(e) =>
                      field.onChange(new Date(`${e.target.value}-01-01`))
                    }
                  >
                    <option value="" disabled hidden>
                      {t("Miperfil.Selecciona")}...
                    </option>
                    {generateYearOptions().map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  {errors.fechaNacimiento && (
                    <p className="error-message">
                      {errors.fechaNacimiento.message}
                    </p>
                  )}
                </>
              )}
              rules={{ required: t("Miperfil.CampoObligatorio") }}
            />
          </div>
          <div>
            <label>{t("Miperfil.Genero")}:</label>
            <select
              {...register("genero", {
                required: t("Miperfil.CampoObligatorio"),
              })}
            >
              {getValues("genero") ? (
                <option value={getValues("genero")}>
                  {getValues("genero")}
                </option>
              ) : (
                <option value="">{t("Miperfil.Selecciona1")}...</option>
              )}
              {GenderOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors.genero && (
              <p className="error-message">{errors.genero.message}</p>
            )}
          </div>

          <button
            type="submit"
            className="botoncirc"
            style={{ backgroundColor: "#51A670" }}
          >
            {t("Confirmar")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Miperfil;

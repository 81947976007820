import React from 'react';
import Pasos_Icono from "../../Images/Pasos_Icono.png";
import Sueño_Icono from "../../Images/Sueño_Icono.png";
import Corazon_Icono from "../../Images/Corazon_Icono.png";
import './MisDatosSaludCarta.css';
import Card from '../Card';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
function MisDatosSaludCarta() {
  const [t] = useTranslation("global");
  return (
    <div>
      <div style={{ width: "75%", margin: "0 auto", marginTop: "-2rem" }}>
        <Card
          content={t("Mis-Datos-de-Salud")}
          alto="50%"
          ancho="100%"
          className={"home-card-header"}
        />
      </div>
      <div style={{  marginTop:"0.5vh"}}>
      {/* Texto en la parte superior */}
        <p style={{ color:"black"}}>{t("Mis-Datos-de-Salud-texto")}</p>
        {/* Contenedor para las imágenes */}
        <div style={{ marginBottom: "1vh"}}>
          {/* Imagen 1 */}
          <Link to="/MiActividadFisica/dia">
          <img  src={Pasos_Icono} alt={t("Pasos-Icono")} className="imagenMisDatosSalud" style={{marginRight:"0.5vh"}}/>
          </Link>

          {/* Imagen 2 */}
          <Link to="/RutinaSuenoDiaria">
          <img  src={Sueño_Icono} alt={t("Sueno-Icono")}  className="imagenMisDatosSalud" style={{marginRight:"0.5vh"}}/>
          </Link>

          {/* Imagen 3 */}
          <Link to="/FrecuenciaCardiacaDiaria">
          <img  src={Corazon_Icono} alt={t("Corazon-Icono")}  className="imagenMisDatosSalud"/>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MisDatosSaludCarta;
// Publicidad.js
import React from "react";
import { useTranslation } from "react-i18next";
import logospu from "../Images/logospu.png";
import "./Publicidad.css";

function Publicidad() {
  const [t] = useTranslation("global");



  return (
    <div className="contenedor-pu" >
      <img
        className="imagen-logospu"
        src={logospu}
        alt={t("NextGeneration-texto")}
      />
      <p style={{ fontSize: "1vh", zIndex: "1", marginTop: "0.5%", color: "white", marginBottom: "0.1%", marginLeft: "1%" }}>
        {t("NextGeneration-texto1")}
        {t("NextGeneration-texto2")}
        {t("NextGeneration-texto3")}
      </p>
    </div>
  );
}

export default Publicidad;

import React, { useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import UserContext from "../../UserContext";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import "./FormularioRegistro.css";

const FormularioRegistro = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue, // Para establecer valores del formulario
    getValues, // Para obtener los valores actuales del formulario
  } = useForm();
  const { email, setMostrar } = useContext(UserContext);
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const GenderOptions = ["Masculino", "Femenino", "Otro"];

  useEffect(() => {
    // Recupera los datos del formulario desde localStorage cuando el componente se monta
    const savedFormData = JSON.parse(localStorage.getItem("formData"));
    if (savedFormData) {
      Object.keys(savedFormData).forEach((key) => {
        setValue(key, savedFormData[key]);
      });
    }
  }, [setValue]);

  const onSubmit = async (data) => {
    const formattedDate = format(new Date(data.fechaNacimiento), "yyyy-MM-dd");

    const formData = {
      nombre: data.nombre,
      apellidos: data.apellidos,
      genero: data.genero,
      email: email,
      fechaNacimiento: formattedDate,
      politicaPrivacidad: data.politicaPrivacidad,
    };

    try {
      const response = await axios.post(
        t("Recursos.ip") + "/accounts/register",
        formData
      );

      localStorage.removeItem("formData"); // Limpia los datos del formulario del localStorage después del envío exitoso
      setMostrar(true);
      navigate("/anadirinfopersonal");
      console.log(response)
    } catch (error) {
      console.error("Error en la petición POST:", error);
    }
  };

  const handleChange = () => {
    const currentFormData = getValues();
    localStorage.setItem("formData", JSON.stringify(currentFormData));
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 100;
    const years = [];

    for (let year = currentYear; year >= startYear; year--) {
      years.push(year);
    }

    return years;
  };

  return (
    <div style={{ height: "100%" }}>
      <div className="mensaje-bienvenida">
        <p>{t("FormularioRegistro.Mensaje-Bienvenido")}</p>
      </div>

      <div className="formulario-container">
        <form
          onSubmit={handleSubmit(onSubmit)}
          onChange={handleChange} // Usa handleChange aquí
          className="formulario"
        >
          <div>
            <label>{t("Nombre")}:</label>
            <input
              {...register("nombre", {
                required: "*Este campo es obligatorio*",
              })}
            />
            {errors.nombre && (
              <p className="error-message">{errors.nombre.message}</p>
            )}
          </div>

          <div>
            <label>{t("Apellidos")}:</label>
            <input
              {...register("apellidos", {
                required: "*Este campo es obligatorio*",
              })}
            />
            {errors.apellidos && (
              <p className="error-message">{errors.apellidos.message}</p>
            )}
          </div>

          <div>
            <label>{t("Miperfil.Fecha-Nacimiento")}:</label>
            <Controller
              name="fechaNacimiento"
              control={control}
              render={({ field }) => (
                <>
                  <select
                    {...field}
                    name="fechaNacimiento"
                    value={
                      field.value
                        ? new Date(field.value).getFullYear().toString()
                        : ""
                    }
                    onChange={(e) =>
                      field.onChange(new Date(`${e.target.value}-01-01`))
                    }
                  >
                    <option value="" disabled hidden>
                      {t("Miperfil.Selecciona")}
                    </option>
                    {generateYearOptions().map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  {errors.fechaNacimiento && (
                    <p className="error-message">
                      {errors.fechaNacimiento.message}
                    </p>
                  )}
                </>
              )}
              rules={{ required: t("Miperfil.CampoObligatorio") }}
            />
          </div>

          <div>
            <label>{t("Genero")}:</label>
            <select
              {...register("genero", {
                required: "*Este campo es obligatorio*",
              })}
            >
              <option value="">{t("Selecciona")}...</option>
              {GenderOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors.genero && (
              <p className="error-message">{errors.genero.message}</p>
            )}
          </div>

          <div>
            <p htmlFor="politicaPrivacidad">
              {t("PoliticaPrivacidad.Texto-introduccion")}{" "}
              <Link
                to="/politica-privacidad"
                onClick={() => window.scrollTo(0, 0)}
              >
                {t("PoliticaPrivacidad.titulo")}
              </Link>
            </p>
            {errors.politicaPrivacidad && (
              <p className="error-message">
                {errors.politicaPrivacidad.message}
              </p>
            )}
            <input
              type="checkbox"
              id="politicaPrivacidad"
              style={{ width: "30px", height: "30px" }}
              {...register("politicaPrivacidad", {
                required: t("PoliticaPrivacidad.Texto-no-aceptada"),
              })}
            />
          </div>

          <button type="submit" style={{ marginTop: "-1%" }} className="botoncirc">
            {t("Enviar")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default FormularioRegistro;

import { useEffect, useContext, useState } from "react";
import UserContext from "../../UserContext";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import Card from "../../components/Card";
import "./SuenoPageDuracion.css";
import { FaCircle } from "react-icons/fa";
import CustomBarChartSueno from "../../components/Graficos/CustomBarChartSueno";
import TituloyFlecha from "../../components/TituloyFlecha";

const SuenoPageDuracion = () => {
  const email = useContext(UserContext);
  const [t] = useTranslation("global");
  const [chartData, setChartData] = useState(null);
  const [nfecha, setNfecha] = useState(0);

  const [listasesionesprocesadas, setListasesionesprocesadas] = useState(null);
  const { contenido } = useParams();
  const [contenidoAMostrar, setContenidoAMostrar] = useState(null);
  const [colorFondoboton1, setColorFondoboton1] = useState("#b9ddd7");
  const [colorFondoboton2, setColorFondoboton2] = useState("#b9ddd7");
  const [colorFondoboton3, setColorFondoboton3] = useState("#b9ddd7");
  const [colorFondoboton4, setColorFondoboton4] = useState("#b9ddd7");
  const [colorTexto1, setColorTexto1] = useState("black");
  const [colorTexto2, setColorTexto2] = useState("black");
  const [colorTexto3, setColorTexto3] = useState("black");
  const [colorTexto4, setColorTexto4] = useState("black");
  const [textocuadro1, setTextocuadro1] = useState(
    t("Duracion-media-de-sueno")
  );
  const [textocuadro2, setTextocuadro2] = useState(t("Datos-medios-de-etapas"));
  const locale = {
    dateTime: "%A, %e de %B de %Y, %X",
    date: "%d/%m/%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: [
      t("DiasSemana.Domingo"),
      t("DiasSemana.Lunes"),
      t("DiasSemana.Martes"),
      t("DiasSemana.Miercoles"),
      t("DiasSemana.Jueves"),
      t("DiasSemana.Viernes"),
      t("DiasSemana.Sabado"),
    ],
    shortDays: [
      t("shortDiasSemana.Domingo"),
      t("shortDiasSemana.Lunes"),
      t("shortDiasSemana.Martes"),
      t("shortDiasSemana.Miercoles"),
      t("shortDiasSemana.Jueves"),
      t("shortDiasSemana.Viernes"),
      t("shortDiasSemana.Sabado"),
    ],
    months: [
      t("Meses.Enero"),
      t("Meses.Febrero"),
      t("Meses.Marzo"),
      t("Meses.Abril"),
      t("Meses.Mayo"),
      t("Meses.Junio"),
      t("Meses.Julio"),
      t("Meses.Agosto"),
      t("Meses.Septiembre"),
      t("Meses.Octubre"),
      t("Meses.Noviembre"),
      t("Meses.Diciembre"),
    ],
    shortMonths: [
      t("shortMeses.Enero"),
      t("shortMeses.Febrero"),
      t("shortMeses.Marzo"),
      t("shortMeses.Abril"),
      t("shortMeses.Mayo"),
      t("shortMeses.Junio"),
      t("shortMeses.Julio"),
      t("shortMeses.Agosto"),
      t("shortMeses.Septiembre"),
      t("shortMeses.Octubre"),
      t("shortMeses.Noviembre"),
      t("shortMeses.Diciembre"),
    ],
  };

  useEffect(() => {
    if (contenido) {
      switch (contenido) {
        case "semana":
          setColorFondoboton1("#b9ddd7");
          setColorFondoboton2("#518ea6");
          setColorFondoboton3("#b9ddd7");
          setColorFondoboton4("#b9ddd7");
          setColorTexto1("black");
          setColorTexto2("white");
          setColorTexto3("black");
          setColorTexto4("black");
          setContenidoAMostrar("1");
          setTextocuadro1(t("Duracion-media-de-sueno-semana"));
          setTextocuadro2(t("Datos-medios-de-etapas-semana"));
          break;
        case "mes":
          setColorFondoboton1("#b9ddd7");
          setColorFondoboton2("#b9ddd7");
          setColorFondoboton3("#518ea6");
          setColorFondoboton4("#b9ddd7");
          setColorTexto1("black");
          setColorTexto2("black");
          setColorTexto3("white");
          setColorTexto4("black");
          setContenidoAMostrar("2");
          setTextocuadro1(t("Duracion-media-de-sueno-mes"));
          setTextocuadro2(t("Datos-medios-de-etapas-mes"));
          break;
        case "ano":
          setColorFondoboton1("#b9ddd7");
          setColorFondoboton2("#b9ddd7");
          setColorFondoboton3("#b9ddd7");
          setColorFondoboton4("#518ea6");
          setColorTexto1("black");
          setColorTexto2("black");
          setColorTexto3("black");
          setColorTexto4("white");
          setContenidoAMostrar("3");
          setTextocuadro1(t("Duracion-media-de-sueno-ano"));
          setTextocuadro2(t("Datos-medios-de-etapas-ano"));
          break;
        default:
          setColorFondoboton1("#b9ddd7");
          setColorFondoboton2("#518ea6");
          setColorFondoboton3("#b9ddd7");
          setColorFondoboton4("#b9ddd7");
          setColorTexto1("black");
          setColorTexto2("white");
          setColorTexto3("black");
          setColorTexto4("black");
          setContenidoAMostrar("1");
          setTextocuadro1(t("Duracion-media-de-sueno"));
          setTextocuadro2(t("Datos-medios-de-etapas"));
      }
    }
  }, [contenido, t]);

  useEffect(() => {
    if (listasesionesprocesadas) {
      //Aqui hacer logica del año
      if (contenido === "ano") {
        setChartData(listasesionesprocesadas[nfecha].reverse());
      } else {
        setChartData(listasesionesprocesadas[nfecha]);
      }
      ////Console.log("setChartData", listasesionesprocesadas);
      ////Console.log("Valores agrupados", listasesionesprocesadas);
      //imprimirDias(agruparPorMesesYsemanas(listasesionesprocesadas));
    }
  }, [nfecha, contenido, listasesionesprocesadas]);

  useEffect(() => {
    const consulta_sueno = async () => {
      const currentDate = new Date();
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(currentDate.getDate() - 730);
      const formattedCurrentDate = currentDate.toISOString().split("T")[0];
      const formattedOneWeekAgo = oneWeekAgo.toISOString().split("T")[0];

      try {
        const response_durancion = await axios.get(
          t("Recursos.ip") + "/sueno",
          {
            params: {
              email: email.email,
              startTime: formattedOneWeekAgo,
              endTime: formattedCurrentDate,
            },
          }
        );
        ////Console.log("Datos: ", response_durancion.data);
        const lista = encontrarSesionSuenoN(
          procesarArray(response_durancion.data)
        );
        ////Console.log("Sesiones: ", lista);

        setListasesionesprocesadas(procesadodatos(lista));
      } catch (error) {
        console.error("Error:", error);
      }
    };
    function procesadodatos(datos) {
      setNfecha(0);
      switch (contenido) {
        case "semana":
          return combinarArraysSemanas(
            generarArrayDiasaSemana(new Date()),
            procesarDatos(datos)
          ).reverse();

        case "mes":
          return agruparPorMeses(
            combinarArraysDias(
              generarArrayDias(UltimoDiaDelMes(new Date())),
              procesarDatos(datos)
            )
          ).reverse();

        case "ano":
          return obtenerValoresPrimerDiaMes(
            agruparPorMeses(
              combinarArraysDias(
                generarArrayDias(UltimoDiaDelAno(new Date())),
                procesarDatos(datos)
              )
            )
          ).reverse();

        default:
          combinarArraysSemanas(
            generarArrayDiasaSemana(new Date()),
            procesarDatos(datos)
          ).reverse();
          return;
      }
    }
    function combinarArraysSemanas(arraySemanas, arrayDias) {
      // Iterar a través de cada semana en el array de semanas
      for (const semana of arraySemanas) {
        // Iterar a través de cada día en la semana
        for (const dia of semana) {
          // Encontrar el índice del día en el array de días
          const indiceDia = arrayDias.findIndex(
            (d) =>
              obtenerFechaSinHora(d.horaFin) ===
              obtenerFechaSinHora(dia.horaFin)
          );

          // Si se encuentra el día, sustituir el elemento
          if (indiceDia !== -1) {
            arraySemanas[arraySemanas.indexOf(semana)][semana.indexOf(dia)] =
              arrayDias[indiceDia];
          }
        }
      }

      return arraySemanas;
    }

    function agruparPorMeses(array) {
      const resultado = [];
      const fechaActual = new Date();
      const añoInicio = fechaActual.getFullYear() - 1; // Año pasado
      const mesInicio = 0; // Enero del año pasado
    
      array.forEach((item) => {
        const fecha = new Date(obtenerFechaSinHora(item.horaFin));
        const mes = fecha.getMonth(); // Meses en JavaScript van de 0 a 11
        const año = fecha.getFullYear();
    
        // Calcular una clave numérica única basada en el año y el mes, con enero del año pasado como "0"
        const clave = (año - añoInicio) * 12 + (mes - mesInicio);
    
        if (!resultado[clave]) {
          resultado[clave] = [];
        }
    
        resultado[clave].push({
          horaInicio: item.horaInicio,
          horaFin: item.horaFin,
          duracionTotal: item.duracionTotal,
          duracionSuenoProfundo: item.duracionSuenoProfundo,
          duracionSuenoLigero: item.duracionSuenoLigero,
          duracionSuenoREM: item.duracionSuenoREM,
          duracionDespierto: item.duracionDespierto,
        });
      });
    
      console.log("Meses agrupados (clave numérica desde 0): ", resultado);
      return resultado;
    }
    

    const obtenerValoresPrimerDiaMes = (datos) => {
      const valoresPorAño = [[], []]; // Índice 0 para el año pasado, índice 1 para el año actual
      const fechaActual = new Date();
      const añoActual = fechaActual.getFullYear();
      const añoAnterior = añoActual - 1;
    
      Object.keys(datos).forEach((indiceMes) => {
        const primerDia = datos[indiceMes][0]; // Suponiendo que los días están indexados desde 1
        if (primerDia) {
          const fecha = new Date(primerDia.horaFin);
          const año = fecha.getFullYear();
          const mes = fecha.getMonth();
    
          // Elegir el índice correcto para el año actual o el año pasado
          let añoIndex = null;
          if (año === añoAnterior) {
            añoIndex = 0;
          } else if (año === añoActual) {
            añoIndex = 1;
          }
    
          if (añoIndex !== null) {
            // Inicializar el mes si aún no existe en el arreglo del año correspondiente
            if (!valoresPorAño[añoIndex][mes]) {
              valoresPorAño[añoIndex][mes] = {
                ...primerDia,
                duracionDespierto: calcularTotalPropiedad(
                  datos[indiceMes],
                  "duracionDespierto"
                ),
                duracionSuenoLigero: calcularTotalPropiedad(
                  datos[indiceMes],
                  "duracionSuenoLigero"
                ),
                horaInicio: combinarFechas(
                  primerDia.horaInicio,
                  calcularHoraMediaInicio(datos[indiceMes])
                ),
                horaFin: combinarFechas(
                  primerDia.horaFin,
                  calcularHoraMediaFin(datos[indiceMes])
                ),
                duracionSuenoProfundo: calcularTotalPropiedad(
                  datos[indiceMes],
                  "duracionSuenoProfundo"
                ),
                duracionSuenoREM: calcularTotalPropiedad(
                  datos[indiceMes],
                  "duracionSuenoREM"
                ),
                duracionTotal: calcularTotalPropiedad(
                  datos[indiceMes],
                  "duracionTotal"
                ),
                duracionMediaDespierto: calcularMediaPropiedad(
                  datos[indiceMes],
                  "duracionDespierto"
                ),
                duracionMediaSuenoLigero: calcularMediaPropiedad(
                  datos[indiceMes],
                  "duracionSuenoLigero"
                ),
                duracionMediaSuenoProfundo: calcularMediaPropiedad(
                  datos[indiceMes],
                  "duracionSuenoProfundo"
                ),
                duracionMediaSuenoREM: calcularMediaPropiedad(
                  datos[indiceMes],
                  "duracionSuenoREM"
                ),
                duracionMediaTotal: calcularMediaPropiedad(
                  datos[indiceMes],
                  "duracionTotal"
                ),
              };
            }
          }
        }
      });
    
      console.log("Valores agrupados por año y mes: ", valoresPorAño);
      return valoresPorAño;
    };
    

    function combinarFechas(fecha1, hora2) {
      // Extraer la fecha del primer objeto
      const fecha1String = fecha1.split("T")[0];

      // Formatear la nueva fecha combinando la fecha del primer formato y la hora del segundo formato
      const fechaHoraCombinada = `${fecha1String}T${hora2}:00`;

      return fechaHoraCombinada;
    }

    const UltimoDiaDelMes = (fecha) => {
      // Obtener el último día del mes
      const ultimoDia = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);

      return ultimoDia;
    };

    const UltimoDiaDelAno = (fecha) => {
      // Verificar si la fecha es undefined
      if (!fecha) {
        return "Fecha no válida";
      }

      // Obtener el último día del año
      const ultimoDiaDelAno = new Date(fecha.getFullYear() + 1, 0, 0);

      return ultimoDiaDelAno;
    };
    function combinarArraysDias(arrayAno, arrayDias) {
      // Iterar a través de cada semana en el array de semanas

      for (const dia of arrayAno) {
        // Encontrar el índice del día en el array de días
        const indiceDia = arrayDias.findIndex(
          (d) =>
            obtenerFechaSinHora(d.horaFin) === obtenerFechaSinHora(dia.horaFin)
        );

        // Si se encuentra el día, sustituir el elemento
        if (indiceDia !== -1) {
          arrayAno[arrayAno.indexOf(dia)] = arrayDias[indiceDia];
        }
      }
      ////Console.log("Dias:", arrayAno);
      return arrayAno;
    }

    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if (email.email !== null && contenido) {
      consulta_sueno();
    }
  }, [email, contenido, t]);

  function redondearHaciaArriba(fecha) {
    // Obtenemos los minutos y los segundos de la fecha
    const minutos = fecha.getMinutes();
    const segundos = fecha.getSeconds();

    // Redondeamos hacia arriba los segundos dividiendo entre 60 y sumando 1
    const segundosRedondeados = Math.ceil(segundos / 60) * 60;

    // Creamos una nueva fecha con los minutos originales y los segundos redondeados
    const nuevaFecha = new Date(fecha);
    nuevaFecha.setSeconds(0); // Reiniciamos los segundos
    nuevaFecha.setMinutes(
      minutos + Math.floor((segundos + segundosRedondeados) / 60)
    ); // Sumamos los minutos y el cociente de la división
    return nuevaFecha;
  }

  // Función para formatear la fecha al formato 'YYYY-MM-DDThh:mm:ss'
  function formatearFechas(fecha) {
    if (!(fecha instanceof Date)) {
      // Si no es un objeto Date, puedes manejar el error de alguna manera
      console.error("Error: Se esperaba un objeto Date");
      return null;
    }

    const year = fecha.getFullYear();
    const month = String(fecha.getMonth() + 1).padStart(2, "0");
    const day = String(fecha.getDate()).padStart(2, "0");
    const hours = String(fecha.getHours()).padStart(2, "0");
    const minutes = String(fecha.getMinutes()).padStart(2, "0");
    const seconds = String(fecha.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  // Función para procesar el array de fechas
  function procesarArray(array) {
    // Iteramos sobre cada objeto en el array
    const nuevoArray = array
      .map((item) => {
        // Convertimos las cadenas de fecha a objetos Date
        const fechaInicio = new Date(item.t_inicio);
        const fechaFin = new Date(item.t_fin);

        // Redondeamos hacia arriba las fechas
        const nuevaFechaInicio = redondearHaciaArriba(fechaInicio);
        const nuevaFechaFin = redondearHaciaArriba(fechaFin);

        // Formateamos las fechas redondeadas al formato original
        const fechaInicioFormateada = formatearFechas(nuevaFechaInicio);
        const fechaFinFormateada = formatearFechas(nuevaFechaFin);

        // Verificamos si el formateo fue exitoso antes de devolver el nuevo objeto
        if (fechaInicioFormateada && fechaFinFormateada) {
          return {
            ...item,
            t_inicio: fechaInicioFormateada,
            t_fin: fechaFinFormateada,
          };
        } else {
          // Puedes manejar el error de alguna manera
          console.error("Error al formatear la fecha");
          return null;
        }
      })
      .filter(Boolean) // Filtramos los elementos nulos que pueden haber sido devueltos en caso de error
      .filter((item) => item.t_inicio !== item.t_fin); // Filtramos los objetos donde t_inicio sea igual a t_fin

    return nuevoArray;
  }

  function encontrarSesionSuenoN(fasesSueno, n) {
    //No lee bien los segundos
    if (n < 0 || n >= fasesSueno.length) {
      return null; // Valor de n no válido.
    }

    // Ordena las fases de sueño por fecha de fin en orden descendente.
    fasesSueno.sort((a, b) => new Date(b.t_fin) - new Date(a.t_fin));

    const sesiones = [];
    let currentSession = [fasesSueno[0]];
    fasesSueno.push(fasesSueno[0]);

    for (let i = 0; i < fasesSueno.length; i++) {
      if (i === 0) {
        currentSession.push(fasesSueno[i]);
      } else {
        const fechaFinActual = new Date(fasesSueno[i].t_fin);
        const fechaInicioAnterior = new Date(fasesSueno[i - 1].t_inicio);
        if (fechaInicioAnterior.getTime() === fechaFinActual.getTime()) {
          currentSession.push(fasesSueno[i]);
        } else {
          sesiones.push(currentSession);

          currentSession = [fasesSueno[i]];
        }
      }
    }
    // Ordena las sesiones por fecha de finalización en orden descendente.
    sesiones.sort((a, b) => new Date(b[0].t_fin) - new Date(a[0].t_fin));
    return sesiones;
  }

  function procesarDatos(sesionesSueno) {
    function calcularDuracionEnMinutos(inicio, fin) {
      const tiempoInicio = new Date(inicio);
      const tiempoFin = new Date(fin);

      // Manejar el caso de cruce de medianoche
      if (tiempoFin < tiempoInicio) {
        tiempoFin.setDate(tiempoFin.getDate() + 1); // Agregar un día a la marca de tiempo de fin
      }

      const duracionEnMilisegundos = tiempoFin - tiempoInicio;
      return duracionEnMilisegundos / (1000 * 60);
    }

    const sesionesProcesadas = [];

    for (const sesion of sesionesSueno) {
      let duracionSuenoProfundo = 0;
      let duracionSuenoLigero = 0;
      let duracionSuenoREM = 0;
      let duracionDespierto = 0;

      let horaInicio = sesion[sesion.length - 1].t_inicio;
      let horaFin = sesion[0].t_fin;

      for (const fase of sesion) {
        const duracionFase = calcularDuracionEnMinutos(
          fase.t_inicio,
          fase.t_fin
        );

        switch (fase.fasesSuenoEnum) {
          case "SUENO_PROFUNDO":
            duracionSuenoProfundo += duracionFase;
            break;
          case "SUENO_LIGERO":
            duracionSuenoLigero += duracionFase;
            break;
          case "SUENO_REM":
            duracionSuenoREM += duracionFase;
            break;
          default:
            duracionDespierto += duracionFase;
        }
      }

      sesionesProcesadas.push({
        horaInicio,
        horaFin,
        duracionTotal: calcularDuracionEnMinutos(horaInicio, horaFin),
        duracionSuenoProfundo,
        duracionSuenoLigero,
        duracionSuenoREM,
        duracionDespierto,
      });
    }

    return sesionesProcesadas;
  }
  function generarArrayDias(fechaActual) {
    const añoActual = fechaActual.getFullYear();
    const primerDiaAñoPasado = new Date(añoActual - 1, 0, 1); // Año pasado, enero, día 1
    const diasTranscurridos = Math.ceil(
      (fechaActual - primerDiaAñoPasado) / (24 * 60 * 60 * 1000)
    );
    const arrayDias = [];

    for (let i = 0; i <= diasTranscurridos; i++) {
      const fecha = new Date(primerDiaAñoPasado);
      fecha.setDate(fecha.getDate() + i);

      if (fecha > fechaActual) {
        break;
      }

      const year = fecha.getFullYear();
      const month = (fecha.getMonth() + 1).toString().padStart(2, "0");
      const day = fecha.getDate().toString().padStart(2, "0");
      const hours = "00";
      const minutes = "00";

      const horaInicio = `${year}-${month}-${day}T${hours}:${minutes}:00`;

      fecha.setHours(8, 0, 0, 0);
      const horaFin = fecha.toISOString().slice(0, 16);

      arrayDias.push({
        horaInicio,
        horaFin,
        duracionTotal: 0,
        duracionSuenoProfundo: 0,
        duracionSuenoLigero: 0,
        duracionSuenoREM: 0,
        duracionDespierto: 0,
      });
    }
    console.log("Dias", arrayDias);
    return arrayDias;
  }

  function generarArrayDiasaSemana(fechaActual) {
    const añoActual = fechaActual.getFullYear();
    const añoAnterior = añoActual - 1;

    // Función para calcular el primer lunes de un año
    function primerLunesDeAño(año) {
      const primerDiaAño = new Date(año, 0, 1); // 1 de enero de ese año
      return new Date(año, 0, 1 + ((8 - primerDiaAño.getDay()) % 7)); // Primer lunes
    }

    const primerLunesAñoAnterior = primerLunesDeAño(añoAnterior);
    const primerLunesAñoActual = primerLunesDeAño(añoActual);

    const diasTranscurridos = Math.ceil(
      (fechaActual - primerLunesAñoAnterior) / (24 * 60 * 60 * 1000)
    );
    const diasPorSemana = 7;
    const arrayDias = [];

    for (let i = 0; i < diasTranscurridos + diasPorSemana; i++) {
      const fecha = new Date(primerLunesAñoAnterior);
      fecha.setDate(fecha.getDate() + i);

      // Si la fecha es mayor que la fecha actual, salir del ciclo
      if (fecha > fechaActual) {
        break;
      }

      const year = fecha.getFullYear();
      const month = (fecha.getMonth() + 1).toString().padStart(2, "0");
      const day = fecha.getDate().toString().padStart(2, "0");
      const hours = "00";
      const minutes = "00";

      const horaInicio = `${year}-${month}-${day}T${hours}:${minutes}:00`;

      fecha.setHours(8, 0, 0, 0);
      const horaFin = fecha.toISOString().slice(0, 16);

      arrayDias.push({
        horaInicio,
        horaFin,
        duracionTotal: 0,
        duracionSuenoProfundo: 0,
        duracionSuenoLigero: 0,
        duracionSuenoREM: 0,
        duracionDespierto: 0,
      });
    }

    // Verificar y agregar días adicionales si es necesario
    while (arrayDias.length % diasPorSemana !== 0) {
      const lastDate = new Date(arrayDias[arrayDias.length - 1].horaInicio);
      lastDate.setDate(lastDate.getDate() + 1);

      const year = lastDate.getFullYear();
      const month = (lastDate.getMonth() + 1).toString().padStart(2, "0");
      const day = lastDate.getDate().toString().padStart(2, "0");
      const hours = "00";
      const minutes = "00";

      const horaInicio = `${year}-${month}-${day}T${hours}:${minutes}:00`;

      lastDate.setHours(8, 0, 0, 0);
      const horaFin = lastDate.toISOString().slice(0, 16);

      arrayDias.push({
        horaInicio,
        horaFin,
        duracionTotal: 0,
        duracionSuenoProfundo: 0,
        duracionSuenoLigero: 0,
        duracionSuenoREM: 0,
        duracionDespierto: 0,
      });
    }

    const arraySemanas = [];
    for (let i = 0; i < arrayDias.length; i += diasPorSemana) {
      const semana = arrayDias.slice(i, i + diasPorSemana);
      arraySemanas.push(semana);
    }

    console.log("Semanas", arraySemanas);
    return arraySemanas;
  }

  // Función para obtener la fecha sin la hora
  function obtenerFechaSinHora(fecha) {
    return fecha.split("T")[0];
  }

  function cambiarNfecha(n) {
    //ACABAR
    ////Console.log("lista", listasesionesprocesadas);
    if (
      (n < 0 && nfecha > 0) ||
      (n > 0 && nfecha < listasesionesprocesadas.length - 1)
    ) {
      setNfecha(nfecha + n);
    }
    console.log("nfecha", nfecha);
  }

  function convertirMinutosAHoras(minutos) {
    if (isNaN(minutos) || minutos < 0) {
      return "Formato de tiempo no válido";
    }

    const horas = Math.floor(minutos / 60);
    const minutosRestantes = Math.ceil(minutos % 60); // Aproximar hacia arriba

    if (minutos === 0) {
      return "0 h 0 min";
    }

    let resultado = "";

    if (horas > 0) {
      resultado += `${horas} h `;
    }

    if (minutosRestantes > 0) {
      resultado += `${minutosRestantes} min`;
    }

    return resultado.trim(); // Eliminamos espacios al final si no hay minutos
  }

  function calcularHoraMediaInicio(datos) {
    try {
      const diasFiltrados = datos.filter((dia) => dia.duracionTotal !== 0);
      console.log(datos);
      if (diasFiltrados.length === 0) {
        return "00:00";
      }

      const totalMinutos = diasFiltrados.reduce((acumulador, dia) => {
        const [horaStr, minutoStr] = dia.horaInicio.split("T")[1].split(":");
        let minutosDesdeNuevePM =
          (parseInt(horaStr) - 21) * 60 + parseInt(minutoStr);

        // Ajustar para manejar horas antes de las 9 PM
        if (minutosDesdeNuevePM < 0) {
          minutosDesdeNuevePM += 24 * 60; // Agregar 24 horas en minutos
        }

        return acumulador + minutosDesdeNuevePM;
      }, 0);

      const mediaMinutos = totalMinutos / diasFiltrados.length;
      const horasDesdeNuevePM = Math.floor(mediaMinutos / 60);
      const minutosRestantes = Math.round(mediaMinutos % 60);

      const horas = (horasDesdeNuevePM + 21) % 24; // Sumar las horas desde las 9 PM y ajustar al formato de 24 horas
      const formatoHoras = horas.toString().padStart(2, "0");
      const formatoMinutos = minutosRestantes.toString().padStart(2, "0");

      return `${formatoHoras}:${formatoMinutos}`;
    } catch {
      return "00:00";
    }
  }

  function calcularHoraMediaFin(datos) {
    try {
      const diasFiltrados = datos.filter((dia) => dia.duracionTotal !== 0);

      if (diasFiltrados.length === 0) {
        return "00:00";
      }

      const totalMinutos = diasFiltrados.reduce((acumulador, dia) => {
        const [horaStr, minutoStr] = dia.horaFin.split("T")[1].split(":");
        const minutosDesdeMedianoche =
          parseInt(horaStr) * 60 + parseInt(minutoStr);
        return acumulador + minutosDesdeMedianoche;
      }, 0);

      const mediaMinutos = totalMinutos / diasFiltrados.length;
      const horas = Math.floor(mediaMinutos / 60);
      const minutos = Math.round(mediaMinutos % 60);

      const formatoHoras = horas.toString().padStart(2, "0");
      const formatoMinutos = minutos.toString().padStart(2, "0");

      return `${formatoHoras}:${formatoMinutos}`;
    } catch {
      return "00:00";
    }
  }

  function formatFecha(fecha, opcion, locale) {
    const date = new Date(fecha);

    switch (opcion) {
      case "semana":
        const startDate = new Date(date);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);

        const startDay = startDate.getDate();
        const startMonth = locale.shortMonths[startDate.getMonth()];
        const endDay = endDate.getDate();
        const endMonth = locale.shortMonths[endDate.getMonth()];

        return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;

      case "mes":
        return locale.months[date.getMonth()];

      case "ano":
        return date.getFullYear();

      default:
        return "w";
    }
  }

  function calcularMediaPropiedad(semanasGeneradas, propiedad) {
    // Filtrar los elementos donde duracionTotal no es 0
    const diasFiltrados = semanasGeneradas
      .flat() // Aplanamos el array de semanas
      .filter((dia) => dia.duracionTotal !== 0);

    // Verificar si hay elementos después del filtrado
    if (diasFiltrados.length === 0) {
      return 0; // Si no hay elementos, devolver 0
    }

    // Calcular la media para la propiedad especificada
    const total = diasFiltrados.reduce(
      (acumulador, dia) => acumulador + dia[propiedad],
      0
    );
    const media = total / diasFiltrados.length;

    return media;
  }

  function calcularTotalPropiedad(semanasGeneradas, propiedad) {
    // Filtrar los elementos donde duracionTotal no es 0
    const diasFiltrados = semanasGeneradas
      .flat() // Aplanamos el array de semanas
      .filter((dia) => dia.duracionTotal !== 0);

    // Verificar si hay elementos después del filtrado
    if (diasFiltrados.length === 0) {
      return 0; // Si no hay elementos, devolver 0
    }

    // Calcular la total para la propiedad especificada
    const total = diasFiltrados.reduce(
      (acumulador, dia) => acumulador + dia[propiedad],
      0
    );

    return total;
  }

  return (
    <div>
      <TituloyFlecha title={t("Rutina-Sueno")} link={"/MiSalud"} />

      <div
        style={{
          marginTop: "1%",
          marginBottom: "1%",
          margin: window.innerWidth <= 768 ? "3%" : "1%",
        }}
      >
        <Link to="/RutinaSuenoDiaria">
          <button
            className="botongranuralidadSuenoPageDuracion"
            style={{ backgroundColor: colorFondoboton1, color: colorTexto1 }}
          >
            {t("Dia")}
          </button>
        </Link>
        <Link to="/RutinaSueno/semana">
          <button
            className="botongranuralidadSuenoPageDuracion"
            style={{ backgroundColor: colorFondoboton2, color: colorTexto2 }}
          >
            {t("Semana")}
          </button>
        </Link>
        <Link to="/RutinaSueno/mes">
          <button
            className="botongranuralidadSuenoPageDuracion"
            style={{ backgroundColor: colorFondoboton3, color: colorTexto3 }}
          >
            {t("Mes")}
          </button>
        </Link>
        <Link to="/RutinaSueno/ano">
          <button
            className="botongranuralidadSuenoPageDuracion"
            style={{ backgroundColor: colorFondoboton4, color: colorTexto4 }}
          >
            {t("Ano")}
          </button>
        </Link>
      </div>
      <div className="containerBodySueno">
        {chartData && chartData.length > 0 ? (
          <>
            <div className="containerGrafFechaSueno">
              <div className="centerSuenoPageDuracion1">
                {nfecha < listasesionesprocesadas.length - 1 ? (
                  <div style={{ visibility: "visible", marginRight: "10%" }}>
                    <button
                      className="botoncircSuenoPageDuracion"
                      onClick={() => cambiarNfecha(1)}
                    >
                      {t("Anterior")}
                    </button>
                  </div>
                ) : (
                  <div style={{ visibility: "hidden" }}>
                    <button onClick={() => cambiarNfecha(1)}>
                      {t("Anterior")}
                    </button>
                  </div>
                )}

                <p
                  style={{
                    fontSize: "1.1rem",
                    color: "black",
                    width: "150%",
                    marginLeft: "-25%",
                  }}
                >
                  {
                    <b>
                      {formatFecha(chartData[0].horaFin, contenido, locale)}
                    </b>
                  }
                </p>
                {nfecha > 0 ? (
                  <div style={{ visibility: "visible", marginLeft: "20%" }}>
                    <button
                      className="botoncircSuenoPageDuracion"
                      onClick={() => cambiarNfecha(-1)}
                    >
                      {t("Siguiente")}
                    </button>
                  </div>
                ) : (
                  <div style={{ visibility: "hidden" }}>
                    <button onClick={() => cambiarNfecha(-1)}>
                      {t("Siguiente")}
                    </button>
                  </div>
                )}
              </div>

              <div className="graficoSuenoPage" style={{ marginTop: "0%" }}>
                <CustomBarChartSueno
                  data={chartData}
                  dateDisplayType={contenidoAMostrar}
                />
              </div>
            </div>

            <div
              className="centerSuenoPageDuracion3"
              style={{ marginTop: "1%" }}
            >
              <div
                style={{
                  marginLeft: "2%",
                  marginRight: "2%",
                  marginTop: window.innerWidth <= 768 ? "25%" : "0%",
                }}
              >
                <Card
                  content={
                    <div style={{ color: "black" }}>
                      <h4 style={{ textAlign: "center", marginTop: "0.5%" }}>
                        {textocuadro1}{" "}
                      </h4>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "1%",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            textAlign: "left",
                            marginLeft: "3%",
                          }}
                        >
                          <b> {t("Hora-media-de-acostarse")}</b>
                          <br />
                          {calcularHoraMediaInicio(chartData)}
                        </div>
                        <div
                          style={{
                            flex: 1,
                            textAlign: "right",
                            marginRight: "3%",
                          }}
                        >
                          <b>{t("Hora-media-de-despertarse")}</b>
                          <br />
                          {calcularHoraMediaFin(chartData)}
                        </div>
                      </div>
                      <div style={{ textAlign: "center", marginTop: "0%" }}>
                        <b>{t("Tiempo-medio-de-sueno")}</b>
                        <br />
                        {convertirMinutosAHoras(
                          calcularMediaPropiedad(chartData, "duracionTotal") -
                            calcularMediaPropiedad(
                              chartData,
                              "duracionDespierto"
                            )
                        )}
                      </div>
                    </div>
                  }
                  className={"cardInfoSuenoDuracion"}
                ></Card>
              </div>

              <div style={{ marginRight: "2%", marginLeft: "2%" }}>
                <Card
                  content={
                    <div style={{ color: "black" }}>
                      <h4
                        className="titulocardsSuenoPageDuracion"
                        style={{
                          textAlign: "center",
                          marginBottom: "1%",
                          marginTop: "0.5%",
                        }}
                      >
                        {textocuadro2}
                      </h4>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          gridTemplateRows: "repeat(2, auto)",
                          gridGap: "10%",
                          marginTop: "1%",
                        }}
                      >
                        <div>
                          <b>
                            {" "}
                            <FaCircle style={{ color: "#DFCCFB" }} />{" "}
                            {t("Sueño-Ligero")}
                          </b>
                          <br />
                          {convertirMinutosAHoras(
                            calcularMediaPropiedad(
                              chartData,
                              "duracionSuenoLigero"
                            )
                          )}
                        </div>
                        <div>
                          <b>
                            <FaCircle style={{ color: "#BEAFFF" }} />{" "}
                            {t("Sueño-Profundo")}
                          </b>
                          <br />
                          {convertirMinutosAHoras(
                            calcularMediaPropiedad(
                              chartData,
                              "duracionSuenoProfundo"
                            )
                          )}
                        </div>
                        <div>
                          <b>
                            <FaCircle style={{ color: "#FFF8C9" }} />{" "}
                            {t("Despierto/a")}
                          </b>
                          <br />
                          {convertirMinutosAHoras(
                            calcularMediaPropiedad(
                              chartData,
                              "duracionDespierto"
                            )
                          )}
                        </div>
                        <div>
                          <b>
                            <FaCircle style={{ color: "#756AB6" }} /> REM
                          </b>
                          <br />
                          {convertirMinutosAHoras(
                            calcularMediaPropiedad(
                              chartData,
                              "duracionSuenoREM"
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  }
                  className={"cardInfoSuenoDuracion"}
                ></Card>
              </div>
            </div>
          </>
        ) : (
          <div style={{ color: "black", marginTop: "20%" }}>
            <b>{t("Conectando-BD")}</b>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuenoPageDuracion;

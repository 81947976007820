import { useEffect, useContext, useState } from "react";
import UserContext from "../UserContext";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Card from "../components/Card";
import { useTranslation } from "react-i18next";
import "./EstadodeAnimo.css";
import mood1 from "../Images/mood1.png";
import mood2 from "../Images/mood2.png";
import mood3 from "../Images/mood3.png";
import mood4 from "../Images/mood4.png";
import mood5 from "../Images/mood5.png";
import EstadoAnimoGrafico from "../components/Graficos/EstadoAnimoGrafico";
import TituloyFlecha from "../components/TituloyFlecha";

const EstadodeAnimo = () => {
  const email = useContext(UserContext);
  const [t] = useTranslation("global");
  const { contenido } = useParams();
  const [chartData, setChartData] = useState(null);
  const [nfecha, setNfecha] = useState(0);
  const [listaAgrupada, setListaAgrupada] = useState(null);
  const [colorFondoboton2, setColorFondoboton2] = useState("#b9ddd7");
  const [colorFondoboton3, setColorFondoboton3] = useState("#b9ddd7");
  const [colorFondoboton4, setColorFondoboton4] = useState("#b9ddd7");
  const [colorTexto2, setColorTexto2] = useState("black");
  const [colorTexto3, setColorTexto3] = useState("black");
  const [colorTexto4, setColorTexto4] = useState("black");
  const [textocuadro, setTextocuadro] = useState(t("Estado-Animo-Grafico.titulo"));
  const [contenidoAMostrar, setContenidoAMostrar] = useState(null);
  const locale = {
    dateTime: "%A, %e de %B de %Y, %X",
    date: "%d/%m/%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: [
      t("DiasSemana.Domingo"),
      t("DiasSemana.Lunes"),
      t("DiasSemana.Martes"),
      t("DiasSemana.Miercoles"),
      t("DiasSemana.Jueves"),
      t("DiasSemana.Viernes"),
      t("DiasSemana.Sabado"),
    ],
    shortDays: [
      t("shortDiasSemana.Domingo"),
      t("shortDiasSemana.Lunes"),
      t("shortDiasSemana.Martes"),
      t("shortDiasSemana.Miercoles"),
      t("shortDiasSemana.Jueves"),
      t("shortDiasSemana.Viernes"),
      t("shortDiasSemana.Sabado"),
    ],
    months: [
      t("Meses.Enero"),
      t("Meses.Febrero"),
      t("Meses.Marzo"),
      t("Meses.Abril"),
      t("Meses.Mayo"),
      t("Meses.Junio"),
      t("Meses.Julio"),
      t("Meses.Agosto"),
      t("Meses.Septiembre"),
      t("Meses.Octubre"),
      t("Meses.Noviembre"),
      t("Meses.Diciembre"),
    ],
    shortMonths: [
      t("shortMeses.Enero"),
      t("shortMeses.Febrero"),
      t("shortMeses.Marzo"),
      t("shortMeses.Abril"),
      t("shortMeses.Mayo"),
      t("shortMeses.Junio"),
      t("shortMeses.Julio"),
      t("shortMeses.Agosto"),
      t("shortMeses.Septiembre"),
      t("shortMeses.Octubre"),
      t("shortMeses.Noviembre"),
      t("shortMeses.Diciembre"),
    ],
  };

  useEffect(() => {
    const consulta_estado_animo = async () => {
      setNfecha(0);
      const currentDate = new Date();
      const oneYearAgo = new Date();
      oneYearAgo.setDate(currentDate.getDate() - 365);
      const formattedCurrentDate = currentDate.toISOString().split("T")[0];
      const formattedOneYearAgo = oneYearAgo.toISOString().split("T")[0];

      try {
        const response = await axios.get(t("Recursos.ip") + "/estadoanimo", {
          params: {
            email: email.email,
            startTime: formattedOneYearAgo,
            endTime: formattedCurrentDate,
          },
        });
        let groupedData;
        // eslint-disable-next-line default-case
        switch (contenido) {
          case "semana":
            groupedData = groupByWeek(response.data);
            console.log("Semana", groupedData);
            break;
          case "mes":
            groupedData = groupByMonth(response.data);
            console.log("Mes", groupedData);
            break;
          case "ano":
            groupedData = groupByYear(response.data);
            console.log("Año", groupedData);
            groupedData = getMonthlyAverages(groupedData[0]);
            console.log("Promedios Mensuales:", groupedData);
            break;
        }
        setListaAgrupada(groupedData);
        setChartData(getChartData(groupedData[nfecha]));

        console.log("Datos", response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    consulta_estado_animo();
  }, [email, contenido, t]);

  useEffect(() => {
    if (contenido) {
      switch (contenido) {
        case "semana":
          setColorFondoboton2("#518ea6");
          setColorFondoboton3("#b9ddd7");
          setColorFondoboton4("#b9ddd7");
          setColorTexto2("white");
          setColorTexto3("black");
          setColorTexto4("black");
          setContenidoAMostrar("1");
          setTextocuadro(t("Estado-Animo-Grafico.media-semana"));
          break;
        case "mes":
          setColorFondoboton2("#b9ddd7");
          setColorFondoboton3("#518ea6");
          setColorFondoboton4("#b9ddd7");
          setColorTexto2("black");
          setColorTexto3("white");
          setColorTexto4("black");
          setContenidoAMostrar("1");
          setTextocuadro(t("Estado-Animo-Grafico.media-mes"));
          break;
        case "ano":
          setColorFondoboton2("#b9ddd7");
          setColorFondoboton3("#b9ddd7");
          setColorFondoboton4("#518ea6");
          setColorTexto2("black");
          setColorTexto3("black");
          setColorTexto4("white");
          setContenidoAMostrar("3");
          setTextocuadro(t("Estado-Animo-Grafico.media-ano"));
          break;
        default:
          setColorFondoboton2("#518ea6");
          setColorFondoboton3("#b9ddd7");
          setColorFondoboton4("#b9ddd7");
          setColorTexto2("white");
          setColorTexto3("black");
          setColorTexto4("black");
          setContenidoAMostrar("1");
          setTextocuadro(t("Estado-Animo-Grafico.titulo"));
      }
    }
  }, [contenido, t]);

  useEffect(() => {
    if (nfecha !== null && listaAgrupada)
      setChartData(getChartData(listaAgrupada[nfecha]));
  }, [nfecha, listaAgrupada]);

  const groupByToArray = (array, getKey, fillMissingDatesFn) => {
    const grouped = array.reduce((acc, item) => {
      const key = getKey(item.fecha);
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    return Object.values(grouped).map((group) =>
      fillMissingDatesFn(group, array)
    );
  };

  const getWeekNumber = (date) => {
    const d = new Date(date);
    const firstDayOfYear = new Date(d.getFullYear(), 0, 1);
    const pastDaysOfYear = (d - firstDayOfYear) / 86400000;
    // Ajustar el cálculo para que la semana comience en lunes
    const adjustedDay = (firstDayOfYear.getUTCDay() + 6) % 7; // Ajuste para que el primer día del año considere lunes
    return Math.ceil((pastDaysOfYear + adjustedDay + 1) / 7);
  };

  const getMonth = (date) => {
    const d = new Date(date);
    return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, "0")}`;
  };

  const getYear = (date) => {
    const d = new Date(date);
    return d.getFullYear().toString();
  };

  const getWeekDates = (date) => {
    const d = new Date(date);
    const dayOfWeek = (d.getUTCDay() + 6) % 7; // Ajuste para que la semana comience en lunes
    const startOfWeek = new Date(d);
    startOfWeek.setUTCDate(d.getUTCDate() - dayOfWeek);
    const weekDates = [];
    for (let i = 0; i < 7; i++) {
      const currentDay = new Date(startOfWeek);
      currentDay.setUTCDate(startOfWeek.getUTCDate() + i);
      weekDates.push(currentDay.toISOString().split("T")[0]);
    }
    return weekDates;
  };

  const fillMissingWeekDates = (group) => {
    if (group.length === 0) return group;
    const weekDates = getWeekDates(group[0].fecha);
    const filledGroup = weekDates.map((date) => {
      const found = group.find((item) => item.fecha === date);
      return found ? found : { estadoAnimo: 0, fecha: date };
    });
    return filledGroup;
  };

  const monthsWith31Days = ["01", "03", "05", "07", "08", "10", "12"];
  // const monthsWith30Days = ["04", "06", "09", "11"];

  const fillMissingMonthDates = (group, originalData) => {
    if (group.length === 0) return group;

    const firstDate = new Date(group[0].fecha);
    const monthDates = getMonthDates(firstDate);
    let filledGroup = monthDates.map((date) => {
      const found = group.find((item) => item.fecha === date);
      if (found) return found;

      // Verificar si la fecha existe en los datos originales
      const originalFound = originalData.find((item) => item.fecha === date);
      return originalFound ? originalFound : { estadoAnimo: 0, fecha: date };
    });

    const month = String(firstDate.getUTCMonth() + 1).padStart(2, "0");
    const year = firstDate.getUTCFullYear();

    if (monthsWith31Days.includes(month) && filledGroup.length < 31) {
      const day31 = `${year}-${month}-31`;
      if (!filledGroup.find((item) => item.fecha === day31)) {
        const originalFound = originalData.find((item) => item.fecha === day31);
        filledGroup.push(
          originalFound ? originalFound : { estadoAnimo: 0, fecha: day31 }
        );
      }
    }

    filledGroup.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));

    return filledGroup;
  };

  const getMonthDates = (date) => {
    const d = new Date(date);
    const year = d.getUTCFullYear();
    const month = d.getUTCMonth();
    const daysInMonth = new Date(year, month + 1, 0).getUTCDate();
    const monthDates = Array.from({ length: daysInMonth }, (_, i) => {
      const date = new Date(Date.UTC(year, month, i + 1));
      return date.toISOString().split("T")[0];
    });
    return monthDates;
  };

  const fillMissingYearDates = (group) => {
    if (group.length === 0) return group;
    const year = new Date(group[0].fecha).getUTCFullYear();
    const yearDates = Array.from(
      { length: 365 + (isLeapYear(year) ? 1 : 0) },
      (_, i) => {
        const date = new Date(Date.UTC(year, 0, i + 1));
        return date.toISOString().split("T")[0];
      }
    );

    const filledGroup = yearDates.map((date) => {
      const found = group.find((item) => item.fecha === date);
      return found ? found : { estadoAnimo: 0, fecha: date };
    });
    return filledGroup;
  };

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const groupByWeek = (array) =>
    groupByToArray(
      array,
      (date) => `${getYear(date)}-W${getWeekNumber(date)}`,
      fillMissingWeekDates
    );

  const groupByMonth = (array) =>
    groupByToArray(array, getMonth, fillMissingMonthDates);

  const groupByYear = (array) =>
    groupByToArray(array, getYear, fillMissingYearDates);

  const getChartData = (lista) => {
    if (!lista) {
      return null;
    } else {
      const dias = lista.map((item) => item.fecha);
      const estado_animo = lista.map((item) => item.estadoAnimo);
      return {
        ejeY: estado_animo,
        ejeX: dias,
      };
    }
  };
  const getMonthlyAverages = (groupByYearData) => {
    const monthlyAverages = [];

    const dataByYear = groupByToArray(
      groupByYearData,
      getYear,
      fillMissingYearDates
    );

    dataByYear.forEach((yearData) => {
      const dataByMonth = groupByToArray(
        yearData,
        getMonth,
        fillMissingMonthDates
      );

      const yearMonthlyAverages = dataByMonth.map((monthData) => {
        const validEntries = monthData.filter((item) => item.estadoAnimo > 0);
        const average =
          validEntries.length > 0
            ? Math.round(
                validEntries.reduce((acc, item) => acc + item.estadoAnimo, 0) /
                  validEntries.length
              )
            : 0;
        const firstDayOfMonth = monthData[0].fecha;
        return { estadoAnimo: average, fecha: firstDayOfMonth };
      });

      monthlyAverages.push(yearMonthlyAverages);
    });

    return monthlyAverages;
  };

  const moodImages = {
    1: mood1,
    2: mood2,
    3: mood3,
    4: mood4,
    5: mood5,
  };

  function formatFecha(fecha) {
    const date = new Date(fecha);

    switch (contenido) {
      case "semana":
        // Encuentra el lunes anterior al primer día del rango de fechas
        const startDate = new Date(date);
        const endDate = new Date(date);
        // startDate.setDate(startDate.getDate() - startDate.getDay()); // Resta los días necesarios para llegar al lunes
        endDate.setDate(endDate.getDate() + (7 - endDate.getDay())); // Suma los días necesarios para llegar al domingo

        const startDay = startDate.getDate();
        const startMonth = locale.shortMonths[startDate.getMonth()];
        const endDay = endDate.getDate();
        const endMonth = locale.shortMonths[endDate.getMonth()];

        return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;

      case "mes":
        return locale.months[date.getMonth()];

      case "ano":
        return date.getFullYear();

      default:
        return "w";
    }
  }

  function cambiarNfecha(n) {
    if ((n < 0 && nfecha > 0) || (n > 0 && nfecha < listaAgrupada.length - 1)) {
      setNfecha(nfecha + n);
    }
  }
  const getMaxValue = (array) => {
    const validValues = array.filter((value) => value > 0);
    return validValues.length > 0 ? Math.max(...validValues) : 0;
  };
  const getMinValue = (array) => {
    const validValues = array.filter((value) => value > 0);
    return validValues.length > 0 ? Math.min(...validValues) : 0;
  };
  const getAverageValue = (array) => {
    const validValues = array.filter((value) => value > 0);
    return validValues.length > 0
      ? Math.round(
          validValues.reduce((acc, value) => acc + value, 0) /
            validValues.length
        )
      : 0;
  };

  return (
    <div>

      <TituloyFlecha
        title={t("Estado-Animo-Grafico.titulo")}
        link={"/"}
      />
      <div
        style={{
          marginTop: "1%",
          marginBottom: "1%",
          margin: window.innerWidth <= 768 ? "3%" : "1%",
        }}
      >
        <Link to="/EstadodeAnimo/semana">
          <button
            className="botongranuralidadEstadoAnimo"
            style={{ backgroundColor: colorFondoboton2, color: colorTexto2 }}
          >
            {t("Semana")}
          </button>
        </Link>
        <Link to="/EstadodeAnimo/mes">
          <button
            className="botongranuralidadEstadoAnimo"
            style={{ backgroundColor: colorFondoboton3, color: colorTexto3 }}
          >
            {t("Mes")}
          </button>
        </Link>
        <Link to="/EstadodeAnimo/ano">
          <button
            className="botongranuralidadEstadoAnimo"
            style={{ backgroundColor: colorFondoboton4, color: colorTexto4 }}
          >
            {t("Ano")}
          </button>
        </Link>
      </div>

      <div className="containerBodyPulsaciones">
        {chartData ? (
          <>
            <div className="containerGrafFechaPulsaciones">
              <div className="centerPulsacionesPage1MaxMin">
                {nfecha < listaAgrupada.length - 1 ? (
                  <div style={{ visibility: "visible", marginRight: "10%" }}>
                    <button
                      className="botoncircEstadoAnimo"
                      onClick={() => cambiarNfecha(1)}
                    >
                      {t("Anterior")}
                    </button>
                  </div>
                ) : (
                  <div style={{ visibility: "hidden" }}>
                    <button onClick={() => cambiarNfecha(1)}>
                      {t("Anterior")}
                    </button>
                  </div>
                )}
                <p
                  style={{
                    fontSize: "1.1rem",
                    color: "black",
                    width: "150%",
                    marginLeft: "-20%",
                  }}
                >
                  {"  "}
                  {
                    <b>
                      {formatFecha(
                        chartData.ejeX[0],
                        contenidoAMostrar,
                        locale
                      )}
                    </b>
                  }
                  {"  "}
                </p>
                {nfecha > 0 ? (
                  <div style={{ visibility: "visible", marginLeft: "20%" }}>
                    <button
                      className="botoncircEstadoAnimo"
                      onClick={() => cambiarNfecha(-1)}
                    >
                      {t("Siguiente")}
                    </button>
                  </div>
                ) : (
                  <div style={{ visibility: "hidden" }}>
                    <button onClick={() => cambiarNfecha(-1)}>
                      {t("Siguiente")}
                    </button>
                  </div>
                )}
              </div>
              <div className="graficoEstadoAnimo">
                <EstadoAnimoGrafico
                  yValues={chartData.ejeY}
                  xValues={chartData.ejeX}
                  images={moodImages}
                  granularity={contenido}
                />
              </div>
            </div>
            <div
              className="centerPulsacionePageDuracion3"
              style={{ marginBottom: "1%" }}
            >
              <Card
                content={
                  <div style={{ color: "black" }}>
                    <h3 style={{ textAlign: "center", marginTop: "0.5%" }}>
                      {textocuadro}{" "}
                    </h3>

                    {/* Elemento arriba */}
                    <div style={{ textAlign: "center", marginTop: "0%" }}>
                      <b> {t("Estado-Animo-Grafico.medio")}</b>
                      <br />
                      <img
                        src={moodImages[getAverageValue(chartData.ejeY)]}
                        alt={t("Estado-Animo.Bien")}
                        className="imagenEstadoAnimo1"
                      />

                     
                    </div>

                    {/* Dos elementos debajo */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "-1%",
                        
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          textAlign: "center",
                          marginRight: "3%",
                          
                        }}
                      >
                        <b> {t("Estado-Animo-Grafico.Peor")}</b>
                        <br />
                        <img
                          src={moodImages[getMinValue(chartData.ejeY)]}
                          alt={t("Estado-Animo.Bien")}
                          className="imagenEstadoAnimo2"
                        />

                        {/* {chartData[nfecha].filter(
                          (item) => item.minfpVal !== 0
                        ).length > 0
                          ? Math.min(
                              ...chartData[nfecha]
                                .filter((item) => item.minfpVal !== 0)
                               .map((item) => item.minfpVal)
                            )
                          : 0}{" "} */}
                      </div>

                      <div
                        style={{
                          flex: 1,
                          textAlign: "center",
                          marginLeft: "3%",
                          
                        }}
                      >
                        <b> {t("Estado-Animo-Grafico.Mejor")}</b>
                        <br />
                        <img
                          src={moodImages[getMaxValue(chartData.ejeY)]}
                          alt={t("Estado-Animo.Bien")}
                          className="imagenEstadoAnimo2"
                        />

                        {/* {chartData[nfecha].filter(
                          (item) => item.maxfpVal !== 0
                        ).length > 0
                          ? Math.max(
                              ...chartData[nfecha]
                                .filter((item) => item.maxfpVal !== 0)
                               .map((item) => item.maxfpVal)
                            )
                          : 0}{" "} */}
                 
                      </div>
                     
                    </div>
                  </div>
                }
                className={"cardEstadoAnimo"}
              ></Card>
            </div>
          </>
        ) : (
          <div style={{ color: "black", marginTop: "20%" }}>
            <b>{t("Conectando-BD")}</b>
          </div>
        )}
      </div>
    </div>
  );
};

export default EstadodeAnimo;

import React, { useState, useEffect } from "react";
import "./CuestionariosAdmin.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import TituloyFlecha from "../../components/TituloyFlecha";
import { BsDownload } from "react-icons/bs";
import SelectEtiquetaUsuarioAdmin from "./components/SelectEtiquetaUsuarioAdmin";

function CuestionariosAdmin() {
  const { t } = useTranslation("global");
  const [cuestionario, setCuestioario] = useState(null);
  const [intento, setIntento] = useState(null);
  const [mostraradvertencia, setMostraradvertencia] = useState(false);

  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  useEffect(() => {
    axios.get(`${t("Recursos.ip")}/etiqueta/findAll`)
    .catch((error) => console.error("Error al obtener los cuidadores:", error))
    .then((response) => {
      if (!response || !response.data) {
        return console.error("No se pudieron obtener las etiquetas");
      }
      setTags(response.data.map((tag) => ({ value: tag.id, label: tag.nombre })));
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const download_Cuestionario = async () => {
    console.log("Cuestionario:", cuestionario, "Intento:", intento);
    if (cuestionario !== null && intento !== null) {
      setMostraradvertencia(false);
      try {
        const response = await axios.get(
          t("Recursos.ip") + "/respuesta/descargarCuestionario",
          {
            params: { cuestionario: cuestionario, intento: intento },
            responseType: "blob", // Esto es importante para obtener una respuesta binaria (blob)
          }
        );

        // Crea una URL de objeto a partir de la respuesta
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        // Crea un enlace de descarga y simula un clic en él para iniciar la descarga
        const a = document.createElement("a");
        a.href = url;
        a.download =
          "Cuestionario_" +
          t(`Cuestionario${cuestionario}.Titulo`) +
          "_Intento_" +
          (intento === 4 ? "todos" : intento) +
          ".csv"; // Nombre del archivo corregido
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();

        // Libera la URL de objeto cuando ya no se necesita
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error al descargar el archivo", error);
      }
    } else {
      setMostraradvertencia(true);
    }
  };

  const download_Pasos_CSV = async () => {
    try {
      const response = await axios.get(
        t("Recursos.ip") + "/pasos/descargarPasosGlobal",
        {
          responseType: "blob", // Esto es importante para obtener una respuesta binaria (blob)
        }
      );

      // Crea una URL de objeto a partir de la respuesta
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      // Crea un enlace de descarga y simula un clic en él para iniciar la descarga
      const a = document.createElement("a");
      a.href = url;
      a.download = `PasosGlobales.csv`; // Puedes establecer el nombre del archivo aquí
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();

      // Libera la URL de objeto cuando ya no se necesita
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar el archivo", error);
    }
  };

  const download_Pulsaciones_CSV = async () => {
    try {
      const response = await axios.get(
        t("Recursos.ip") + "/pulsaciones/descargarPulsacionesGlobal",
        {
          responseType: "blob", // Esto es importante para obtener una respuesta binaria (blob)
        }
      );

      // Crea una URL de objeto a partir de la respuesta
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      // Crea un enlace de descarga y simula un clic en él para iniciar la descarga
      const a = document.createElement("a");
      a.href = url;
      a.download = `PulsacioneGlobales.csv`; // Puedes establecer el nombre del archivo aquí
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();

      // Libera la URL de objeto cuando ya no se necesita
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar el archivo", error);
    }
  };

  const download_Sueno_CSV = async () => {
    try {
      const response = await axios.get(t("Recursos.ip") + "/sueno/findAll");

      console.log("Datos: ", response.data);
      // console.log("Datos ids: ",getUniqueIds( response.data));
      //  console.log("Datos procesados: ",transformarSesiones(encontrarSesionesSuenoPorIds(procesarArray(response.data),getUniqueIds( response.data))))
      generarCSV(transformarSesiones(encontrarSesionesSuenoPorIds(procesarArray(response.data), getUniqueIds(response.data))))
    } catch (error) {
      console.error("Error al descargar el archivo", error);
    }
  };
  const getUniqueIds = (data) => {
    const ids = data.map(item => item.cuidador_id); // Extrae todos los IDs
    return [...new Set(ids)]; // Devuelve solo los IDs únicos
  };

  const download_EstadoAnimo_CSV = async () => {
    try {
      const response = await axios.get(t("Recursos.ip") + "/estadoanimo/descargarEstadosAnimoGlobal", {
        responseType: "blob", // Esto es importante para obtener una respuesta binaria (blob)
      }
      );

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `EstadosAnimoGlobales.csv`; // Puedes establecer el nombre del archivo aquí
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar el archivo", error);
    }
  };
  function redondearHaciaArriba(fecha) {
    // Obtenemos los minutos y los segundos de la fecha
    const minutos = fecha.getMinutes();
    const segundos = fecha.getSeconds();

    // Redondeamos hacia arriba los segundos dividiendo entre 60 y sumando 1
    const segundosRedondeados = Math.ceil(segundos / 60) * 60;

    // Creamos una nueva fecha con los minutos originales y los segundos redondeados
    const nuevaFecha = new Date(fecha);
    nuevaFecha.setSeconds(0); // Reiniciamos los segundos
    nuevaFecha.setMinutes(
      minutos + Math.floor((segundos + segundosRedondeados) / 60)
    ); // Sumamos los minutos y el cociente de la división
    return nuevaFecha;
  }
  function formatearFechas(fecha) {
    if (!(fecha instanceof Date)) {
      // Si no es un objeto Date, puedes manejar el error de alguna manera
      console.error("Error: Se esperaba un objeto Date");
      return null;
    }

    const year = fecha.getFullYear();
    const month = String(fecha.getMonth() + 1).padStart(2, "0");
    const day = String(fecha.getDate()).padStart(2, "0");
    const hours = String(fecha.getHours()).padStart(2, "0");
    const minutes = String(fecha.getMinutes()).padStart(2, "0");
    const seconds = String(fecha.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }
  // Función para procesar el array de fechas
  function procesarArray(array) {
    // Iteramos sobre cada objeto en el array
    const nuevoArray = array
      .map(item => {
        // Convertimos las cadenas de fecha a objetos Date
        const fechaInicio = new Date(item.t_inicio);
        const fechaFin = new Date(item.t_fin);

        // Redondeamos hacia arriba las fechas
        const nuevaFechaInicio = redondearHaciaArriba(fechaInicio);
        const nuevaFechaFin = redondearHaciaArriba(fechaFin);

        // Formateamos las fechas redondeadas al formato original
        const fechaInicioFormateada = formatearFechas(nuevaFechaInicio);
        const fechaFinFormateada = formatearFechas(nuevaFechaFin);

        // Verificamos si el formateo fue exitoso antes de devolver el nuevo objeto
        if (fechaInicioFormateada && fechaFinFormateada) {
          return {
            ...item,
            t_inicio: fechaInicioFormateada,
            t_fin: fechaFinFormateada,
          };
        } else {
          // Puedes manejar el error de alguna manera
          console.error("Error al formatear la fecha");
          return null;
        }
      })
      .filter(Boolean) // Filtramos los elementos nulos que pueden haber sido devueltos en caso de error
      .filter(item => item.t_inicio !== item.t_fin); // Filtramos los objetos donde t_inicio sea igual a t_fin

    return nuevoArray;
  }


  function encontrarSesionesSuenoPorIds(fasesSueno, ids) {
    const resultados = ids.map(id => {
      const fasesParaId = fasesSueno.filter(fase => fase.cuidador_id === id);

      // Ordena las fases de sueño por fecha de inicio en orden ascendente.
      fasesParaId.sort((a, b) => new Date(a.t_inicio) - new Date(b.t_inicio));

      const sesiones = [];
      let currentSession = [];
      let piloto_id = null;

      for (let i = 0; i < fasesParaId.length; i++) {
        if (!piloto_id && fasesParaId[i].piloto_id) {
          piloto_id = fasesParaId[i].piloto_id;
        }

        if (currentSession.length === 0) {
          currentSession.push(fasesParaId[i]);
        } else {
          const fechaFinAnterior = new Date(currentSession[currentSession.length - 1].t_fin);
          const fechaInicioActual = new Date(fasesParaId[i].t_inicio);

          if (fechaFinAnterior.getTime() === fechaInicioActual.getTime()) {
            currentSession.push(fasesParaId[i]);
          } else {
            sesiones.push(currentSession);
            currentSession = [fasesParaId[i]];
          }
        }
      }

      if (currentSession.length > 0) {
        sesiones.push(currentSession);
      }

      return {
        id: id,
        piloto_id: piloto_id,
        sesiones: sesiones
      };
    });

    return resultados;
  }


  function transformarSesiones(datos) {
    return datos.map(cuidador => {
      const sesionesTransformadas = cuidador.sesiones.map(sesion => {
        const fechaInicio = new Date(sesion[0].t_inicio);
        const fechaFin = new Date(sesion[sesion.length - 1].t_fin);

        const tiempos = {
          SUENO_LIGERO: 0,
          SUENO_PROFUNDO: 0,
          SUENO_REM: 0,
          DESPIERTO: 0
        };

        sesion.forEach(fase => {
          const faseInicio = new Date(fase.t_inicio);
          const faseFin = new Date(fase.t_fin);
          const tiempoFaseMinutos = (faseFin - faseInicio) / 60000; // Conversión de milisegundos a minutos
          tiempos[fase.fasesSuenoEnum] += tiempoFaseMinutos; // Suma el tiempo de la fase correspondiente
        });

        const tiempoTotalMinutos = tiempos.SUENO_LIGERO + tiempos.SUENO_PROFUNDO + tiempos.SUENO_REM;

        return {
          fecha_acosto: formatearFechas(fechaInicio),
          fecha_desperto: formatearFechas(fechaFin),
          tiempo_total_minutos: tiempoTotalMinutos,
          tiempo_sueno_ligero: tiempos.SUENO_LIGERO,
          tiempo_sueno_profundo: tiempos.SUENO_PROFUNDO,
          tiempo_sueno_rem: tiempos.SUENO_REM,
          tiempo_despierto: tiempos.DESPIERTO
        };
      });

      return {
        id: cuidador.id,
        piloto_id: cuidador.piloto_id,
        sesiones: sesionesTransformadas,
      };
    });
  }


  function generarCSV(datosTransformados) {
    const filas = [];
    const encabezados = [
      'ID', 'ID_Piloto', 'Hora acostarse', 'Hora despertarse', 'Total de sueno',
      'Sueno Ligero', 'Sueno Profundo', 'REM', 'Despierto'
    ];


    filas.push(encabezados.join(';')); // Agrega el encabezado al CSV

    datosTransformados.forEach(cuidador => {
      cuidador.sesiones.forEach(sesion => {
        const fila = [
          cuidador.id,
          cuidador.piloto_id,
          sesion.fecha_acosto,
          sesion.fecha_desperto,
          sesion.tiempo_total_minutos,
          sesion.tiempo_sueno_ligero,
          sesion.tiempo_sueno_profundo,
          sesion.tiempo_sueno_rem,
          sesion.tiempo_despierto
        ];
        filas.push(fila.join(';')); // Agrega cada fila al CSV con punto y coma como delimitador
      });
    });

    const csv = filas.join('\n');

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'SuenoGlobales.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  return (
    <div className="CuestionariosAdmin">
      <TituloyFlecha title={t("Globales")} link={"/Admin"} />
      <SelectEtiquetaUsuarioAdmin
        tags={tags}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
      <div className="columns-container">
        <div className="left-container">
          <div className="column">
            <p className="big-textoCuestionariosAdmin">Cuestionario</p>
            {[1, 2, 3, 4, 5, 6, 8, 9, 0].map((num) => (
              <button
                key={num}
                className={`big-buttonCuestionariosAdmin ${cuestionario === num ? "active" : ""
                  }`}
                onClick={() => {
                  setCuestioario(num);
                  if (num === 9 || num === 8) {
                    setIntento(4);
                  }
                }}
              >
                {t(`Cuestionario${num}.Titulo`)}
              </button>
            ))}
          </div>

          <div className="column">
            <p className="big-textoCuestionariosAdmin">Intentos</p>
            {[1, 2, 3, 4].map((num) => (
              <button
                key={num}
                className={`big-buttonCuestionariosAdmin ${intento === num ? "active" : ""
                  }`}
                onClick={() => setIntento(num)}
                disabled={cuestionario === 9 || cuestionario === 8}
              >
                {num === 4 ? "Todos" : num}
              </button>
            ))}
          </div>

          <div className="column-bottom">
            <button
              className="big-buttonCuestionariosAdmin descargar-button"
              onClick={() => download_Cuestionario()}
            >
              {t("Descarga")}
              <BsDownload />
            </button>
          </div>
        </div>

        <div className="separator"></div>

        <div className="right-container">
          <p className="big-textoCuestionariosAdmin">Datos Salud</p>
          <button
            className="big-buttonCuestionariosAdmin"
            onClick={() => download_Pasos_CSV()}
          >
            {t("Pasos")}&nbsp;&nbsp;
            <BsDownload />
          </button>
          <button
            className="big-buttonCuestionariosAdmin"
            onClick={() => download_Sueno_CSV()}
          >
            {t("Sueno")}&nbsp;&nbsp;
            <BsDownload />
          </button>
          <button
            className="big-buttonCuestionariosAdmin"
            onClick={() => download_Pulsaciones_CSV()}
          >
            {t("Pulsaciones")}&nbsp;&nbsp;
            <BsDownload />
          </button>
          <button
            className="big-buttonCuestionariosAdmin"
            onClick={() => download_EstadoAnimo_CSV()}
          >
            {t("Estado-Animo-Grafico.titulo")}&nbsp;&nbsp;
            <BsDownload />
          </button>
        </div>

      </div>

      <div className="download-container">
        {mostraradvertencia && (
          <p className="advertencia-texto">
            Por favor, selecciona un cuestionario y un intento.
          </p>
        )}
      </div>
    </div>
  );
}

export default CuestionariosAdmin;

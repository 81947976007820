import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "../components/Card";
import "./RellenoRecomendados.css";

function RellenoContenidos({ nombre, contenido }) {
    const [windowWidth] = useState(window.innerWidth);
    const [contenidolink, setContenidolink] = useState(null);
    let componenteRenderizado;
    let altocard = "21vh";
    let anchocard = "21vh";
    if (windowWidth < 800) {
        altocard = "20vh";
        anchocard = "20vh";
    }

    useEffect(() => {
        switch (nombre) {
            case "CareQoL.pdf":
                setContenidolink("/Cuestionario/2/intentos")
                break;
            case "Empoderamiento.pdf":
                setContenidolink("/Cuestionario/6/intentos/")
                break;
            case "EQ5D5L.pdf":
                setContenidolink("/Cuestionario/1/intentos/")
                break;
            case "EquilibrioOcupacional.pdf":
                setContenidolink("/Cuestionario/5/intentos/")
                break;
            case "EsfuerzoCuidador.pdf":
                setContenidolink("/Cuestionario/4/intentos/")
                break;
            case "Satisfaccion.pdf":
                setContenidolink("/Cuestionario/8/intentos/")
                break;
            case "Zarit.pdf":
                setContenidolink("/Cuestionario/3/intentos/")
                break;
            default:
                setContenidolink("/pdf/")
        }
    }, [nombre]);

    function eliminarUltimosCaracteres(cadena) {
        const nuevaCadena = cadena.slice(0, -4);
        return nuevaCadena;
    }

    function ImagenConNombre(nombre) {
        // Supongamos que las imágenes están en una carpeta llamada "imagenes"
        // en la raíz de la carpeta "public" de tu proyecto React

        const rutaImagen =
            //process.env.PUBLIC_URL + "/Images/Miniaturas/" + eliminarUltimosCaracteres(nombre) + ".png"; // Cambia la extensión según el tipo de archivo de imagen que estés utilizando
            process.env.PUBLIC_URL +
            "/Images/Miniaturas/" +
            eliminarUltimosCaracteres(nombre) +
            ".png"; // Cambia la extensión según el tipo de archivo de imagen que estés utilizando

        return rutaImagen;
    }

    componenteRenderizado = (
        <Link to={contenidolink}>
            <Card
                content={
                    <div style={{ height: "100%" }}>
                        {/* <div style={{ minHeight: "30%", maxHeight: "30%", marginTop:"0%" }}>
              <br></br>
              <p className="parrafoCardRecomendados">
                <b>{nombreFront}</b>
              </p>
            </div>
            <br />
            <div style={{ minHeight: "70%", maxHeight: "70%" }}>
              <br></br> */}
                        <img
                            src={ImagenConNombre(nombre)}
                            alt={nombre}
                            className="imagenRellenoRecomendados"
                        />
                        {/* </div> */}
                    </div>
                }
                alto={altocard}
                ancho={anchocard}
                className={`cardRellenoRecomendados ${contenido}Border`}


            />
        </Link>
    );

    return <div>{componenteRenderizado}</div>;
}

export default RellenoContenidos;

// componenteRenderizado = (
//   <Link to={contenidolink}>
//     <Card
//       content={
//         <div style={{ height: "100%" }}>
//           <div style={{ minHeight: "30%", maxHeight: "30%", marginTop:"0%" }}>
//             <br></br>
//             <p className="parrafoCardRecomendados">
//               <b>{nombreFront}</b>
//             </p>
//           </div>
//           <br />
//           <div style={{ minHeight: "70%", maxHeight: "70%" }}>
//             <br></br>
//             <img
//                src={ImagenConNombre(contenido)}
//                alt={contenido}
//               className="imagenRellenoRecomendados"
//             />
//           </div>
//         </div>
//       }
//       alto={altocard}
//       ancho={anchocard}
//       className="cardRellenoRecomendados"
//     />
//   </Link>
// );

// return <div>{componenteRenderizado}</div>;
// }

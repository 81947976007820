import { useEffect, useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import "./MiPulsera.css";
import SuenoCarta from '../../components/RellenoCartas/SuenoCarta';
import ActividadCarta from '../../components/RellenoCartas/ActividadCarta';
import PulsacionesCarta from '../../components/RellenoCartas/PulsacionesCarta';

import { useTranslation } from "react-i18next";
import TituloyFlecha from "../../components/TituloyFlecha";

const MiPulsera = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [t] = useTranslation("global");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="mi-pulsera-wrapper">
      <TituloyFlecha
        title={t("Mi-Pulsera")}
        link={"/"}
      />

      <h5 style={{ marginTop: "0.5%" }}>{t("Hola")}, {t("Texto-ultimos-datos")}</h5>
      {windowWidth > 1200 ? (
        <div className="card-container">
          <Link to="/MiActividadFisica/dia">
            <Card content={<ActividadCarta progresoPasos={3254} progresoDistancia={5.5} maxValor={4000} />} alto="55vh" ancho="55vh" className="cardMiPulsera" />
          </Link>
          <Link to="/RutinaSuenoDiaria">
            <Card content={<SuenoCarta progreso={7.5} maxValor={8} />} alto="55vh" ancho="60vh" className="cardMiPulsera" />
          </Link>
          <Link to="/FrecuenciaCardiacaDiaria">
            <Card content={<PulsacionesCarta progreso={3254} maxValor={4000} />} alto="55vh" ancho="55vh" className="cardMiPulsera" />
          </Link>
        </div>
      ) : (
        <div className="card-container">
          <Link to="/MiActividadFisica/dia">
            <Card content={<ActividadCarta progresoPasos={3254} progresoDistancia={5.5} maxValor={4000} />} alto="52vh" ancho="100%" className="cardMiPulsera" />
          </Link>
          <Link to="/RutinaSuenoDiaria">
            <Card content={<SuenoCarta progreso={7.5} maxValor={8} />} alto="52vh" ancho="100%" className="cardMiPulsera" />
          </Link>
          <Link to="/FrecuenciaCardiacaDiaria">
            <Card content={<PulsacionesCarta progreso={3254} maxValor={4000} />} alto="52vh" ancho="100%" className="cardMiPulsera" />
          </Link>
          <br />
        </div>
      )}
      <Link to="/AyudaSincronizacion" className="linkayudasinc">{t("Novesdatos")}</Link>
    </div>
  );
};

export default MiPulsera;

import React from "react";
import "./Ayuda.css";
import { useTranslation } from "react-i18next";
import TituloyFlecha from "../../components/TituloyFlecha";

const QuienesSomos = () => {
    const [t] = useTranslation("global");
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div className="containerAyuda">
                <TituloyFlecha
                    title={t("QuienesSomos")}
                    link={"/"}
                />

                <div className="contenidoAyuda">
                    <h2 className="subtituloAyuda">
                        {t("PreguntasFrecuentes.preguntas.preg9")}
                    </h2>
                    <p>{t("PreguntasFrecuentes.respuestas.resp9")}</p>
                    <p>{t("PreguntasFrecuentes.respuestas.resp9,1")}</p>

                    <h2 className="subtituloAyuda">
                        {t("PreguntasFrecuentes.preguntas.preg8")}
                    </h2>
                    <p>{t("PreguntasFrecuentes.respuestas.resp8")}</p>
                    <p>{t("PreguntasFrecuentes.respuestas.resp8,1")}</p>
                    <p>
                        {t("PreguntasFrecuentes.respuestas.resp8,2")}{" "}
                        <span>
                            <a
                                href="https://talionis.citic.udc.es/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#518ea6", textDecoration: "none" }}
                            >
                                https://talionis.citic.udc.es/
                            </a>
                        </span>
                    </p>

                </div>
            </div>
        </div>
    );
};

export default QuienesSomos;

import React, { useCallback } from "react";
import CircularProgressBarPasos from "../Graficos/CircularProgressBarPasos";
import CircularProgressBarSueno from "../Graficos/CircularProgressBarSueno";
import Card from "../Card";
import Pasos_Icono from "../../Images/Pasos_Icono.png";
import Sueño_Icono from "../../Images/Sueño_Icono.png";
import mood1 from "../../Images/mood1.png";
import mood2 from "../../Images/mood2.png";
import mood3 from "../../Images/mood3.png";
import mood4 from "../../Images/mood4.png";
import mood5 from "../../Images/mood5.png";
import "./CartaGrandeHome.css";
import { useTranslation } from "react-i18next";
import { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import UserContext from "../../UserContext";
import { BsGraphUp } from "react-icons/bs";

export default function CartaGrandeHome({
  progresosueno,
  maxValorsueno,
  progresoactividad,
  maxValoractividad,
}) {
  const { t, i18n } = useTranslation("global");
  const locale = i18n.language;
  const intlDate = new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const email = useContext(UserContext);
  const [pasos_totales, setPasos_totales] = useState(0);
  const [sueno, setSueno] = useState(null);
  const [estadoAnimo, setEstadoAnimo] = useState(null);
  const [objetivopasos, setObjetivopasos] = useState(null);
  const [objetivosueno, setObjetivosueno] = useState(null);
  const [fechapasos, setFechapasos] = useState(null);
  const [fechasueno, setFechasueno] = useState(null);
  const navigate = useNavigate();

  const consulta_estado_animo = useCallback(() => {
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    axios
      .get(t("Recursos.ip") + "/estadoanimo", {
        params: {
          email: email.email,
          startTime: formattedCurrentDate,
          endTime: formattedCurrentDate,
        },
      })
      .catch((error) => console.error("Error:", error))
      .then((response) => {
        if (!response || response.data.length === 0) return;
        setEstadoAnimo(response.data[0].estadoAnimo);
      });
  }, [email, t]);

  const consulta_pasos = useCallback(() => {
    const currentDate = new Date();
    const OneMoreDay = new Date();
    currentDate.setDate(currentDate.getDate() - 720);
    OneMoreDay.setDate(OneMoreDay.getDate() + 1);
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedOneMoreDay = OneMoreDay.toISOString().split("T")[0];

    axios
      .get(t("Recursos.ip") + "/pasos", {
        params: {
          email: email.email,
          startTime: formattedCurrentDate,
          endTime: formattedOneMoreDay,
        },
      })
      .catch((error) => console.error("Error:", error))
      .then((response) => {
        setPasos_totales(calcularTotalNumeropasosUltimoDia(response.data));
      });
  }, [email, t]);

  function calcularTotalNumeropasosUltimoDia(pasos) {
    if (!pasos || pasos.length === 0) {
      return 0;
    }

    // Encontrar el último timestamp (fecha más reciente)
    const ultimoTimestamp = pasos.reduce((ultimo, actual) => {
      return new Date(actual.timestamp) > new Date(ultimo.timestamp)
        ? actual
        : ultimo;
    }).timestamp;

    // Obtener solo la fecha del último timestamp en formato yyyy-mm-dd
    const fechaUltimoDia = new Date(ultimoTimestamp)
      .toISOString()
      .split("T")[0];
    setFechapasos(fechaUltimoDia);
    // Filtrar los pasos que corresponden al último día y sumar numeropasos
    const pasosUltimoDia = pasos
      .filter((paso) => paso.timestamp.startsWith(fechaUltimoDia)) // Verifica que sea el mismo día
      .reduce((suma, paso) => suma + paso.numeropasos, 0); // Sumar numeropasos

    return pasosUltimoDia;
  }

  function formatfecha(dateString) {
    if (!dateString) return "--/--/----";
    const date = new Date(dateString);
    return intlDate.format(date);
  }

  const encontrarFechaFinMasReciente = useCallback((fasesSueno) => {
    if (fasesSueno.length === 0) {
      return { ultimasesion: null, minutosTotales: 0 };
    }

    const ultimasesion = [];
    let minutosTotales = 0;

    fasesSueno.sort((a, b) => new Date(b.t_fin) - new Date(a.t_fin));

    for (let i = 0; i < fasesSueno.length; i++) {
      if (i === 0) {
        ultimasesion.push(fasesSueno[i]);
        minutosTotales += calcularDuracionEnMinutos(
          fasesSueno[i].t_inicio,
          fasesSueno[i].t_fin
        );
      } else {
        const fechaFinActual = new Date(fasesSueno[i].t_fin);
        const fechaInicioAnterior = new Date(fasesSueno[i - 1].t_inicio);

        if (fechaInicioAnterior.getTime() === fechaFinActual.getTime()) {
          ultimasesion.push(fasesSueno[i]);
          minutosTotales += calcularDuracionEnMinutos(
            fasesSueno[i].t_inicio,
            fasesSueno[i].t_fin
          );
        } else {
          break;
        }
      }
    }

    // Restar los minutos de las fases "DESPIERTO"
    const minutosDespierto = ultimasesion
      .filter((fase) => fase.fasesSuenoEnum === "DESPIERTO")
      .reduce(
        (total, fase) =>
          total + calcularDuracionEnMinutos(fase.t_inicio, fase.t_fin),
        0
      );
    minutosTotales -= minutosDespierto;

    return { ultimasesion, minutosTotales };
  }, []);

  const consulta_sueño = useCallback(async () => {
    const currentDate = new Date();
    const oneDayAgo = new Date();
    oneDayAgo.setDate(currentDate.getDate() - 360);
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedoneDayAgo = oneDayAgo.toISOString().split("T")[0];

    try {
      const response_durancion = await axios.get(t("Recursos.ip") + "/sueno", {
        params: {
          email: email.email,
          startTime: formattedoneDayAgo,
          endTime: formattedCurrentDate,
        },
      });
      // setSueno(response.data[0].duracion);
      try {
        const ultima = encontrarFechaFinMasReciente(
          response_durancion.data
        ).ultimasesion;
        // console.log(response_durancion);
        setSueno(
          encontrarFechaFinMasReciente(response_durancion.data).minutosTotales
          // calcularDiferenciaMinutos(
          //   ultima[ultima.length - 1].t_inicio,
          //   ultima[0].t_fin
          // )
        );
        setFechasueno(ultima[0].t_fin);
      } catch (error) {
        setSueno(0);
      }
    } catch (error) {
      error("Error:", error);
    }
  }, [email.email, encontrarFechaFinMasReciente, t]);

  const consulta_objetivo_pasos = useCallback(() => {
    axios
      .get(t("Recursos.ip") + "/objetivo", {
        params: {
          email: email.email,
          parametro: "pasos",
        },
      })
      .catch((error) => console.error("Error:", error))
      .then((response) => {
        setObjetivopasos(response.data.valor);
      });
  }, [email, t]);

  const consulta_objetivo_sueno = useCallback(async () => {
    try {
      const response = await axios.get(t("Recursos.ip") + "/objetivo", {
        params: {
          email: email.email,
          parametro: "sueno",
        },
      });

      const objetivoaux = response.data;
      setObjetivosueno(objetivoaux.valor);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [email, t]);

  // Función auxiliar para calcular la duración en minutos entre dos fechas.
  function calcularDuracionEnMinutos(fechaInicio, fechaFin) {
    const inicio = new Date(fechaInicio);
    const fin = new Date(fechaFin);
    return Math.round((fin - inicio) / (1000 * 60)); // Convertir de milisegundos a minutos
  }

  useEffect(() => {
    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if (email.email) {
      consulta_estado_animo();
      consulta_pasos();
      consulta_sueño();
      consulta_objetivo_pasos();
      consulta_objetivo_sueno();
    }
  }, [
    consulta_estado_animo,
    consulta_objetivo_pasos,
    consulta_objetivo_sueno,
    consulta_pasos,
    consulta_sueño,
    email,
  ]);

  const estadoAnimoString = () => {
    switch (estadoAnimo) {
      case 1:
        return t("Estado-Animo.Muy-mal");
      case 2:
        return t("Estado-Animo.Mal");
      case 3:
        return t("Estado-Animo.Normal");
      case 4:
        return t("Estado-Animo.Bien");
      case 5:
        return t("Estado-Animo.Muy-bien");
      default:
        // Devuelve una imagen predeterminada o maneja el caso no especificado según tus necesidades
        return t("Estado-Animo.Normal");
    }
  };
  const navergarcuestionario = () => {
    navigate("/CuestionarioEstadoAnimo");
  };
  const navergarestadoanimo = () => {
    navigate("/EstadodeAnimo/semana");
  };
  const estadoAnimoImagen = () => {
    switch (estadoAnimo) {
      case 1:
        return mood1;
      case 2:
        return mood2;
      case 3:
        return mood3;
      case 4:
        return mood4;
      case 5:
        return mood5;
      default:
        // Devuelve una imagen predeterminada o maneja el caso no especificado según tus necesidades
        return mood1;
    }
  };
  return (
    <div className="CartaGrandeHome">
      <div style={{ width: "75%", margin: "0 auto", marginTop: "-2rem" }}>
        <Card
          content={t("Mi-Resumen-Diario")}
          alto="50%"
          ancho="100%"
          className={"home-card-header"}
        />
      </div>
      {estadoAnimo ? (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: ".25em",
            }}
          >
            <span>{t("Carta-Grande.Hoy-te-encuentras")}</span>
            <b>{estadoAnimoString()}</b>
            <button
              className="botongraficoanimoCartaGrandeHome"
              onClick={() => navergarestadoanimo()}
            >
              <BsGraphUp
                style={{
                  color: "#518ea6",
                  strokeWidth: "1.75px",
                }}
              />
            </button>
          </div>
          <img
            src={estadoAnimoImagen()}
            alt={estadoAnimoString()}
            className="imagenCartaGrandeHome"
          />
        </div>
      ) : (
        <div className="home-card-animo">
          <p>{t("Carta-Grande.No-estado-animo")} </p>
          <button
            className="botoncircCartaGrandeHome"
            onClick={() => navergarcuestionario()}
          >
            {t("Cubrir")}
          </button>
        </div>
      )}

      {/* <p>{t("Carta-Grande.Hoy-dormido-caminado")}</p> */}
      <div className="home-card-grande-datos">
        <div>
          <b>{t("Pasos")}</b>
          <Link to="/MiActividadFisica/dia">
            <div className="home-progressBar-container">
              <div className="home-progessBar-wrapper">
                <CircularProgressBarPasos
                  progress={pasos_totales}
                  maxValue={objetivopasos}
                  texto=" pasos"
                  colortexto="black"
                  colorfondo="#F0F8FF"
                  colorpath="#F8DE22"
                  width="100%"
                />
              </div>
              <img
                src={Pasos_Icono}
                alt={t("Pasos-Icono")}
                className="home-progressBar-icon"
              />
            </div>
          </Link>
          <div className="home-card-grande-datos-date">{formatfecha(fechapasos)}</div>
        </div>
        <div>
          <b>{t("Sueno")}</b>
          <Link to="/RutinaSuenoDiaria">
            <div className="home-progressBar-container">
              <div className="home-progessBar-wrapper">
                <CircularProgressBarSueno
                  progress={sueno}
                  maxValue={objetivosueno}
                  texto=" horas"
                  colortexto="black"
                  colorfondo="#F0F8FF"
                  colorpath="#810CA8"
                  width="100%"
                />
              </div>
              <img
                src={Sueño_Icono}
                alt={t("Sueno-Icono")}
                className="home-progressBar-icon"
              />
            </div>
          </Link>
          <div className="home-card-grande-datos-date">{formatfecha(fechasueno)}</div>
        </div>
      </div>
    </div>
  );
}

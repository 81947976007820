import { useEffect, useContext, useState, useCallback } from "react";
import CircularProgressBarPasos from "../Graficos/CircularProgressBarPasos";
import { useTranslation } from "react-i18next";
import Pasos_Icono from "../../Images/Pasos_Icono.png";
import Pasos_Icono_SinRelleno from "../../Images/Pasos_Icono_SinRelleno.png";
import Distancia_Gris from "../../Images/Distancia_Gris.png";
import axios from "axios";
import UserContext from "../../UserContext";
import "./ActividadCarta.css";

const ActividadCarta = () => {
  const email = useContext(UserContext);
  const [pasos_totales, setPasos_totales] = useState(null);
  const [distancia, setDistancia] = useState(null);
  const [objetivo, setObjetivo] = useState(null);
  const [t] = useTranslation("global");
  const [fecha, setFecha] = useState(null);

  const consulta_pasos = useCallback(() => {
    const currentDate = new Date();
    const OneMoreDay = new Date();
    currentDate.setDate(currentDate.getDate() - 720);
    OneMoreDay.setDate(OneMoreDay.getDate() + 1);
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedOneMoreDay = OneMoreDay.toISOString().split("T")[0];
    console.log(formattedCurrentDate);

    axios
      .get(t("Recursos.ip") + "/pasos", {
        params: {
          email: email.email,
          startTime: formattedCurrentDate,
          endTime: formattedOneMoreDay,
        },
      })
      .catch((error) => console.error("Error:", error))
      .then((response) => {
        setPasos_totales(calcularTotalNumeropasosUltimoDia(response.data));
      });
  }, [email, t]);
  const consulta_objetivo = useCallback(() => {
    axios
      .get(t("Recursos.ip") + "/objetivo", {
        params: {
          email: email.email,
          parametro: "pasos",
        },
      })
      .catch((error) => console.error("Error:", error))
      .then((response) => {
        //Console.log("Objetivo: ", response.data);
        const objetivoaux = response.data;
        setObjetivo(objetivoaux.valor);
      });
  }, [email, t]);

  const consulta_distancia = useCallback(() => {
    const currentDate = new Date();
    const OneMoreDay = new Date();
    currentDate.setDate(currentDate.getDate() - 720);
    OneMoreDay.setDate(OneMoreDay.getDate() + 1);
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedOneMoreDay = OneMoreDay.toISOString().split("T")[0];
    axios
      .get(t("Recursos.ip") + "/distancia", {
        params: {
          email: email.email,
          startTime: formattedCurrentDate,
          endTime: formattedOneMoreDay,
        },
      })
      .catch((error) => console.error("Error:", error))
      .then((response) => {
        console.log("Distancia: ", response?.data[0]?.fecha);
        if (!response?.data || response?.data[0]?.fecha !== fecha) {
          setDistancia(0);
        } else {
          setDistancia(response?.data[0]?.metros);
        }
      });
  }, [email, t]);

  useEffect(() => {
    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if (email.email) {
      consulta_objetivo();
      consulta_pasos();
    }
  }, [consulta_pasos, consulta_objetivo, email]);

  useEffect(() => {
    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if (email.email && fecha) {
      consulta_distancia();
    }
  }, [consulta_distancia, email, fecha]);


  const formatearNumeroConPuntoDeMiles = (numero) => {
    if (numero === null) {
      return 0;
    } else {
      return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  };

  function calcularTotalNumeropasosUltimoDia(pasos) {
    if (!pasos || pasos.length === 0) {
      return 0;
    }

    // Encontrar el último timestamp (fecha más reciente)
    const ultimoTimestamp = pasos.reduce((ultimo, actual) => {
      return new Date(actual.timestamp) > new Date(ultimo.timestamp)
        ? actual
        : ultimo;
    }).timestamp;

    // Obtener solo la fecha del último timestamp en formato yyyy-mm-dd
    const fechaUltimoDia = new Date(ultimoTimestamp)
      .toISOString()
      .split("T")[0];
    setFecha(fechaUltimoDia);
    // Filtrar los pasos que corresponden al último día y sumar numeropasos
    const pasosUltimoDia = pasos
      .filter((paso) => paso.timestamp.startsWith(fechaUltimoDia)) // Verifica que sea el mismo día
      .reduce((suma, paso) => suma + paso.numeropasos, 0); // Sumar numeropasos

    return pasosUltimoDia;
  }

  function formatearFecha(dateString) {
    const date = new Date(dateString); // Convertir la cadena a un objeto Date
    if (isNaN(date.getTime())) {
      return "Fecha no válida"; // Manejar el caso en que la cadena no sea una fecha válida
    }

    const dia = date.getDate().toString().padStart(2, "0");
    const mes = (date.getMonth() + 1).toString().padStart(2, "0");
    const anio = date.getFullYear();

    return `${dia}-${mes}-${anio}`;
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: "3%",
          color: "#340068",
          marginTop: "5%",
        }}
      >
        <img
          src={Pasos_Icono}
          alt={t("Pasos-Icono")}
          className="imagenSuenoCarta"
        />
        <h3 style={{ color: "black" }}>
          &nbsp;
          {t("Actividad")}
        </h3>
      </div>
      {pasos_totales >= 0 && objetivo  ? (
        <div
          style={{
            height: "80%",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <p style={{ color: "black" }}>
              <b>{formatearFecha(fecha)}</b>
            </p>
          </div>
          <br />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "5%",
              justifyContent: "center",
            }}
          >
            <div
              style={{ marginTop: "-10%", marginBottom: "5%", width: "50%" }}
            >
              <CircularProgressBarPasos
                progress={pasos_totales}
                maxValue={objetivo}
                texto=" pasos"
                colortexto="black"
                colorfondo="#F0F8FF"
                colorpath="#F8DE22"
                ancho="80%"
                alto="80%"
              />
            </div>
            <div
              style={{
                marginBottom: "17.5%",
                fontSize: "15 0%",
                color: "black",
              }}
            >
              <span>
                {" "}
                <img
                  src={Pasos_Icono_SinRelleno}
                  alt={t("Pasos-Icono")}
                  className="imagenActividadCarta"
                />{" "}
                {formatearNumeroConPuntoDeMiles(pasos_totales)} {t("Pasos")}
              </span>
              <div style={{ marginTop: "10%" }}>
                <span>
                  {" "}
                  <img
                    src={Distancia_Gris}
                    alt={t("Distancia-Icono")}
                    className="imagenActividadCarta"
                  />{" "}
                  {distancia !== null
                    ? (distancia / 1000).toLocaleString("es-ES", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""}{" "}
                  km
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ color: "black", marginTop: "20%" }}>
          <b>{t("Conectando-BD-pasos")}</b>
        </div>
      )}
    </div>
  );
};
export default ActividadCarta;

import { useState, useContext, useEffect } from "react";

import LinesChart from "../../components/Graficos/LinesChart";
import UserContext from "../../UserContext";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Card from "../../components/Card";
import { FaCircle } from "react-icons/fa";
import "./PulsacionesPageMaxMin.css";
import TituloyFlecha from "../../components/TituloyFlecha";

const PulsacionesPageMaxMin = () => {
  const email = useContext(UserContext);
  const [t] = useTranslation("global");
  const [chartData, setChartData] = useState(null);
  const [listaAgrupada, setListaAgrupada] = useState(null);
  const [nfecha, setNfecha] = useState(0);
  const { contenido } = useParams();
  const [contenidoAMostrar, setContenidoAMostrar] = useState(null);
  const [colorFondoboton1, setColorFondoboton1] = useState("#b9ddd7");
  const [colorFondoboton2, setColorFondoboton2] = useState("#b9ddd7");
  const [colorFondoboton3, setColorFondoboton3] = useState("#b9ddd7");
  const [colorFondoboton4, setColorFondoboton4] = useState("#b9ddd7");
  const [colorTexto1, setColorTexto1] = useState("black");
  const [colorTexto2, setColorTexto2] = useState("black");
  const [colorTexto3, setColorTexto3] = useState("black");
  const [colorTexto4, setColorTexto4] = useState("black");
  const [textocuadro, setTextocuadro] = useState(t("Frecuencia-cardiaca"));
  // AQUI IRIA PERIODICIDAD PERO REALMENTE NO SE USA ASI QUE NADA
  const locale = {
    dateTime: "%A, %e de %B de %Y, %X",
    date: "%d/%m/%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: [
      t("DiasSemana.Domingo"),
      t("DiasSemana.Lunes"),
      t("DiasSemana.Martes"),
      t("DiasSemana.Miercoles"),
      t("DiasSemana.Jueves"),
      t("DiasSemana.Viernes"),
      t("DiasSemana.Sabado"),
    ],
    shortDays: [
      t("shortDiasSemana.Domingo"),
      t("shortDiasSemana.Lunes"),
      t("shortDiasSemana.Martes"),
      t("shortDiasSemana.Miercoles"),
      t("shortDiasSemana.Jueves"),
      t("shortDiasSemana.Viernes"),
      t("shortDiasSemana.Sabado"),
    ],
    months: [
      t("Meses.Enero"),
      t("Meses.Febrero"),
      t("Meses.Marzo"),
      t("Meses.Abril"),
      t("Meses.Mayo"),
      t("Meses.Junio"),
      t("Meses.Julio"),
      t("Meses.Agosto"),
      t("Meses.Septiembre"),
      t("Meses.Octubre"),
      t("Meses.Noviembre"),
      t("Meses.Diciembre"),
    ],
    shortMonths: [
      t("shortMeses.Enero"),
      t("shortMeses.Febrero"),
      t("shortMeses.Marzo"),
      t("shortMeses.Abril"),
      t("shortMeses.Mayo"),
      t("shortMeses.Junio"),
      t("shortMeses.Julio"),
      t("shortMeses.Agosto"),
      t("shortMeses.Septiembre"),
      t("shortMeses.Octubre"),
      t("shortMeses.Noviembre"),
      t("shortMeses.Diciembre"),
    ],
  };
  useEffect(() => {
    if (contenido) {
      ////Console.log("Contenido:", contenido);
      switch (contenido) {
        case "semana":
          setColorFondoboton1("#b9ddd7");
          setColorFondoboton2("#518ea6");
          setColorFondoboton3("#b9ddd7");
          setColorFondoboton4("#b9ddd7");
          setColorTexto1("black");
          setColorTexto2("white");
          setColorTexto3("black");
          setColorTexto4("black");
          setContenidoAMostrar("1");
          setTextocuadro(t("Frecuencia-cardiaca-semana"));
          break;
        case "mes":
          setColorFondoboton1("#b9ddd7");
          setColorFondoboton2("#b9ddd7");
          setColorFondoboton3("#518ea6");
          setColorFondoboton4("#b9ddd7");
          setColorTexto1("black");
          setColorTexto2("black");
          setColorTexto3("white");
          setColorTexto4("black");
          setContenidoAMostrar("1");
          setTextocuadro(t("Frecuencia-cardiaca-mes"));
          break;
        case "ano":
          setColorFondoboton1("#b9ddd7");
          setColorFondoboton2("#b9ddd7");
          setColorFondoboton3("#b9ddd7");
          setColorFondoboton4("#518ea6");
          setColorTexto1("black");
          setColorTexto2("black");
          setColorTexto3("black");
          setColorTexto4("white");
          setContenidoAMostrar("3");
          setTextocuadro(t("Frecuencia-cardiaca-ano"));
          break;
        default:
          setColorFondoboton1("#b9ddd7");
          setColorFondoboton2("#518ea6");
          setColorFondoboton3("#b9ddd7");
          setColorFondoboton4("#b9ddd7");
          setColorTexto1("black");
          setColorTexto2("white");
          setColorTexto3("black");
          setColorTexto4("black");
          setContenidoAMostrar("1");
          setTextocuadro(t("Frecuencia-cardiaca"));
      }
    }
  }, [contenido, t]);



  useEffect(() => {
    const cosnulta_pulsaciones = async () => {
      const currentDate = new Date();
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(currentDate.getDate() - 730);
      const formattedCurrentDate = currentDate.toISOString().split("T")[0];
      const formattedOneWeekAgo = oneWeekAgo.toISOString().split("T")[0];
      //Console.log("PAYDA ",contenidoAMostrar)
      try {
        const response = await axios.get(
          t("Recursos.ip") + "/pulsaciones/grouped",
          {
            params: {
              email: email.email,
              startTime: formattedOneWeekAgo,
              endTime: formattedCurrentDate,
              granularidad: contenidoAMostrar,
            },
          }
        );
        ////Console.log("Pulsaciones: ", contenidoAMostrar, response.data);
        setNfecha(0);
        if (response.data.length > 0) { setListaAgrupada(procesadodatos(response.data)); }

      } catch (error) {
        console.error("Error:", error);
      }
    };

    function procesadodatos(datos) {
      // //Console.log("Datos", datos);
      const currentDate = new Date();
      switch (contenido) {
        case "semana":
          return updateWeeklyData(generateDaylyData(), datos).reverse();
        case "mes":
          return agruparPorMeses(
            combinarArraysDias(
              generarArrayDias(UltimoDiaDelMes(currentDate)),
              datos
            )
          ).reverse();
        case "ano":
          return groupDataByYearsAndMonths(datos);
        default:
          updateWeeklyData(generateDaylyData(), datos).reverse();
          return;
      }
    }

    const UltimoDiaDelMes = (fecha) => {
      // Obtener el último día del mes
      const ultimoDia = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);

      return ultimoDia;
    };

    function generarArrayDias(fechaActual) {
      const añoActual = fechaActual.getFullYear();
      const primerDiaAño = new Date(2023, 0, 1);
      const diasTranscurridos = Math.ceil(
        (fechaActual - primerDiaAño) / (24 * 60 * 60 * 1000)
      );
      const arrayDias = [];

      for (let i = 0; i <= diasTranscurridos; i++) {
        const fecha = new Date(primerDiaAño);
        fecha.setDate(fecha.getDate() + i);

        if (fecha > fechaActual) {
          break;
        }

        const year = fecha.getFullYear();
        const month = (fecha.getMonth() + 1).toString().padStart(2, "0");
        const day = fecha.getDate().toString().padStart(2, "0");
        const hours = "00";
        const minutes = "00";

        const timestamp = `${year}-${month}-${day}T${hours}:${minutes}:00`;

        arrayDias.push({
          maxfpVal: 0,
          minfpVal: 0,
          avgVal: 0,
          timestamp,
        });
      }

   
      return arrayDias;
      
    }

    function combinarArraysDias(arrayAno, arrayDias) {
      // Iterar a través de cada semana en el array de semanas

      for (const dia of arrayAno) {
        // Encontrar el índice del día en el array de días
        const indiceDia = arrayDias.findIndex(
          (d) => d.timestamp === dia.timestamp
        );

        // Si se encuentra el día, sustituir el elemento
        if (indiceDia !== -1) {
          arrayAno[arrayAno.indexOf(dia)] = arrayDias[indiceDia];
        }
      }
     
      return arrayAno;
    }

    function agruparPorMeses(array) {
      const resultado = [];
      const mesesVistos = {}; // Aquí almacenamos as claves únicas de ano-mes
      let contador = 0; // Contador que se usa para asignar números de clave
    
      array.forEach((item) => {
        const fecha = new Date(item.timestamp);
        const mes = fecha.getMonth(); // Mes de 0 a 11
        const ano = fecha.getFullYear(); // Obtemos o ano
    
        const claveUnica = `${ano}-${mes}`; // Creamos unha clave única co ano e mes
    
        // Se aínda non se viu este mes e ano, asignámoslle un novo índice
        if (mesesVistos[claveUnica] === undefined) {
          console.log("ClaveUnica",claveUnica);
          mesesVistos[claveUnica] = contador;
          contador++;
        }
    
        const indice = mesesVistos[claveUnica]; // Obtemos o índice asociado ao mes e ano
    
        if (!resultado[indice]) {
          resultado[indice] = [];
        }
    
        resultado[indice].push({
          maxfpVal: item.maxfpVal,
          minfpVal: item.minfpVal,
          avgVal: item.avgVal,
          timestamp: item.timestamp,
        });
      });
    console.log("Resultado",resultado);
      return resultado;
    }
    
    function generateDaylyData() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear() - 1; //Cambiar esto
      // Encuentra el primer lunes del año
      const firstDayOfYear = new Date(currentYear, 0, 1);
      let firstMondayOfYear = new Date(firstDayOfYear);
      firstMondayOfYear.setDate(firstDayOfYear.getDate() + (1 - firstDayOfYear.getDay() + (firstDayOfYear.getDay() === 0 ? -6 : 0)));


      // Inicializa un array para almacenar los datos semanales
      const weeklyData = [];

      // Itera a través de las semanas hasta la fecha actual
      let currentDatePointer = new Date(firstMondayOfYear);
      while (currentDatePointer <= currentDate) {
        const weekStartDate = new Date(currentDatePointer);
        const weekEndDate = new Date(currentDatePointer);
        weekEndDate.setDate(currentDatePointer.getDate() + 6);

        // Establece valores fijos en 0 para max, min, y avg
        const maxfpVal = 0;
        const minfpVal = 0;
        const avgVal = 0;

        // Inicializa un array para almacenar los datos diarios de la semana
        const dailyData = [];

        // Agrega los datos de cada día de la semana al array
        for (let i = 0; i < 7; i++) {
          const currentDate = new Date(weekStartDate);
          currentDate.setDate(weekStartDate.getDate() + i);

          dailyData.push({
            maxfpVal,
            minfpVal,
            avgVal,
            timestamp: currentDate.toISOString().slice(0, 19), // Elimina el "Z" al final
          });
        }

        // Agrega los datos diarios al array semanal
        weeklyData.push(dailyData);

        // Avanza al próximo lunes
        currentDatePointer.setDate(currentDatePointer.getDate() + 7);
      }

      return weeklyData;
    }




    function updateWeeklyData(globalWeeklyData, dailyData) {

      // Recorre el array de días
      dailyData.forEach((day) => {
        const dayTimestamp = day.timestamp.slice(0, 10); // Obtiene la parte de la fecha sin la hora

        // Busca la semana correspondiente en el array de datos semanales
        const weekToUpdate = globalWeeklyData.find((week) => {
          const firstDayOfWeek = week[0].timestamp.slice(0, 10);
          const lastDayOfWeek = week[6].timestamp.slice(0, 10);

          return (
            dayTimestamp >= firstDayOfWeek && dayTimestamp <= lastDayOfWeek
          );
        });

        // Si encuentra la semana correspondiente, actualiza los valores
        if (weekToUpdate) {
          const dayIndex = (new Date(day.timestamp).getDay() + 6) % 7; // Esto cambia el domingo a índice 6 y lunes a índice 0

          weekToUpdate[dayIndex] = {
            maxfpVal: day.maxfpVal,
            minfpVal: day.minfpVal,
            avgVal: day.avgVal,
            timestamp: day.timestamp,
          };
        }
      });

      return globalWeeklyData;
    }


    function groupDataByYearsAndMonths(data) {

      const groupedData = data.reduce((acc, current) => {
        const timestamp = new Date(current.timestamp);
        const yearMonth = `${timestamp.getFullYear()}-${(
          timestamp.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}`;
        const year = timestamp.getFullYear();

        if (!acc[year]) {
          acc[year] = {};
        }

        acc[year][yearMonth] = current;

        return acc;
      }, {});

      // Rellenar meses sin datos con valores a 0 y ordenar descendente
      const completeData = Object.keys(groupedData)
        .sort((a, b) => b - a) // Ordenar años descendente
        .map((year) => {
          const monthsInYear = 12;

          // Crear un array con los meses del año
          const yearMonths = Array.from(
            { length: monthsInYear },
            (_, index) => {
              const month = (index + 1).toString().padStart(2, "0");
              const yearMonthKey = `${year}-${month}`;

              if (!groupedData[year][yearMonthKey]) {
                // Si el mes no está en los datos originales, poner valores a 0
                return {
                  maxfpVal: 0,
                  minfpVal: 0,
                  avgVal: 0,
                  timestamp: `${year}-${month}-01T00:00:00`,
                };
              }

              return groupedData[year][yearMonthKey];
            }
          );

          return yearMonths;
        });

      return completeData;
    }

    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if (email.email && contenidoAMostrar) {
      cosnulta_pulsaciones();
    }
  }, [email, contenido, t, contenidoAMostrar]);

  useEffect(() => {
    // console.log("PATATA", listaAgrupada)
    if (listaAgrupada && listaAgrupada.length > 0) {
      setChartData(getChartData(listaAgrupada[nfecha]));
    } else {
      // Manejar la situación de datos vacíos o no disponibles
      setChartData(null);
    }
  }, [nfecha, listaAgrupada]);

  function formatFecha(fecha) {
    const date = new Date(fecha);

    switch (contenido) {
      case "semana":
        // Encuentra el lunes anterior al primer día del rango de fechas
        const startDate = new Date(date);
        const endDate = new Date(date);
        startDate.setDate(startDate.getDate() ); // Resta los días necesarios para llegar al lunes
        endDate.setDate(endDate.getDate() + (7 - endDate.getDay())); // Suma los días necesarios para llegar al domingo

        const startDay = startDate.getDate();
        const startMonth = locale.shortMonths[startDate.getMonth()];
        const endDay = endDate.getDate();
        const endMonth = locale.shortMonths[endDate.getMonth()];

        return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;

      case "mes":
        return locale.months[date.getMonth()];

      case "ano":
        return date.getFullYear();

      default:
        return "w";
    }
  }


  function cambiarNfecha(n) {
    ////Console.log("lista", listaAgrupada);
    if ((n < 0 && nfecha > 0) || (n > 0 && nfecha < listaAgrupada.length - 1)) {
      setNfecha(nfecha + n);
    }
  }
  const obtenerInformacionFecha = (fechas) => {
    return fechas.map((fecha) => {
      const fechaObj = new Date(fecha);

      switch (contenido) {
        case "semana":
          const nombreDia = locale.days[fechaObj.getDay()];
          return `${nombreDia} ${fechaObj.getDate()}`;
        case "mes":
          return fechaObj.getDate();
        case "ano":
          return locale.months[fechaObj.getMonth()];
        default:
          // Manejar caso no reconocido
          return fechas;
      }
    });
  };

  const getChartData = (listaAgrupada) => {
    if (!listaAgrupada) {
      return null; // Devuelve null para no mostrar el gráfico
    } else {
      const dias = obtenerInformacionFecha(listaAgrupada.map((item) => item.timestamp));
      //const timestamp = listaAgrupada.map((item) => item.timestamp);
      const maxdia = listaAgrupada.map((item) => item.maxfpVal);
      const mindia = listaAgrupada.map((item) => item.minfpVal);
      const avg = listaAgrupada.map((item) => item.avgVal);
      return {
        labels: dias,
        datasets: [
          {
            label: t("Pulsaciones-maximas"),
            data: maxdia,
            tension: 0.5,
            fill: false,
            borderColor: "rgb(255, 99, 132)",
            pointRadius: 2,
            pointBorderColor: "rgba(255, 99, 132)",
            pointBackgroundColor: "rgba(255, 99, 132)",
          },
          {
            label: t("Pulsaciones-medias"),
            data: avg,
            tension: 0.5,
            fill: false,
            borderColor: "rgb(4, 176, 17)",
            pointRadius: 2,
            pointBorderColor: "rgb(4, 176, 17)",
            pointBackgroundColor: "rgb(4, 176, 17)",
          },
          {
            label: t("Pulsaciones-minimas"),
            data: mindia,
            tension: 0.5,
            fill: false,
            borderColor: "rgb(0, 23, 255)",
            pointRadius: 2,
            pointBorderColor: "rgb(0, 23, 255)",
            pointBackgroundColor: "rgb(0, 23, 255)",
          },
        ],
      };
    };
  };

  const misoptions = {
    plugins: {
      legend: {
        display: false, // Deshabilita la leyenda (incluido el botón para ocultar líneas)
      },
    },
    scales: {
      y: {
        min: 0,
        title: {
          display: true,
          text: t("pulsaciones-bpm"),
          color: "black",
        },
      },
      x: {
        title: {
          display: true,
          text: contenido === "semana" || contenido === "mes" ? t("Dia") : contenido === "ano" ? t("Mes") : "",
          color: "black",
        },
        ticks: { color: "black" },
      },
    },
  };
  return (
    <div>
          <TituloyFlecha
        title={t("Mi_Frecuencia_Cardiaca")}
        link={"/MiSalud"}
      />
      
      <div
        style={{
          marginTop: "1%",
          marginBottom: "1%",
          margin: window.innerWidth <= 768 ? "3%" : "1%",
        }}
      >
        <Link to="/FrecuenciaCardiacaDiaria">
          <button
            className="botongranuralidadPulsacionesPageMaxMin"
            style={{ backgroundColor: colorFondoboton1, color: colorTexto1 }}
          >
            {t("Dia")}
          </button>
        </Link>
        <Link to="/FrecuenciaCardiaca/semana">
          <button
            className="botongranuralidadPulsacionesPageMaxMin"
            style={{ backgroundColor: colorFondoboton2, color: colorTexto2 }}
          >
            {t("Semana")}
          </button>
        </Link>
        <Link to="/FrecuenciaCardiaca/mes">
          <button
            className="botongranuralidadPulsacionesPageMaxMin"
            style={{ backgroundColor: colorFondoboton3, color: colorTexto3 }}
          >
            {t("Mes")}
          </button>
        </Link>
        <Link to="/FrecuenciaCardiaca/ano">
          <button
            className="botongranuralidadPulsacionesPageMaxMin"
            style={{ backgroundColor: colorFondoboton4, color: colorTexto4 }}
          >
            {t("Ano")}
          </button>
        </Link>
      </div>

      <div className="containerBodyPulsaciones">
        {chartData ? (
          <>
            <div className="containerGrafFechaPulsaciones">
              <div className="centerPulsacionesPage1MaxMin">
                {nfecha < listaAgrupada.length - 1 ? (
                  <div style={{ visibility: "visible", marginRight: "10%" }}>
                    <button
                      className="botoncircPulsacionesPageMaxMin"
                      onClick={() => cambiarNfecha(1)}
                    >
                      {t("Anterior")}
                    </button>
                  </div>
                ) : (
                  <div style={{ visibility: "hidden" }}>
                    <button onClick={() => cambiarNfecha(1)}>
                      {t("Anterior")}
                    </button>
                  </div>
                )}
                <p
                  style={{
                    fontSize: "1.1rem",
                    color: "black",
                    width: "150%",
                    marginLeft: "-20%",
                  }}
                >
                  {"  "}
                  {
                    <b>
                      {formatFecha(
                        listaAgrupada[nfecha][0].timestamp,
                        contenidoAMostrar,
                        locale
                      )}
                    </b>
                  }
                  {"  "}
                </p>
                {nfecha > 0 ? (
                  <div style={{ visibility: "visible", marginLeft: "20%" }}>
                    <button
                      className="botoncircPulsacionesPageMaxMin"
                      onClick={() => cambiarNfecha(-1)}
                    >
                      {t("Siguiente")}
                    </button>
                  </div>
                ) : (
                  <div style={{ visibility: "hidden" }}>
                    <button onClick={() => cambiarNfecha(-1)}>
                      {t("Siguiente")}
                    </button>
                  </div>
                )}
              </div>
              <div className="graficoPulsacionesPageMaxMin">
                <LinesChart data={chartData} options={misoptions} />
              </div>
            </div>
            <div
              className="centerPulsacionePageDuracion3"
              style={{ marginBottom: "1%" }}
            >
              <Card
                content={
                  <div style={{ color: "black" }}>
                    <h3 style={{ textAlign: "center", marginTop: "0.5%" }}>
                      {textocuadro}{" "}
                    </h3>

                    {/* Elemento arriba */}
                    <div style={{ textAlign: "center", marginTop: "0%" }}>
                      <FaCircle style={{ color: "rgb(4, 176, 17)" }} />
                      <b> {t("Frecuencia_cardiaca_media")}</b>
                      <br />
                      <p>
                        {((data) =>
                          (data = data.filter((item) => item.avgVal !== 0))
                            .length > 0
                            ? (
                              data.reduce((acc, val) => acc + val.avgVal, 0) /
                              data.length
                            ).toLocaleString("es-ES", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                            })
                            : 0)(listaAgrupada[nfecha])}{" "}
                        {t("bpm")}
                      </p>
                    </div>

                    {/* Dos elementos debajo */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "1%",
                      }}
                    >

                      <div
                        style={{
                          flex: 1,
                          textAlign: "right",
                          marginRight: "3%",
                        }}
                      >
                        <FaCircle style={{ color: "rgb(0, 23, 255)" }} />
                        <b> {t("Pulsaciones-minimas")}</b>
                        <br />
                        {listaAgrupada[nfecha].filter((item) => item.minfpVal !== 0)
                          .length > 0
                          ? Math.min(
                            ...listaAgrupada[nfecha]
                              .filter((item) => item.minfpVal !== 0)
                              .map((item) => item.minfpVal)
                          )
                          : 0}{" "}
                        {t("bpm")}
                      </div>

                      <div
                        style={{
                          flex: 1,
                          textAlign: "left",
                          marginLeft: "3%",
                        }}
                      >
                        <FaCircle style={{ color: "rgb(255, 99, 132)" }} />
                        <b> {t("Pulsaciones-maximas")}</b>
                        <br />
                        {listaAgrupada[nfecha].filter((item) => item.maxfpVal !== 0)
                          .length > 0
                          ? Math.max(
                            ...listaAgrupada[nfecha]
                              .filter((item) => item.maxfpVal !== 0)
                              .map((item) => item.maxfpVal)
                          )
                          : 0}{" "}
                        {t("bpm")}
                      </div>


                    </div>
                  </div>
                }
                className={"cardPulsacionesPageMaxMin"}
              ></Card>
            </div>
          </>
        ) : (
          <div style={{ color: "black", marginTop: "20%" }}>
            <b>{t("Conectando-BD")}</b>
          </div>
        )}
      </div>

    </div>
  );
};
export default PulsacionesPageMaxMin;

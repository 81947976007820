import React, { useState, useEffect } from "react";
import "./PreguntaRespuestaCuadrados.css";
import { useTranslation } from "react-i18next";
import "./CheckboxesCuestionarioSD.css";
function CheckboxesCuestionarioEQ5D5L({
  preguntas,
  respuestasComunes,
  onRespuestaSubmit,
  aclaracion
}) {
  const [respuestasSeleccionadas, setRespuestasSeleccionadas] = useState({});
  const [respuestaOtros, setRespuestaOtros] = useState("");
  const [t] = useTranslation("global");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  let estiloRespuesta,
    estiloTabla,
    estiloPregunta,
  
    estiloSeparador,
    estiloLinea;
 
  if (window.innerWidth >= 768) {
    // Estilo para pantalla grande
    estiloRespuesta = {
      padding: "5px",
      marginBottom: "5px",
      marginRight: "15px",
      textAlign: "left",
      display: "flex",
      alignItems: "left",
    };
 
    estiloTabla = {
      width: "100%",
      marginLeft: "4%",
      marginBottom: "3%",
    };
 
    estiloPregunta = {
      fontSize: "20px",
      whiteSpace: "pre-line",
      textAlign: "justify",
      marginLeft: "5%",
      marginBottom: "1%",
    };
 
 
    estiloSeparador = {
      margin: "15px 0",
    };
 
    estiloLinea = {
      marginLeft: "5px",
      marginBottom: "5px"
    };
  } else {
    // Estilo para pantalla pequeña (móvil)
    estiloRespuesta = {
      padding: "5px",
      marginBottom: "5px",
      display: "flex",
      alignItems: "center",
    };
 
    estiloTabla = {
      width: "100%",
      marginLeft: "0%",
    };
 
    estiloPregunta = {
      fontSize: "20px",
      whiteSpace: "pre-line",
      textAlign: "justify",
      marginLeft: "5%",
    };
 
    estiloSeparador = {
      margin: "15px 0",
    };
 
    estiloLinea = {
      marginLeft: "5px",
      marginBottom: "5px",
    };
  }
  useEffect(() => {
    // Escucha el evento de cambio de tamaño de la ventana y actualiza el ancho de la ventana
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
 
    window.addEventListener("resize", handleResize);
 
    return () => {
      // Limpia el evento de cambio de tamaño al desmontar el componente
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleRespuestaSubmit = (pregunta, respuesta) => {
    if (respuesta === t("Otros") || respuesta === t("Otro")) {
      // Si la respuesta es "Otros", permitir al usuario escribir su propia respuesta
      setRespuestasSeleccionadas({
        ...respuestasSeleccionadas,
        [pregunta]: respuestaOtros || respuesta,
      });
      onRespuestaSubmit(pregunta, respuestaOtros || respuesta); // Enviar respuesta cuando se modifica el input para "Otros"
    } else {
      const valorRespuesta =
        respuesta === respuestasSeleccionadas[pregunta] ? "" : respuesta;
 
      setRespuestasSeleccionadas({
        ...respuestasSeleccionadas,
        [pregunta]: valorRespuesta,
      });
 
      if (respuesta === t("Otros") || respuesta === t("Otro")) {
        // Limpiar respuestaOtros si la opción "Otros" fue desmarcada
        setRespuestaOtros("");
        onRespuestaSubmit(pregunta, valorRespuesta);
      } else {
        onRespuestaSubmit(pregunta, valorRespuesta);
      }
    }
  };
 
  return (
    <div>
      {preguntas &&
        preguntas.map((pregunta, index) => (
          <div key={index} >
            <div style={estiloPregunta}>
              <b>{pregunta}</b>
              <p>{aclaracion}</p>
            </div>
            <table style={estiloTabla}>
              <tbody>
                <tr>
                  <td>
                    {respuestasComunes.map((respuesta, respuestaIndex) => (
                      <label key={respuestaIndex} style={estiloRespuesta}>
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            className="checkbox__input"
                            checked={
                              respuesta === respuestasSeleccionadas[pregunta]
                            }
                            onChange={() =>
                              handleRespuestaSubmit(pregunta, respuesta)
                            }
                          />
                          <span className="checkbox__inner"></span>
                        </label>
                        <div className="respuestaChecknoxes" style={{textAlign:"left"}}>
                          {windowWidth > 800 ? (
                            <>
                              {respuesta.split("\n").map((line, lineIndex) => (
                                <span key={lineIndex} style={estiloLinea}>
                                  {line}
                                </span>
                              ))}
                            </>
                          ) : (
                            <span style={estiloLinea}>{respuesta}</span>
                          )}
                          {(respuesta === t("Otros") ||
                            respuesta === t("Otro")) &&
                            respuesta === respuestasSeleccionadas[pregunta] && (
                              <input
                                type="text"
                                value={respuestaOtros}
                                onChange={(e) => {
                                  setRespuestaOtros(e.target.value);
                                  onRespuestaSubmit(
                                    pregunta,
                                    "Otros + " + e.target.value
                                  ); // Enviar respuesta cada vez que se modifica el input para "Otros"
                                }}
                                placeholder={t("Especifique") + "..."}
                                style={{ marginLeft: "10px" }}
                              />
                            )}
                        </div>
                      </label>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
            {index < preguntas.length - 1 && (
              <div style={estiloSeparador}></div>
            )}
          </div>
        ))}
    </div>
  );
}
 
export default CheckboxesCuestionarioEQ5D5L;
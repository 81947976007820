import { useEffect, useContext, useState, useCallback } from "react";
import UserContext from "../../UserContext";
import Cuadrado from "../Graficos/Cuadrado";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Sueño_Icono from "../../Images/Sueño_Icono.png";
import "./SuenoCarta.css"

function SuenoCarta({ progreso, maxValor }) {
  const [chartData, setChartData] = useState(null);
  const email = useContext(UserContext);
  const [t] = useTranslation("global");
  const [fecha, setFecha] = useState(null);
  const [una, setUna] = useState(false);
  const [windowWidth] = useState(window.innerWidth);

  const arrayDeHoras = [
    { valor: "A", minutos: 17, color: "purple", horaInicio: "10:00 AM" },
    { valor: "B", minutos: 5, color: "green", horaInicio: "10:22 AM" },
    { valor: "C", minutos: 8, color: "blue", horaInicio: "10:30 PM" },
    { valor: "D", minutos: 20, color: "purple", horaInicio: "10:50 PM" },
    { valor: "E", minutos: 11, color: "blue", horaInicio: "11:01 PM" },
    { valor: "F", minutos: 7, color: "green", horaInicio: "11:08 PM" },
    { valor: "G", minutos: 13, color: "purple", horaInicio: "11:21 PM" },
    { valor: "H", minutos: 1, color: "green", horaInicio: "11:22 PM" },
    { valor: "I", minutos: 30, color: "blue", horaInicio: "11:52 PM" },
    { valor: "J", minutos: 23, color: "cyan", horaInicio: "12:17 PM" },
    // Agrega más objetos a tu array con valores, minutos, colores y horas de inicio
  ];

  const [cuadradoHovered, setCuadradoHovered] = useState(null);

  // Calcula totalMinutos sumando los minutos de todos los objetos en arrayDeHoras
  const totalMinutos = arrayDeHoras.reduce(
    (total, item) => total + item.minutos,
    0
  );

  const handleCuadradoHover = (
    color,
    minutos,
    horaInicio,
    horaFin,
    valor,
    index
  ) => {
    setCuadradoHovered({ color, minutos, horaInicio, horaFin, valor, index });
  };

  const consulta_sueno = useCallback(async () => {
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 365);
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedOneWeekAgo = oneWeekAgo.toISOString().split("T")[0];

    try {
      const response_durancion = await axios.get(
        t("Recursos.ip") + "/sueno",
        {
          params: {
            email: email.email,
            startTime: formattedOneWeekAgo,
            endTime: formattedCurrentDate,
          },
        }
      );
      // console.log("Datos sueno: ", response_durancion.data);
      setChartData(
        transformarFasesSueno(
          encontrarFechaFinMasReciente(procesarArray(response_durancion.data))
        ).reverse()
      );
    } catch (error) {
      console.error("Error:", error);
    }
  }, [email.email, t]);
  

  useEffect(() => {
    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if (email.email !== null && !una) {
      setUna(true);
      consulta_sueno();
    }
  }, [consulta_sueno, email, una]);

  function redondearHaciaArriba(fecha) {
    // Obtenemos los minutos y los segundos de la fecha
    const minutos = fecha.getMinutes();
    const segundos = fecha.getSeconds();

    // Redondeamos hacia arriba los segundos dividiendo entre 60 y sumando 1
    const segundosRedondeados = Math.ceil(segundos / 60) * 60;

    // Creamos una nueva fecha con los minutos originales y los segundos redondeados
    const nuevaFecha = new Date(fecha);
    nuevaFecha.setSeconds(0); // Reiniciamos los segundos
    nuevaFecha.setMinutes(
      minutos + Math.floor((segundos + segundosRedondeados) / 60)
    ); // Sumamos los minutos y el cociente de la división
    return nuevaFecha;
  }

  // Función para formatear la fecha al formato 'YYYY-MM-DDThh:mm:ss'
  function formatearFechas(fecha) {
    if (!(fecha instanceof Date)) {
      // Si no es un objeto Date, puedes manejar el error de alguna manera
      console.error("Error: Se esperaba un objeto Date");
      return null;
    }

    const year = fecha.getFullYear();
    const month = String(fecha.getMonth() + 1).padStart(2, "0");
    const day = String(fecha.getDate()).padStart(2, "0");
    const hours = String(fecha.getHours()).padStart(2, "0");
    const minutes = String(fecha.getMinutes()).padStart(2, "0");
    const seconds = String(fecha.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  // Función para procesar el array de fechas
  function procesarArray(array) {
    // Iteramos sobre cada objeto en el array
    const nuevoArray = array
      .map(item => {
        // Convertimos las cadenas de fecha a objetos Date
        const fechaInicio = new Date(item.t_inicio);
        const fechaFin = new Date(item.t_fin);

        // Redondeamos hacia arriba las fechas
        const nuevaFechaInicio = redondearHaciaArriba(fechaInicio);
        const nuevaFechaFin = redondearHaciaArriba(fechaFin);

        // Formateamos las fechas redondeadas al formato original
        const fechaInicioFormateada = formatearFechas(nuevaFechaInicio);
        const fechaFinFormateada = formatearFechas(nuevaFechaFin);

        // Verificamos si el formateo fue exitoso antes de devolver el nuevo objeto
        if (fechaInicioFormateada && fechaFinFormateada) {
          return {
            ...item,
            t_inicio: fechaInicioFormateada,
            t_fin: fechaFinFormateada,
          };
        } else {
          // Puedes manejar el error de alguna manera
          console.error("Error al formatear la fecha");
          return null;
        }
      })
      .filter(Boolean) // Filtramos los elementos nulos que pueden haber sido devueltos en caso de error
      .filter(item => item.t_inicio !== item.t_fin); // Filtramos los objetos donde t_inicio sea igual a t_fin

    return nuevoArray;
  }
  function encontrarFechaFinMasReciente(fasesSueno) {
    if (fasesSueno.length === 0) {
      return null; // O cualquier otro valor que desees para indicar que no hay fases de sueño.
    }

    const ultimasesion = [];
    // Ordena las fases de sueño por fecha de fin en orden descendente.
    fasesSueno.sort((a, b) => new Date(b.t_fin) - new Date(a.t_fin));

    for (let i = 0; i < fasesSueno.length; i++) {
      if (i === 0) {
        ultimasesion.push(fasesSueno[i]);
      } else {
        const fechaFinActual = new Date(fasesSueno[i].t_fin);
        const fechaInicioAnterior = new Date(fasesSueno[i  -1].t_inicio);

        // Comprueba si la fecha de inicio es igual a la fecha de fin de la fase anterior.
        if (fechaInicioAnterior.getTime() === fechaFinActual.getTime()) {
          ultimasesion.push(fasesSueno[i]);
        } else {
          break; // Finaliza el bucle si la fecha de inicio no coincide.
        }
      }
    }
    //const ultimasesion2=ultimasesion.slice(0,-3)
    setFecha(formatearFecha(ultimasesion[0].t_inicio));
    return ultimasesion;
  }
  const calcularTiempoTotalSueño = (datos, faseSuenoEnum1, faseSuenoEnum2, faseSuenoEnum3) => {
    // Filtrar los datos para obtener solo aquellos de las fases específicas
    const datosFiltrados = datos.filter((dato) => {
      return dato.valor === faseSuenoEnum1 || dato.valor === faseSuenoEnum2 || dato.valor === faseSuenoEnum3;
    });

    // Calcular el tiempo total en minutos
    const tiempoTotalEnMinutos = datosFiltrados.reduce((total, dato) => {
      const inicio = new Date(dato.horaInicio);
      const fin = new Date(dato.horaFin);
      const duracionEnMinutos = (fin - inicio) / (1000 * 60); // Convertir de milisegundos a minutos
      return total + duracionEnMinutos;
    }, 0);

    // Convertir el tiempo total a horas y minutos
    const horas = Math.floor(tiempoTotalEnMinutos / 60);
    const minutos = tiempoTotalEnMinutos % 60;

    return { horas, minutos };
  };
  function formatearFecha(dateString) {
    const date = new Date(dateString); // Convertir la cadena a un objeto Date
    if (isNaN(date.getTime())) {
      return "Fecha no válida"; // Manejar el caso en que la cadena no sea una fecha válida
    }

    const dia = date.getDate().toString().padStart(2, "0");
    const mes = (date.getMonth() + 1).toString().padStart(2, "0");
    const anio = date.getFullYear();

    return `${dia}-${mes}-${anio}`;
  }

  function obtenerHora(fechaYHora) {
    const fecha = new Date(fechaYHora);
    const hora = fecha.getHours();
    const minutos = fecha.getMinutes();

    // Si quieres un formato específico (por ejemplo, "05:34"), puedes formatearlo así:
    const horaFormateada = `${hora.toString().padStart(2, "0")}:${minutos
      .toString()
      .padStart(2, "0")}`;

    return horaFormateada;
  }

  function transformarFasesSueno(fasesSueno) {
    const colores = {
      SUENO_LIGERO: "#DFCCFB",
      SUENO_REM: "#756AB6",
      SUENO_PROFUNDO: "#BEADFA",
      DESPIERTO: "#FFF8C9",
    };
    const valores = {
      SUENO_LIGERO: t("Sueño-Ligero"),
      SUENO_REM: "REM",
      SUENO_PROFUNDO: t("Sueño-Profundo"),
      DESPIERTO: "Despierto/a",
    };

    const arrayDeHoras = [];
    let currentValor = null;
    let currentColor = null;
    let currentHoraInicio = null;
    let currentHoraFin = null;
    let currentMinutos = 0;

    fasesSueno.forEach((fase) => {
      if (fase.fasesSuenoEnum === currentValor) {
        currentMinutos += calcularDiferenciaMinutos(currentHoraFin, fase.t_fin);
        currentHoraFin = fase.t_fin; // Actualiza la hora de fin
      } else {
        if (currentValor !== null) {
          arrayDeHoras.push({
            valor: currentValor,
            minutos: currentMinutos,
            color: currentColor,
            horaInicio: currentHoraInicio,
            horaFin: currentHoraFin,
          });
        }

        currentValor = valores[fase.fasesSuenoEnum];
        currentColor = colores[fase.fasesSuenoEnum];
        currentHoraInicio = fase.t_inicio;
        currentHoraFin = fase.t_fin;
        currentMinutos = calcularDiferenciaMinutos(
          currentHoraInicio,
          currentHoraFin
        );
      }
    });

    if (currentValor !== null) {
      arrayDeHoras.push({
        valor: currentValor,
        minutos: currentMinutos,
        color: currentColor,
        horaInicio: currentHoraInicio,
        horaFin: currentHoraFin,
      });
    }

    return arrayDeHoras;
  }

  function calcularDiferenciaMinutos(horaInicio, horaFin) {
    const inicio = new Date(horaInicio);
    const fin = new Date(horaFin);
    return (fin - inicio) / (1000 * 60);
  }

  /* function calcularDiferenciaHoras(horaInicio, horaFin) {
    const horaInicioParts = horaInicio.split(":");
    const horaFinParts = horaFin.split(":");

    let inicio = new Date(0, 0, 0, horaInicioParts[0], horaInicioParts[1]);
    let fin = new Date(0, 0, 0, horaFinParts[0], horaFinParts[1]);

    if (fin < inicio) {
      fin.setHours(fin.getHours() + 24);
    }

    const diferenciaMs = fin - inicio;

    const horas = Math.floor(diferenciaMs / 3600000); // 1 hora = 3600000 ms
    const minutos = Math.floor((diferenciaMs % 3600000) / 60000); // 1 minuto = 60000 ms

    // Formatear la diferencia en "HH:MM"
    const diferenciaFormateada = `${horas
      .toString()
      .padStart(2, "0")}h ${minutos.toString().padStart(2, "0")}min`;

    return diferenciaFormateada;
  } */


  return (
    <div style={{ width: "100%", height: "100%", color: "black" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: "3%",
          color: "#340068",
          marginTop: "5%"
        }}
      >
        <img
          src={Sueño_Icono}
          alt={t("Sueno-Icono")}
          className="imagenSuenoCarta"
        />
        <h3 style={{ color: "black" }}>
          &nbsp;
          {t("Rutina-Sueno")}
        </h3>
      </div>

      {chartData && chartData.length > 0 && fecha ? (
        <div style={{ height: "80%", display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center" }}>

          <p style={{ marginBottom: "0" }}><b>{fecha}</b></p>

          <div >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "3%"
              }}
            >
              <div style={{ flex: 1, textAlign: "left", marginLeft: "3%" }}>
                <b> {t("Hora-de-acostarse")}:</b>
                <br />
                {obtenerHora(chartData[0].horaInicio)}
              </div>
              <div style={{ flex: 1, textAlign: "right", marginRight: "3%" }}>
                <b>{t("Hora-de-despertarse")}:</b>
                <br />
                {obtenerHora(chartData[chartData.length - 1].horaFin)}
              </div>
            </div>
            <div style={{ textAlign: "center", marginTop: "0%" }}>
              <b>{t("Tiempo-durmiendo")}:</b>
              <br />
              {
                calcularTiempoTotalSueño(chartData, t("Sueño-Ligero"), t("Sueño-Profundo"), "REM")
                  .horas
              }
              h{" "}
              {
                calcularTiempoTotalSueño(chartData, t("Sueño-Ligero"), t("Sueño-Profundo"), "REM")
                  .minutos
              }
              min
            </div>
          </div>
          <div>
            <div
              style={{
                width: "96%",
                display: "flex",
                flexDirection: "row",
                marginTop: "3%",
                height: "10%",
                marginLeft: "2%",
              }}
              className="chart-suenoCarta"
            >
              {chartData.map((item, index) => (
                <Cuadrado
                  key={index}
                  minutos={item.minutos}
                  color={item.color}
                  horaInicio={item.horaInicio}
                  horaFin={item.horaFin}
                  valor={item.valor}
                  widthPercentage={(item.minutos / totalMinutos) * 100}
                  onCuadradoHover={handleCuadradoHover}
                  index={index}
                />
              ))}
            </div>


            <div style={{ marginLeft: "5%", marginTop: "11%", color: "black", "minHeight": "5rem"}}>
              {cuadradoHovered && cuadradoHovered.color ? (
                <p>
                  <b>{t("Hora-Inicio")}:</b>{" "}
                  {obtenerHora(cuadradoHovered.horaInicio)}
                  <b> {t("Hora-Fin")}:</b>{" "}
                  {obtenerHora(cuadradoHovered.horaFin)}
                  <br />
                  <b>{t("Duracion")}:</b> {cuadradoHovered.minutos}min{" "}
                  <b>{t("Fase")}:</b> {cuadradoHovered.valor}
                </p>
              ) : (
                <p>
                  {windowWidth > 1200 ? (
                    t("Selecciona-grafico")
                  ) : (
                    " "
                  )}
                </p>



              )}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ marginTop: "20%" }}><b>{t("No-hay-datos-sueno-card")}</b></div>
      )}
    </div>


  );
}

export default SuenoCarta;

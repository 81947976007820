import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import RellenoRecomendados from "./RellenoRecomendados";
import { useTranslation } from "react-i18next";
import "./AgrupacionContenidos.css";
 
const AgrupacionContenidos = ({ contenidos, tema, titulosPagina }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [t] = useTranslation("global");
  const [pagina, setPagina] = useState(0);
  const [maxpagina, setMaxpagina] = useState(0);
  const [player, setPlayer] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [showButton, setShowButton] = useState(true);
 
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
 
  useEffect(() => {
    if (contenidos) {
      const maxPage = Math.max(
        ...contenidos.map((contenido) => contenido.pagina)
      );
      // setMaxpagina(maxPage);
      // setPagina(0);
    }
  }, [contenidos]);
 
  const handlePlayPause = () => {
    setPlaying(!playing);
  };
 
  const handlePause = () => {
    setPlaying(false);
    setShowButton(true);
  };
 
  const handlePlay = () => {
    setShowButton(false);
  };
 
  const playerWidth = window.innerWidth >= 768 ? "100vh" : "45vh";
  const playerHeight = window.innerWidth >= 768 ? "50vh" : "27vh";
 
  const getURLBasedOnTema = (tema) => {
    switch (tema) {
      case 'ESTRATEGIAS_CUIDADO':
        return 'https://youtu.be/SHeU5m4GHRM';
      case 'MI_AUTOCUIDADO_CONSCIENTE':
        return 'https://youtu.be/Ifq8IC3uGwQ';
      case 'MI_AUTOCUIDADO_PLANIFICADO':
        return 'https://youtu.be/eyaGBn4locM';
      case 'MI_AUTOCUIDADO_SALUDABLE':
        return 'https://youtu.be/FzF6vhR0i2I';
      case 'PLANIFICACIÓN_DEL_CUIDADO':
        return 'https://youtu.be/WzR0my2nflI';
      case 'PRINCIPIOS_DEL_CUIDADO':
        return 'https://youtu.be/sh0imiCkrz0';
      default:
        return 'Respuesta por defecto';
    }
  };
  return (
    <div className="paginacion-temas-container">
      {contenidos ? (
        <>
          <h3
            className="tituloAgrupacionContenidos"
            dangerouslySetInnerHTML={{ __html: titulosPagina }}
          ></h3>
          <div className="containerContenidos">
            {tema !== null && tema !== "Recomendados" ? (
              <div className="video-containerAgrupacionContenidos">
                <ReactPlayer
                  ref={setPlayer}
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload", // Quita la opción de descargar
                        crossOrigin: "anonymous",
                        preload: "auto",
                      },
                      tracks: [
                        {
                          kind: "subtitles",
                          src:
                            process.env.PUBLIC_URL +
                            "/Videos/Subtitulos/" +
                            tema +
                            ".vtt",
                          srcLang: "es",
                          default: false,
                        },
                      ],
                    },
                  }}
                  url={getURLBasedOnTema(tema)}
                  playing={playing}
                  loop={false}
                  controls={true}
                  onPause={handlePause}
                  onPlay={handlePlay}
                  width={playerWidth}
                  height={playerHeight}
                  onError={(e) => console.error('Error loading video:', e)}
                  onBuffer={() => console.log('Buffering...')}
                  onBufferEnd={() => console.log('Buffering ended')}
                  onProgress={(state) => console.log('Progress:', state)}
                  onReady={() => console.log('Video ready to play')}
                  onStart={() => console.log('Video started')}
                />
                {/* {showButton && !playing && windowWidth > 800 && (
                <button className="play-button" onClick={handlePlayPause}>
                  <FaPlay />
                </button>
              )} */}
              </div>
            ) : (
              <>
                {" "}
                {windowWidth > 800 ? (
                  <div>
                    <div className="agrupacionMecuido">
                      {contenidos
 
                        .slice(0, Math.min(4, contenidos.length))
                        .map((contenido, index) => (
                          <RellenoRecomendados
                            key={index}
                            nombreFront={contenido.nombreFront}
                            nombre={contenido.nombre}
                            contenido={contenido.contenido}
                          />
                        ))}
                    </div>
                    <div className="agrupacionMecuido">
                      {contenidos.length > 4 &&
                        contenidos
                          .slice(4)
                          .map((contenido, index) => (
                            <RellenoRecomendados
                              key={index}
                              nombreFront={contenido.nombreFront}
                              nombre={contenido.nombre}
                              contenido={contenido.contenido}
                            />
                          ))}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="agrupacionMecuido">
                      {contenidos
 
                        .slice(0, Math.min(2, contenidos.length))
                        .map((contenido, index) => (
                          <RellenoRecomendados
                            key={index}
                            nombreFront={contenido.nombreFront}
                            nombre={contenido.nombre}
                            contenido={contenido.contenido}
                          />
                        ))}
                    </div>
                    <div className="agrupacionMecuido">
                      {contenidos.length > 2 &&
                        contenidos
 
                          .slice(2, Math.min(4, contenidos.length))
                          .map((contenido, index) => (
                            <RellenoRecomendados
                              key={index}
                              nombreFront={contenido.nombreFront}
                              nombre={contenido.nombre}
                              contenido={contenido.contenido}
                            />
                          ))}
                    </div>
                    <div className="agrupacionMecuido">
                      {contenidos.length > 4 &&
                        contenidos
                          .slice(4, Math.min(6, contenidos.length))
                          .map((contenido, index) => (
                            <RellenoRecomendados
                              key={index}
                              nombreFront={contenido.nombreFront}
                              nombre={contenido.nombre}
                              contenido={contenido.contenido}
                            />
                          ))}
                    </div>
                    <div className="agrupacionMecuido">
                      {contenidos.length > 6 &&
                        contenidos
                          .slice(6, Math.min(8, contenidos.length))
                          .map((contenido, index) => (
                            <RellenoRecomendados
                              key={index}
                              nombreFront={contenido.nombreFront}
                              nombre={contenido.nombre}
                              contenido={contenido.contenido}
                            />
                          ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
 
        </>
      ) : (
        <div style={{ marginTop: "20%" }}>
          <b>{t("Conectando-BD")}</b>
        </div>
      )}
    </div>
  );
};
 
export default AgrupacionContenidos;
 
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TituloyFlecha from '../../components/TituloyFlecha';

const RevisarAdmin = () => {
    const { id, idCuestionario, idIntento } = useParams(); // Obtiene los parámetros de la URL
    const { t } = useTranslation('global');
    const [respuestas, setRespuestas] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Realiza la solicitud GET para obtener las respuestas del intento específico
                const response = await axios.get(`${t('Recursos.ip')}/respuesta/intento/revisar2`, {
                    params: {
                        id: id,
                        numerocuestionario: idCuestionario,
                        intento: idIntento
                    },
                });

                // Obtener las preguntas del cuestionario
                const numeroPreguntas = response.data.length;
                const preguntasArray = [];
                for (let i = 1; i <= numeroPreguntas; i++) {
                    const pregunta = t(`Cuestionario${idCuestionario}.Preguntas.${i}`);
                    preguntasArray.push(pregunta);
                }

                // Preparar las respuestas intercaladas con las preguntas
                const respuestasInterleaved = [];
                preguntasArray.forEach((pregunta, index) => {
                    respuestasInterleaved.push({ pregunta: pregunta, respuesta: response.data[index] });
                });

                setRespuestas(respuestasInterleaved);

            } catch (error) {
                console.error('Error al obtener las respuestas:', error);
                // Maneja el error de acuerdo a tus necesidades (puedes mostrar un mensaje al usuario, etc.)
            }
        };

        fetchData(); // Llama a la función para obtener la información al cargar el componente
    }, [id, idCuestionario, idIntento, t]);

    return (
        <div className="revisar-admin-page">
            <TituloyFlecha title={t("Revisión de Respuestas")} />
            <p>Respuestas del intento {idIntento} del cuestionario {t(`Cuestionario${idCuestionario}.Nombre`)}:</p>

            <div className="respuestas-container">
                {respuestas.map((item, index) => (
                    <div key={index} className="respuestas-item">
                        <p><strong>{item.pregunta}</strong>: {item.respuesta}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RevisarAdmin;

import React, { useState, useRef } from "react";
import { CgCloseR } from "react-icons/cg";
import { useTranslation } from "react-i18next";

function TextoConCard({ cuestionarioId }) {
  const [mostrarCard, setMostrarCard] = useState(false);
  const cardRef = useRef(null);
  const [t] = useTranslation("global");

  const toggleCard = () => {
    setMostrarCard(!mostrarCard);
  };

  const cerrarCard = () => {
    setMostrarCard(false);
  };

  const textoPrevio = () => {
    switch (cuestionarioId) {
      case "1":
        return (
          <p style={{ fontSize: "1.6vh" }}>
            {t("Cuestionario1.Explicacion.Texto1")}
          </p>
        );
      case "2":
        return (
          <p style={{ fontSize: "1.6vh" }}>
            {t("Cuestionario2.Explicacion.Texto1")}
          </p>
        );
      case "3":
        return (
          <p style={{ fontSize: "1.6vh" }}>
            {t("Cuestionario3.Explicacion.Texto1")}
          </p>
        );
      case "4":
        return (
          <p style={{ fontSize: "1.6vh" }}>
            {t("Cuestionario4.Explicacion.Texto1")}
          </p>
        );
      case "5":
        return (
          <p style={{ fontSize: "1.6vh" }}>
            {t("Cuestionario5.Explicacion.Texto1")}
          </p>
        );
      case "6":
        return (
          <p style={{ fontSize: "1.6vh" }}>
            {t("Cuestionario6.Explicacion.Texto1")}
          </p>
        );
      case "8":
        return (
          <p style={{ fontSize: "1.6vh" }}>
            {t("Cuestionario8.Explicacion.Texto1")}
          </p>
        );
      case "9":
        return (
          <p style={{ fontSize: "1.6vh" }}>
            {t("Cuestionario9.Explicacion.Texto1")}
          </p>
        );
      default:
        return "hola";
    }
  };

  const contentclicado = () => {
    switch (cuestionarioId) {
      case "1":
        return (
          <p style={{ color: "#148DBB", fontSize: "1.6vh" }}>
            {t("Cuestionario1.Explicacion.TextoBibliografia")}
          </p>
        );
      case "2":
        return (
          <p style={{ color:"#148DBB", fontSize: "1.6vh" }}>
            {t("Cuestionario2.Explicacion.TextoBibliografia")}
          </p>
        );
      case "3":
        return (
          <p style={{ color:"#148DBB", fontSize: "1.6vh" }}>
            {t("Cuestionario3.Explicacion.TextoBibliografia")}
          </p>
        );
      case "4":
        return (
          <p style={{ color:"#148DBB", fontSize: "1.6vh" }}>
            {t("Cuestionario4.Explicacion.TextoBibliografia")}
          </p>
        );
      case "5":
        return (
          <p style={{ color:"#148DBB", fontSize: "1.6vh" }}>
            {t("Cuestionario5.Explicacion.TextoBibliografia")}
          </p>
        );
      case "6":
        return (
          <p style={{ color:"#148DBB", fontSize: "1.6vh" }}>
            {t("Cuestionario6.Explicacion.TextoBibliografia")}
          </p>
        );
      case "8":
        return (
          <p style={{ color:"#148DBB", fontSize: "1.6vh", textAlign: "justify" }}>
            {t("Cuestionario8.Explicacion.TextoBibliografia")}
          </p>
        );
      case "9":
        return (
          <p style={{ color:"#148DBB", textAlign: "justify" }}>
            {t("Cuestionario9.Explicacion.TextoBibliografia")}
          </p>
        );
      default:
        return;
    }
  };

  const contentmostrar = () => {
    switch (cuestionarioId) {
      case "1":
        return (
          <p>
            <strong>
              {t(`Cuestionario${cuestionarioId}.Explicacion.TextoIntroduccion`)}
            </strong>
            <br />
            <em>
              {t(`Cuestionario${cuestionarioId}.Explicacion.TextoCuadro`)}
            </em>

           <a style={{color:"#148DBB"}}
              href={t(`Cuestionario${cuestionarioId}.Explicacion.TextoLink`)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <em> {t("Cuestionario1.Explicacion.TextoLink")}</em>
            </a>
            <br />
            <br />
            {t("Cuestionario1.Explicacion.TextoCuadro2")}
            <br />
            <br />
            <em>{t("Cuestionario1.Explicacion.TextoCuadro4")}</em>
           <a style={{color:"#148DBB"}}
              href="https://doi.org/10.1016/j.jval.2017.10.023"
              target="_blank"
              rel="noopener noreferrer"
            >
              <em> {t("Cuestionario1.Explicacion.TextoLink2")}</em>
            </a>
            <br />
            <br />
            <em> {t("Cuestionario1.Explicacion.TextoCuadro3")}</em>
           <a style={{color:"#148DBB"}}
              href="https://www.sanidad.gob.es/estadEstudios/estadisticas/encuestaNacional/encuesta2011.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              <em> {t("Cuestionario1.Explicacion.TextoLink3")}</em>
            </a>
          </p>
        );
      case "2":
        return (
          <p>
            <strong>{t("Cuestionario2.Explicacion.TextoCuadro")}</strong>
            <br />
            <br />
            <em>
              {t("Cuestionario2.Explicacion.TextoCuadro1")}
             <a style={{color:"#148DBB"}}
                href="https://doi.org/10.1007/s11136-005-5994-6"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Cuestionario2.Explicacion.TextoLink")}
              </a>
            </em>
          </p>
        );

      case "3":
        return (
          <p>
            <strong>{t("Cuestionario3.Explicacion.TextoCuadro")}</strong>

            <br />
            <br />
            <em>{t("Cuestionario3.Explicacion.TextoCuadro2")}</em>
           <a style={{color:"#148DBB"}}
              href="https://doi.org/10.1093/geront/20.6.649"
              target="_blank"
              rel="noopener noreferrer"
            >
              <em>{t("Cuestionario3.Explicacion.TextoLink")}</em>
            </a>
            <br />
            <br />
            <em> {t("Cuestionario3.Explicacion.TextoCuadro4")}</em>
           <a style={{color:"#148DBB"}}
              href="https://doi.org/10.1037/0882-7974.2.3.225  "
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Cuestionario3.Explicacion.TextoLink2")}
            </a>
            <br />
            <br />
            {t("Cuestionario3.Explicacion.TextoCuadro3")}
            <br />
            <br />
            <em>
              {t("Cuestionario3.Explicacion.TextoCuadro5")}
             <a style={{color:"#148DBB"}}
                href="https://dialnet.unirioja.es/servlet/articulo?codigo=2959659"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Cuestionario3.Explicacion.TextoLink3")}
              </a>
            </em>
            <br />
            <br />
            <em>
              {t("Cuestionario3.Explicacion.TextoCuadro6")}
             <a style={{color:"#148DBB"}}
                href="https://doi.org/10.1080/13607860903586094"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Cuestionario3.Explicacion.TextoLink4")}
              </a>
            </em>
          </p>
        );

      case "4":
        return (
          <p>
            <strong>{t("Cuestionario4.Explicacion.TextoCuadro")}</strong>

            <br />
            <br />
            <em>
              {" "}
              {t("Cuestionario4.Explicacion.TextoCuadro2")}
             <a style={{color:"#148DBB"}}
                href="https://doi.org/10.1093/geronj/38.3.344"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Cuestionario4.Explicacion.TextoLink")}
              </a>
            </em>
            <br />
            <br />
            {t("Cuestionario4.Explicacion.TextoCuadro3")}
            <br />
            <br />
            <em>{t("Cuestionario4.Explicacion.TextoCuadro4")}</em>
            <br />
            <br />
            <em>
              {" "}
              {t("Cuestionario4.Explicacion.TextoCuadro5")}
             <a style={{color:"#148DBB"}}
                href="https://doi.org/10.1016/S1138-3593(08)75201-8"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Cuestionario4.Explicacion.TextoLink2")}
              </a>
            </em>
          </p>
        );

      case "5":
        return (
          <p>
            <strong>{t("Cuestionario5.Explicacion.TextoCuadro")}</strong>

            <br />
            <br />
            <em>
              {" "}
              {t("Cuestionario5.Explicacion.TextoCuadro2")}
             <a style={{color:"#148DBB"}}
                href="https://doi.org/10.3109/11038128.2014.934917"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Cuestionario5.Explicacion.TextoLink")}
              </a>
            </em>
            <br />
            <br />
            {t("Cuestionario5.Explicacion.TextoCuadro3")}
            <br />
            <br />
            <em>
              {" "}
              {t("Cuestionario5.Explicacion.TextoCuadro4")}
             <a style={{color:"#148DBB"}}
                href="https://doi.org/10.3390/ijerph18147506 "
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Cuestionario5.Explicacion.TextoLink2")}
              </a>
            </em>
          </p>
        );

      case "6":
        return (
          <p>
            <strong>{t("Cuestionario6.Explicacion.TextoCuadro")}</strong>

            <br />
            <br />
            <em>
              {t("Cuestionario6.Explicacion.TextoCuadro2")}
             <a style={{color:"#148DBB"}}
                href="https://www.jstor.org/stable/256789?origin=JSTOR-pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Cuestionario6.Explicacion.TextoLink3")}
              </a>
            </em>
            <br />
            <br />
            <em>
              {" "}
              {t("Cuestionario6.Explicacion.TextoCuadro3")}
             <a style={{color:"#148DBB"}}
                href="https://doi.org/10.2307/256789"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Cuestionario6.Explicacion.TextoLink")}
              </a>
            </em>
            <br />
            <br />
            {t("Cuestionario6.Explicacion.TextoCuadro4")}
            <br />
            <br />
            <em>
              {" "}
              {t("Cuestionario6.Explicacion.TextoCuadro5")}
             <a style={{color:"#148DBB"}}
                href="https://doi.org/10.16888/INTERD.2022.39.1.12"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Cuestionario6.Explicacion.TextoLink2")}
              </a>
            </em>
          </p>
        );

      case "8":
        return (
          <p>
            <strong>{t("Cuestionario8.Explicacion.TextoCuadro")}</strong>

            <br />
            <br />
            <em>
              {" "}
              {t("Cuestionario8.Explicacion.TextoCuadro2")}
             <a style={{color:"#148DBB"}}
                href="https://eric.ed.gov/?id=EJ1145014"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Cuestionario8.Explicacion.TextoLink")}
              </a>
            </em>
          </p>
        );

      case "9":
        return (
          <p>
            <strong>{t("Cuestionario9.Explicacion.TextoCuadro")}</strong>

            <br />
            <br />
            <em>
              {" "}
              {t("Cuestionario9.Explicacion.TextoCuadro2")}
             <a style={{color:"#148DBB"}}
                href="https://doi.org/10.3390/IJERPH18041413"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Cuestionario9.Explicacion.TextoLink")}
              </a>
            </em>
          </p>
        );

      default:
        return (
          <div style={{ maxWidth: "80%" }}>
            <strong>
              {t(`Cuestionario${cuestionarioId}.Explicacion.TextoCuadro`)}
            </strong>

           <a style={{color:"#148DBB"}}
              href={t(`Cuestionario${cuestionarioId}.Explicacion.TextoLink`)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Cuestionario1.Explicacion.TextoLink")}
            </a>
            {t("Cuestionario1.Explicacion.TextoCuadro2")}
            <br />
            {t("Cuestionario1.Explicacion.TextoCuadro4")}
           <a style={{color:"#148DBB"}}
              href="https://doi.org/10.1016/j.jval.2017.10.023"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Cuestionario1.Explicacion.TextoLink2")}
            </a>
            <br />
            {t("Cuestionario1.Explicacion.TextoCuadro3")}
           <a style={{color:"#148DBB"}}
              href="https://www.sanidad.gob.es/estadEstudios/estadisticas/encuestaNacional/encuesta2011.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Cuestionario1.Explicacion.TextoLink3")}
            </a>
          </div>
        );
    }
  };

  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (cardRef.current && !cardRef.current.contains(event.target)) {
  //       cerrarCard();
  //     }
  //   };

  //   document.addEventListener("mousedown", handleOutsideClick);

  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, []);

  return (
    <div style={{ position: "relative" }}>
      <span>{textoPrevio()}</span>
      <span
        onClick={toggleCard}
        style={{ cursor: "pointer", display: "inline-block" }}
      >
        {contentclicado()}
      </span>

      {mostrarCard && (
        <div
          ref={cardRef}
          style={{
            position: "absolute",
            top: window.innerWidth <= 768 ? "-30%" : "50%",
            left: window.innerWidth <= 768 ? "auto" : "50%",
            transform:
              window.innerWidth <= 768 ? "auto" : "translate(-50%, -20%)",
            border: "1px solid #ccc",
            padding: "20px",
            backgroundColor: "#F8F8F8",
            zIndex: 20,
            borderRadius: "10px",
            maxWidth: "100%",
            overflow: "hidden",
            wordWrap: "break-word",
          }}
        >
          <span
            style={{
              top: "-9px",
              right: "-1px",
              cursor: "pointer",
              fontWeight: "bold",
            }}
            onClick={cerrarCard}
          >
            <CgCloseR style={{ marginBottom: "1%", fontSize: "25px" }} />
          </span>
          {contentmostrar()}
        </div>
      )}
    </div>
  );
}

export default TextoConCard;

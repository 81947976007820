import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const IdPilotoModal = ({ userSelected, setUserSelected, showModal, setShowModal }) => {
  const { t } = useTranslation("global");
  const [modalInput, setModalInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
    
  const handleDelete = (id) => {
    setIsLoading(true);
    axios.put(`${t("Recursos.ip")}/accounts/updateIdPiloto`, {
      id: id,
      id_piloto: modalInput,
    })
      .catch((error) => console.error("Error al eliminar el usuario:", error))
      .then((response) => {
        setShowModal(false);
        userSelected.id_piloto = modalInput;
        setUserSelected(null);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setModalInput(userSelected?.id_piloto ?? "");
  }, [userSelected]);

  return (
    <div className={"modal-container" + (showModal ? " active" : "")}>
      <div className="modal-window">
        {isLoading ?
          <div className="spinner"></div>
        :
          <>
          <div className="modal-content">
            <h4>Escribe el nuevo ID piloto para <strong>{userSelected?.login.trim()}</strong>:</h4>
            <input
              type="text"
              placeholder="Correo"
              className={modalInput === userSelected?.id_piloto? "incorrect": ""}
              value={modalInput}
              onChange={(e) => setModalInput(e.target.value)}
            />
          </div>
          <div className="modal-buttons">
            <button className="modal-cancel"
              onClick={() => setShowModal(false)}
              disabled={isLoading}
            >Cancelar</button>
            <button
              className="modal-accept"
              onClick={() => handleDelete(userSelected?.id)}
              disabled={modalInput === userSelected?.id_piloto || isLoading}
            >Actualizar</button>
          </div>
          </>
        }
      </div>
    </div>
  );
};

export default IdPilotoModal;
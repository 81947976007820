import React from "react";
import Diario_Semanal_Icono from "../../Images/Diario_Semanal_Icono.png";
import "./MiDiarioSemanalCarta.css";
import Card from "../Card";
import { useTranslation } from "react-i18next";
function MiDiarioSemanalCarta() {
  const [t] = useTranslation("global");
  return (
    <div>
      <div style={{ width: "75%", margin: "0 auto", marginTop: "-2rem" }}>
        <Card
          content={t("Mi-Diario-Semanal")}
          alto="50%"
          ancho="100%"
          className={"home-card-header"}
        />
      </div>
      <div style={{ marginTop: "0.5vh" }}>
        {/* Texto en la parte superior */}
        <p style={{ color: "black" }}>{t("Mi-Diario-Semanal-texto")}</p>

        <img
          style={{ marginBottom: "1vh" }}
          src={Diario_Semanal_Icono}
          alt={t("Mi-Diario-Semanal-Icono")}
          className="imagenMiDiarioSemanalCarta"
        />
      </div>
    </div>
  );
}

export default MiDiarioSemanalCarta;

import React from "react";
import { useTranslation } from "react-i18next";
import "./Descarga.css";
import capturaconmovil from "../Images/capturaconmovil.png";
import TituloyFlecha from "../components/TituloyFlecha";

const Descarga = () => {
  const [t] = useTranslation("global");
  const handleDownload = () => {
    // Nombre del archivo APK local
    const apkFileName = 'CuidaconTIC.apk';

    // Ruta al archivo APK local
    const apkUrl = process.env.PUBLIC_URL + "/APK/" + apkFileName;

    // Crear un elemento <a> temporal para iniciar la descarga
    const link = document.createElement('a');
    link.href = apkUrl;
    link.download = apkFileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>

      <TituloyFlecha
        title={t("Descarga")}
        link={"/login"}
      />
      <div className="centerDescarga2">
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <img
            src={capturaconmovil}
            alt={t("Captura-movil")}
            style={{ width: "25vh", marginRight: "10px" }}
          />
        </div>
        <div
          className="textoDescarga"

        >
          <br></br>
          <h3>Descarga nuestra aplicación disponible en Android</h3>
          <br></br>
          <button className="botoncircDescarga" onClick={handleDownload}>Descargar APK</button>
        </div>
      </div>
    </div>
  );
};

export default Descarga;

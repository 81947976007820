import { useEffect, useContext, useState } from "react";
import UserContext from "../../UserContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./Cuestionario9Semanal.css";
import "./CuestionarioSocioDemo.css"
import CheckboxesCuestionarioSD from "../../components/Cuestionarios/CheckboxesCuestionarioSD";
import TituloyFlecha from "../../components/TituloyFlecha";


const Cuestionario9 = () => {
  const navigate = useNavigate();
  const email = useContext(UserContext);

  const [t] = useTranslation("global");
  const numero_cuestionario = 9;
  const [todoRespondido, SetTodoRespondido] = useState(true);
  const [pagina, sePagina] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [navigate]);


  const preguntas = [
    t("Cuestionario9.Preguntas.1"),
    t("Cuestionario9.Preguntas.2"),
    t("Cuestionario9.Preguntas.3"),
    t("Cuestionario9.Preguntas.4"),
    t("Cuestionario9.Preguntas.5"),
    t("Cuestionario9.Preguntas.6"),
    t("Cuestionario9.Preguntas.7"),
    t("Cuestionario9.Preguntas.8"),
    t("Cuestionario9.Preguntas.9"),
  ];
  // Utilizar un objeto para almacenar las respuestas
  const [respuestas, setRespuestas] = useState({});

  /* useEffect(() => {
    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if (email.email) {
      const consulta_respuesta = async () => {
        try {
          const response = await axios.get(t("Recursos.ip") + "/semanalcubierto", {
            params: {
              email: email.email,
            },
          });
          if (response.data) {
            console.log(response.data);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      };
      consulta_respuesta();
    }
  }, [t,email]); */

  
  // Función para manejar las respuestas de cada instancia de PreguntaRespuesta
  const handleRespuestaSubmit = (pregunta, respuesta) => {
    // console.log(pregunta, respuesta);
    // Actualizar el objeto de respuestas con la respuesta correspondiente
    setRespuestas((prevRespuestas) => ({
      ...prevRespuestas,
      [pregunta]: respuesta,
    }));
  };

  // Función para mostrar todas las respuestas en la consola
  const mostrarRespuestasEnConsola = async () => {
    const respuestasEnTexto = preguntas
      .map((pregunta) => `"${respuestas[pregunta] || " "}"`)
      .join(",");
    const puntuacion =
      '"' +
      traducirArray(
        preguntas.map((pregunta) => `${respuestas[pregunta] || " "}`)
      ).join('","') +
      '"';
    console.log("Respuestas en texto:", respuestasEnTexto, puntuacion);
    const hayDosComillasSinValor = /""|\s*" "\s*|\[object Object\]/.test(
      respuestasEnTexto
    );

    if (hayDosComillasSinValor) {
      SetTodoRespondido(false);
    } else {
      SetTodoRespondido(true);
      try {
        const response = await axios.post(t("Recursos.ip") + "/respuesta", {
          numeroCuestionario: numero_cuestionario,
          arrayCuestionario: respuestasEnTexto,
          arrayPuntuacionCuestionario: puntuacion,
          email: email.email,
        });

        console.log(response.data);
        navigate("/");
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const traducirArray = (array) => {
    return array.map((elemento, index) => traducirAValor(elemento, index === 1));
  };
  const traducirAValor = (cadena, esSegundoValor) => {
    switch (cadena) {
      case t("Cuestionario9.Respuestas.1"):
        return esSegundoValor ? 5 : 0;
      case t("Cuestionario9.Respuestas.2"):
        return esSegundoValor ? 4 : 1;
      case t("Cuestionario9.Respuestas.3"):
        return esSegundoValor ? 3 : 2;
      case t("Cuestionario9.Respuestas.4"):
        return esSegundoValor ? 2 : 3;
      case t("Cuestionario9.Respuestas.5"):
        return esSegundoValor ? 1 : 4;
      case t("Cuestionario9.Respuestas.6"):
        return esSegundoValor ? 0 : 5;

      default:
        return cadena; // Valor por defecto o manejo de error
    }
  };

  function paginar(valor) {
    SetTodoRespondido(true);
    if (valor > 0 && pagina < 6) {
      sePagina(pagina + valor);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (valor < 0 && pagina > 0) {
      sePagina(pagina + valor);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }


  return (
    <div>
      <div>
      <TituloyFlecha
        title={t("Cuestionario9.Titulo")}
        link={"/Cuestionario/9/intentos"}
      />



      </div>
      <p style={{ margin: "auto", textAlign: "justify", width: "80%", marginTop: "2%", fontSize:"20px" }}>{t("Cuestionario9.Intro")}</p>
      <div className="cuestionarioContainer" style={{ marginTop: "0.5%" }}>

        <h2 style={{ textAlign: "left", marginLeft: "4%", marginBottom: "2%" }}> Durante esta semana...</h2>
        <div style={{ display: pagina === 0 ? "block" : "none" }}>
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[0]]}
            respuestasComunes={[t("Cuestionario9.Respuestas.1"), t("Cuestionario9.Respuestas.2"), t("Cuestionario9.Respuestas.3"), t("Cuestionario9.Respuestas.4"), t("Cuestionario9.Respuestas.5"), t("Cuestionario9.Respuestas.6"),]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[1]]}
            respuestasComunes={[t("Cuestionario9.Respuestas.1"), t("Cuestionario9.Respuestas.2"), t("Cuestionario9.Respuestas.3"), t("Cuestionario9.Respuestas.4"), t("Cuestionario9.Respuestas.5"), t("Cuestionario9.Respuestas.6"),]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[2]]}
            respuestasComunes={[t("Cuestionario9.Respuestas.1"), t("Cuestionario9.Respuestas.2"), t("Cuestionario9.Respuestas.3"), t("Cuestionario9.Respuestas.4"), t("Cuestionario9.Respuestas.5"), t("Cuestionario9.Respuestas.6"),]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[3]]}
            respuestasComunes={[t("Cuestionario9.Respuestas.1"), t("Cuestionario9.Respuestas.2"), t("Cuestionario9.Respuestas.3"), t("Cuestionario9.Respuestas.4"), t("Cuestionario9.Respuestas.5"), t("Cuestionario9.Respuestas.6"),]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[4]]}
            respuestasComunes={[t("Cuestionario9.Respuestas.1"), t("Cuestionario9.Respuestas.2"), t("Cuestionario9.Respuestas.3"), t("Cuestionario9.Respuestas.4"), t("Cuestionario9.Respuestas.5"), t("Cuestionario9.Respuestas.6"),]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
        </div>
        <div style={{ display: pagina === 1 ? "block" : "none" }}>
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[5]]}
            respuestasComunes={[t("Cuestionario9.Respuestas.1"), t("Cuestionario9.Respuestas.2"), t("Cuestionario9.Respuestas.3"), t("Cuestionario9.Respuestas.4"), t("Cuestionario9.Respuestas.5"), t("Cuestionario9.Respuestas.6"),]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[6]]}
            respuestasComunes={[t("Cuestionario9.Respuestas.1"), t("Cuestionario9.Respuestas.2"), t("Cuestionario9.Respuestas.3"), t("Cuestionario9.Respuestas.4"), t("Cuestionario9.Respuestas.5"), t("Cuestionario9.Respuestas.6"),]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[7]]}
            respuestasComunes={[t("Cuestionario9.Respuestas.1"), t("Cuestionario9.Respuestas.2"), t("Cuestionario9.Respuestas.3"), t("Cuestionario9.Respuestas.4"), t("Cuestionario9.Respuestas.5"), t("Cuestionario9.Respuestas.6"),]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
          <CheckboxesCuestionarioSD
            preguntas={[preguntas[8]]}
            respuestasComunes={[t("Cuestionario9.Respuestas.1"), t("Cuestionario9.Respuestas.2"), t("Cuestionario9.Respuestas.3"), t("Cuestionario9.Respuestas.4"), t("Cuestionario9.Respuestas.5"), t("Cuestionario9.Respuestas.6"),]}
            onRespuestaSubmit={handleRespuestaSubmit}
          />
        </div>
        <div style={{ marginBottom: "-5%" }}>
          {!todoRespondido && (
            <div>
              <p style={{ color: "red" }}>{t("Responde-todo")}</p>
            </div>
          )}
          <div style={{ display: "flex" }}>
            <button
              onClick={() => paginar(-1)}
              style={{
                marginTop: "30px",
                marginRight: window.innerWidth <= 768 ? "10%" : "-25%",
                width: window.innerWidth <= 768 ? "50%" : "20%",
                //visibility: pagina === 0 ? "hidden" : "visible",
                background: pagina ===0 ?" #A5BBC1" : "",
              }}
              className="link-button"
            >
              {t("Atras")}
            </button>

            {pagina < 1 && (
              <button
                onClick={() => paginar(1)}
                style={{
                  marginTop: "30px",
                  marginLeftt: window.innerWidth <= 768 ? "-10%" : "25%",
                  width: window.innerWidth <= 768 ? "50%" : "20%",
                  visibility: pagina === 1 ? "hidden" : "visible",
                }}
                className="link-button"
              >
                {t("Continuar")}
              </button>
            )}
            {pagina === 1 && (
              <button
                onClick={mostrarRespuestasEnConsola}
                className="link-button"
                style={{
                  width: window.innerWidth <= 768 ? "50%" : "20%",
                  marginTop: "30px",
                  backgroundColor:"#51A670",
                }}
              >
                {t("Confirmar")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cuestionario9;

import React, { useEffect, useContext, useState } from "react";
import UserContext from "../UserContext";
import axios from "axios";
import Card from "../components/Card";
import { Link, useNavigate } from "react-router-dom";
import "./Home.css";
import MisDatosSaludCarta from "../components/RellenoCartas/MisDatosSaludCarta";
import MiDiarioSemanalCarta from "../components/RellenoCartas/MiDiarioSemanalCarta";
import MiAutocuidadoCarta from "../components/RellenoCartas/MiAutocuidadoCarta";
import RecomendacionesCarta from "../components/RellenoCartas/RecomendacionesCarta";
import CartaGrandeHome from "../components/RellenoCartas/CartaGrandeHome";
import { useTranslation } from "react-i18next";

const Home = () => {
  const email = useContext(UserContext);
  const { nombre, setNombre } = useContext(UserContext);
  const [sexo, setSexo] = useState("@");
  const [t] = useTranslation("global");
  const navigate = useNavigate();

  const [una, setUna] = useState(false);

  useEffect(() => {
    if (email.email && !una) {
      setUna(true);
      getUserInfo();
    }
  }, [email]); //

  const getUserInfo = async () => {
    try {
      const response = await axios.get(t("Recursos.ip") + "/accounts/getuser", {
        params: {
          email: email.email,
        },
      });

      console.log(response.data);
      setNombre(response.data.nombre);
      setSexo(response.data.sexo);
      console.log(
        "Nombre:",
        email.email,
        response.data.nombre,
        response.data.sexo
      );

      const lastLoginTime = new Date(response.data.ultimologin).getTime();
      const currentTime = new Date().getTime();
      const threeMinutesInMilliseconds = 3 * 60 * 1000; // 3 minutos en milisegundos

      // Comprueba si la fecha de última conexión es más antigua que 3 minutos
      if (currentTime - lastLoginTime > threeMinutesInMilliseconds) {
        navigate("/PantalladeCarga");
      }

      //return { email, name };
    } catch (error) {
      console.error("Error al obtener información del usuario:", error);
      return null;
    }
  };

  const genero = () => {
    switch (sexo) {
      case "Masculino":
        return t("Bienvenido");
      case "Femenino":
        return t("Bienvenida");
      default:
        // Devuelve una imagen predeterminada o maneja el caso no especificado según tus necesidades
        return t("Hola");
    }
  };

  return (
    <div className="mainContainerHome">

      <div className="margentitulohome">
        {nombre && sexo && (
          <h1>
            {genero()} {nombre}
          </h1>
        )}
      </div>
      <div className="center-tableHome">
        <Card
          content={
            <CartaGrandeHome
              progresosueno={8}
              maxValorsueno={10}
              progresoactividad={3254}
              maxValoractividad={4000}
            />
          }
          /* alto="100%"
          ancho="95%" */
          className="cardhomegrande"
        />
        <div className="home-right-container">
          <Link to="/MiSalud" className="cardhome" >
            <MisDatosSaludCarta />
          </Link>
          <Link to="/Cuestionario/9/intentos" className="cardhome">
            <MiDiarioSemanalCarta />
          </Link>
          <Link to="/Mecuido/Recomendados" className="cardhome">
            <MiAutocuidadoCarta />
          </Link>
          <Link to="/Tecuido/Recomendados" className="cardhome">
            <RecomendacionesCarta />
          </Link>
        </div>
      </div>
      {/* <button onClick={(e) => calloauth()}>OAUTH</button>
        <button onClick={(e) => getUserInfo(bearer.bearer)}>Email</button>
        <button onClick={downloadCSV}>CSV</button>
        <button onClick={postButtonClick}>Enviar Solicitud Post</button> */}
    </div>
  );
  };

export default Home;

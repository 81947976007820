import { useEffect, useState, useRef } from "react";
import { BsX } from "react-icons/bs";

import "./SelectEtiquetaUsuarioAdmin.css";

const SelectEtiquetaUsuarioAdmin = ({tags, selectedTags, setSelectedTags}) => {
  const [isVisible, setIsVisible] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (value) => {
    if (!selectedTags.some((tags) => tags.value === value)) {
      const selectedTag = tags.find((tags) => tags.value === value);
      setSelectedTags((selectedTags) => [...selectedTags, selectedTag]);
    }
  };
  const handleRemove = (e, value) => {
    console.log(value, e.target, e.currentTarget);
    setSelectedTags((selectedTags) => selectedTags.filter((tags) => tags.value !== value));
  };

  const handleToggleSelect = (e) => {
    e.stopPropagation();
    setIsVisible((isVisible) => !isVisible);
  };
  
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    console.log(selectedTags);
  }, [selectedTags]);

  return (
  <div
    ref={dropdownRef}
    className="select-tag-custom-tag-select-container"
  >
    <div
      className="select-tag-custom-select-input"
      onClick={handleToggleSelect}
    >
      {selectedTags.map((tag) => (
        <div key={tag.value} className="select-tag-user-selected-tag">
          <span>{tag.label}</span>
          <span onClick={(e) => handleRemove(e, tag.value)}>
            <BsX />
          </span>
        </div>
      ))}
      {selectedTags.length === 0 && (
        <input
          type="text"
          placeholder="Etiquetas..."
          className="select-tag-select-input"
          readOnly
        />
      )}
    </div>

    {isVisible && (
      <div className="select-tag-dropdown">
        <div className="select-tag-dropdown-list">
          {tags
            .filter((tag) => !selectedTags.some((selected) => selected.value === tag.value))
            .map((tag) => (
              <div
                key={tag.value}
                className="select-tag-dropdown-item"
                onClick={() => handleSelect(tag.value)}
              >
                <span className="select-tag-dropdown-item-label">{tag.label}</span>
              </div>
            ))}
          </div>
      </div>
    )}
  </div>
  );
};

export default SelectEtiquetaUsuarioAdmin;
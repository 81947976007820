import React from "react";
import "./Ayuda.css";
import { useTranslation } from "react-i18next";
import EsquemaSincronizacion from "../../Images/EsquemaSincronización.png";
import TituloyFlecha from "../../components/TituloyFlecha";

const AyudaSincronizacion = () => {
  const [t] = useTranslation("global");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="containerAyudaSincronizacion">

        <TituloyFlecha
          title={t("AyudaSincronizacion.Titulo")}
          link={"atras"}
        />


        <div className="contenidoAyudaSincronizacion">
          <h2 className="subtituloAyudaSincronizacion">
            {t("AyudaSincronizacion.Pregunta")}
          </h2>
          <p>
            {t("AyudaSincronizacion.Texto1")}
          </p>
          <p>
            <b>1.</b>   {t("AyudaSincronizacion.Texto2")}
          </p>
          <p>
            <b>2.</b>   {t("AyudaSincronizacion.Texto3")}
          </p>
          <p>
            <b>3.</b>   {t("AyudaSincronizacion.Texto4")}
          </p>
          <img
            src={EsquemaSincronizacion}
            alt={"EsquemaSincronizacion"}
            className="imagenAyudaSincronizacion"
          />
        </div>
      </div>
    </div>
  );
};

export default AyudaSincronizacion;

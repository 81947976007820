import React, { useState, useEffect } from 'react';
import { motion, animate, useMotionValue } from "framer-motion";

const AnimatedProgressProvider = ({
  valueStart = 0,
  valueEnd,
  duration,
  easingFunction,
  children
}) => {
  const start = typeof valueStart === 'number' ? valueStart : 0;
  const end = typeof valueEnd === 'number' ? valueEnd : 0;
  const x = useMotionValue(start);
  const [xValue, setXValue] = useState(valueStart);

  useEffect(() => {
    const controls = animate(x, end, {
      duration: duration,
      ease: easingFunction,
      onUpdate: (latestValue) => setXValue(Math.round(latestValue)),
    });
    return () => controls.stop();
  }, [x, valueEnd, easingFunction, duration, end]);

  return (
    <motion.div>
      {children(xValue)}
    </motion.div>
  );
};

export default AnimatedProgressProvider;

import { useEffect, useContext, useState } from "react";
import UserContext from "../../UserContext";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./Tecuido.css";
import axios from "axios";
import PaginacionTemas from "../../components/PaginacionTemas";
import AgrupacionContenidos from "../../components/AgrupacionContenidos";
import { useTranslation } from "react-i18next";
import { FaStar } from "react-icons/fa";
import { TfiArrowCircleLeft } from "react-icons/tfi";
import { TfiArrowCircleRight } from "react-icons/tfi";
import TituloyFlecha from "../../components/TituloyFlecha";

const Tecuido = () => {
  const [t] = useTranslation("global");

  const { temaSeleccionado } = useParams();
  const email = useContext(UserContext);
  const [contenidos, setContenidos] = useState(null);
  const [tema, setTema] = useState("Recomendados");
  const [titulo, setTitulo] = useState(null);
  const [anterior, setAnterior] = useState(null);
  const [siguiente, setSiguiente] = useState(null);
  const titulosRecomendados = [t("TeCuido.TextoIntroduccion.Recomendados")];
  const titulosPrincipiosdelCuidadodeOtrasPersonas = [
    t("TeCuido.TextoIntroduccion.Tema1"),
    "Principios para el cuidado de otra persona desde el respeto y la empatía",
  ];
  const navigate = useNavigate();
  const PrincipiosdelCuidadodeOtrasPersonas = [
    {
      nombre: "Cuidadodeotraspersonas.pdf",
      nombreFront: "El cuidado de otras personas",
      contenido: "presentacion",
      pagina: 1,
    },
    {
      nombre: "FasesAdaptacionRol.pdf",
      nombreFront: "Fases de adaptación al rol de persona cuidadora ",
      contenido: "infografia",
      pagina: 1,
    },
    {
      nombre: "PrincipiosCuidado.pdf",
      nombreFront: "Principios básicos del cuidado de otras personas ",
      contenido: "infografia",
      pagina: 1,
    },
    {
      nombre: "TareaRealidad.pdf",
      nombreFront: "Empatizar con la persona que cuidas. Viviendo su realidad",
      contenido: "tarea",
      pagina: 1,
    },
    {
      nombre: "TareaRespeto.pdf",
      nombreFront:
        "Fomentar el respeto y la dignidad a través de la historia de vida",
      contenido: "tarea",
      pagina: 1,
    },
    {
      nombre: "TareaEmpatia.pdf",
      nombreFront: "Reflexiones de empatía: La historia de Alex ",
      contenido: "tarea",
      pagina: 1,
    },
    {
      nombre: "MatCompltema1.pdf",
      nombreFront: "Material complementario",
      contenido: "material_complementario",
      pagina: 1,
    },
  ];
  const titulosPlanificaciondelCuidado = [
    t("TeCuido.TextoIntroduccion.Tema2"),
    " Recursos y herramientas para crear un plan de cuidados integral",
  ];
  const PlanificaciondelCuidado = [
    {
      nombre: "PlandeCuidados.pdf",
      nombreFront: "El plan de cuidados",
      contenido: "presentacion",
      pagina: 1,
    },
    {
      nombre: "EjemploPlan.pdf",
      nombreFront: "Plan de cuidados semanal de María",
      contenido: "presentacion",
      pagina: 1,
    },
    {
      nombre: "GuiaBreve.pdf",
      nombreFront: "Guía breve para elaborar un plan de cuidados",
      contenido: "infografia",
      pagina: 1,
    },
    {
      nombre: "RecursosPlan.pdf",
      nombreFront: "Recursos para la creación de un plan de cuidados",
      contenido: "infografia",
      pagina: 1,
    },
    {
      nombre: "TareasImportantes.pdf",
      nombreFront: "Selección de tareas importantes para el plan de cuidados",
      contenido: "tarea",
      pagina: 1,
    },
    {
      nombre: "TareaRueda.pdf",
      nombreFront: "La rueda del cuidado",
      contenido: "tarea",
      pagina: 1,
    },
    {
      nombre: "TareaSemanal.pdf",
      nombreFront: "Creación de un plan semanal",
      contenido: "tarea",
      pagina: 1,
    },
  ];

  const titulosEstrategiasdelCuidadoenelHogar = [
    t("TeCuido.TextoIntroduccion.Tema3"),
    "Estrategias para el uso de productos de apoyo y la adaptación del hogar",
    "Herramientas y técnicas para el cuidado integral: desarrollo a nivel físico, cognitivo y social",
  ];
  const EstrategiasdelCuidadoenelHogar1 = [
    {
      nombre: "EstrategiasCuidado.pdf",
      nombreFront: "Estrategias del cuidado de otras personas",
      contenido: "presentacion",
      pagina: 1,
    },

    {
      nombre: "AdaptacionHogar.pdf",
      nombreFront: "Recomendaciones generales para la adaptación del hogar",
      contenido: "presentacion",
      pagina: 1,
    },
    {
      nombre: "GuiaBreveProducto.pdf",
      nombreFront: "Guía breve para seleccionar un producto de apoyo ",
      contenido: "infografia",
      pagina: 1,
    },
    {
      nombre: "RecomendacionesEstrategias.pdf",
      nombreFront: "Recomendaciones sobre las estrategias de cuidado",
      contenido: "infografia",
      pagina: 1,
    },

    {
      nombre: "TareaEstrategias.pdf",
      nombreFront: "Desarrollo de estrategias personalizadas",
      contenido: "tarea",
      pagina: 1,
    },
    {
      nombre: "MatComplTema3.pdf",
      nombreFront: "Material complementario",
      contenido: "material_complementario",
      pagina: 1,
    },
  ];

  const EstrategiasdelCuidadoenelHogar2 = [
    {
      nombre: "EstrategiasInstr.pdf",
      nombreFront:
        "Estrategias para las actividades instrumentales de la vida diaria ",
      contenido: "presentacion",
      pagina: 2,
    },
    {
      nombre: "EstrategiasFisico.pdf",
      nombreFront:
        "Estrategias para el cuidado de personas con limitaciones a nivel físico",
      contenido: "presentacion",
      pagina: 2,
    },
    {
      nombre: "EstrategiasCogn.pdf",
      nombreFront:
        "Estrategias para el cuidado de personas con limitaciones a nivel cognitivo",
      contenido: "presentacion",
      pagina: 2,
    },

    {
      nombre: "EstrategiasOcioTeCuido.pdf",
      nombreFront:
        "Estrategias de cuidado enfocadas a actividades ocio y recreativas",
      contenido: "infografia",
      pagina: 2,
    },
    {
      nombre: "EstrategiasComunicacion.pdf",
      nombreFront:
        "Estrategias en la comunicación para el cuidado de otras personas",
      contenido: "infografia",
      pagina: 2,
    },
  ];



  useEffect(() => {
    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if ((email.email, temaSeleccionado)) {
      switch (temaSeleccionado) {
        case "Recomendados":
          consulta_recomendados();
         
          setTema("Recomendados");
          setTitulo(titulosRecomendados);
          setAnterior(null);
          setSiguiente(null);
          break;
        case "Tema1.Video":
          setContenidos(PrincipiosdelCuidadodeOtrasPersonas);
   
          setTema("PRINCIPIOS_DEL_CUIDADO");
          setTitulo(titulosPrincipiosdelCuidadodeOtrasPersonas[0]);
          setSiguiente("/Tecuido/Tema1.1");
          setAnterior(null);
          break;
        case "Tema1.1":
          setContenidos(PrincipiosdelCuidadodeOtrasPersonas);
   
          setTema(null);
          setTitulo(titulosPrincipiosdelCuidadodeOtrasPersonas[1]);
          setSiguiente(null);
          setAnterior("/Tecuido/Tema1.Video");
          break;
        case "Tema1.2":
          // Agregar lógica para el subapartado Tema1.2
          break;
        case "Tema2.Video":
          setContenidos(PlanificaciondelCuidado);
    
          setTema("PLANIFICACIÓN_DEL_CUIDADO");
          setTitulo(titulosPlanificaciondelCuidado[0]);
          setSiguiente("/Tecuido/Tema2.1");
          setAnterior(null);
          break;
        case "Tema2.1":
          setContenidos(PlanificaciondelCuidado);
       
          setTema(null);
          setTitulo(titulosPlanificaciondelCuidado[1]);
          setSiguiente(null);
          setAnterior("/Tecuido/Tema2.Video");
          break;
        case "Tema3.Video":
          setContenidos(EstrategiasdelCuidadoenelHogar1);
         
          setTema("ESTRATEGIAS_CUIDADO");
          setTitulo(titulosEstrategiasdelCuidadoenelHogar[0]);
          setSiguiente("/Tecuido/Tema3.1");
          setAnterior(null);
          break;
        case "Tema3.1":
          setContenidos(EstrategiasdelCuidadoenelHogar1);
  
          setTema(null);
          setTitulo(titulosEstrategiasdelCuidadoenelHogar[1]);
          setSiguiente("/Tecuido/Tema3.2");
          setAnterior("/Tecuido/Tema3.Video");
          break;
        case "Tema3.2":
          setContenidos(EstrategiasdelCuidadoenelHogar2);
        
          setTema(null);
          setTitulo(titulosEstrategiasdelCuidadoenelHogar[2]);
          setSiguiente(null);
          setAnterior("/Tecuido/Tema3.1");
          break;
        default:
          break;
      }
    }
  }, [email, temaSeleccionado]);

  // useEffect(() => {
  //   // Verificar si el correo electrónico no es nulo antes de llamar a la función
  //   if (email.email) {
  //     consulta_recomendados();
  //   }
  // }, [email]);

  const asignarPaginas = (contenidos) => {
    const contenidosConPagina = contenidos.map((contenido, index) => {
      const pagina = Math.floor(index / 8);
      return { ...contenido, pagina };
    });
    return contenidosConPagina;
  };
  const consulta_recomendados = async () => {
    ///recomendaciones/otros
    try {
      const response = await axios.get(
        t("Recursos.ip") + "/recomendaciones/otros",
        {
          params: {
            email: email.email,
          },
        }
      );
      // console.log("Recomendaciones: ", response.data);
      const contenidosConPagina = asignarPaginas(
        response.data
      );
      setContenidos(contenidosConPagina);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const temaanterior = () => {
    navigate(anterior);
  };
  const temasiguiente = () => {
    navigate(siguiente);
  };
  return (
    <div>

      <TituloyFlecha
        title={t("Tecuido")}
        link={"/"}
      />

      <div className="botonesalienadosTecuido">
        <Link to="/Tecuido/Recomendados" style={{ flex: "1" }}>
          <button
            className={
              temaSeleccionado === "Recomendados"
                ? "botonTemaSeleccionadoTecuido"
                : "botonTemaDeseleccionadoTecuido"
            }
          >
            &nbsp; {t("Recomendados")} &nbsp;&nbsp;{" "}
            <FaStar style={{ marginBottom: "2%" }} /> &nbsp;
          </button>
        </Link>
        <Link to="/Tecuido/Tema1.Video" style={{ flex: "1" }}>
          <button
            className={
              temaSeleccionado === "Tema1.Video" ||
                temaSeleccionado === "Tema1.1"
                ? "botonTemaSeleccionadoTecuido"
                : "botonTemaDeseleccionadoTecuido"
            }
          >
            {t("TeCuido.Tema1")}
          </button>
        </Link>
        <Link to="/Tecuido/Tema2.Video" style={{ flex: "1" }}>
          <button
            className={
              temaSeleccionado === "Tema2.Video" ||
                temaSeleccionado === "Tema2.1"
                ? "botonTemaSeleccionadoTecuido"
                : "botonTemaDeseleccionadoTecuido"
            }
          >
            {t("TeCuido.Tema2")}
          </button>
        </Link>
        <Link to="/Tecuido/Tema3.Video" style={{ flex: "1" }}>
          <button
            className={
              temaSeleccionado === "Tema3.Video" ||
                temaSeleccionado === "Tema3.1" ||
                temaSeleccionado === "Tema3.2"
                ? "botonTemaSeleccionadoTecuido"
                : "botonTemaDeseleccionadoTecuido"
            }
          >
            {t("TeCuido.Tema3")}
          </button>
        </Link>
      </div>

      {window.innerWidth > 800 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            maxWidth: "70%",
            minWidth: "70%",
            margin: "auto",
            height: "75vh"
          }}
        >
          <button
            className="botoncircPaginacionTemas"
            onClick={() => temaanterior()}
            style={{
              marginRight: "5%",
              visibility: anterior ? "visible" : "hidden",
              height: "7vh",
              width: "7vh",
              verticalAlign: "center",
              margin: "auto",
              borderRadius: "30px"
            }}
          >
            <TfiArrowCircleLeft style={{ fontSize: "3vh" }} />

          </button>
          {temaSeleccionado === "Recomendados" ? (
            <PaginacionTemas
              contenidos={contenidos}
              tema={tema}
              titulosPagina={titulo}
            />
          ) : (
            <AgrupacionContenidos
              contenidos={contenidos}
              tema={tema}
              titulosPagina={titulo}
            />
          )}
          <button
            className="botoncircPaginacionTemas"
            onClick={() => temasiguiente()}
            style={{
              marginLeft: "5%",
              visibility: siguiente ? "visible" : "hidden",
              height: "7vh",
              width: "7vh",
              verticalAlign: "center",
              margin: "auto",
              borderRadius: "30px"
            }}
          >
            <TfiArrowCircleRight style={{ fontSize: "3vh" }} />

          </button>
        </div>
      ) : (
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: "100%",
          minWidth: "100%",
          margin: "auto"
        }}>

          {temaSeleccionado === "Recomendados" ? (
            <PaginacionTemas
              contenidos={contenidos}
              tema={tema}
              titulosPagina={titulo}
            />
          ) : (
            <AgrupacionContenidos
              contenidos={contenidos}
              tema={tema}
              titulosPagina={titulo}
            />
          )}
          <div style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "2%"
          }}>
            <button
              className="botoncircPaginacionTemas"
              onClick={() => temaanterior()}
              style={{
                marginRight: "5%",
                visibility: anterior ? "visible" : "hidden",
                height: "7vh",
                width: "7vh",
                verticalAlign: "center",
                margin: "auto",
                borderRadius: "30px"
              }}
            >
              <TfiArrowCircleLeft style={{ fontSize: "3vh" }} />

            </button>
            <button
              className="botoncircPaginacionTemas"
              onClick={() => temasiguiente()}
              style={{
                marginLeft: "5%",
                visibility: siguiente ? "visible" : "hidden",
                height: "7vh",
                width: "7vh",
                verticalAlign: "center",
                margin: "auto",
                borderRadius: "30px"
              }}
            >
              <TfiArrowCircleRight style={{ fontSize: "3vh" }} />

            </button>
          </div>

        </div>
      )}



    </div>
  );
};

export default Tecuido;

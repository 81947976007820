import React from "react";
import Recomendaciones_Icono from "../../Images/Recomendaciones_Icono.png";
import "./RecomendacionesCarta.css";
import Card from "../Card";
import { useTranslation } from "react-i18next";
function RecomendacionesCarta() {
  const [t] = useTranslation("global");
  return (
    <div>
      <div style={{ width: "75%", margin: "0 auto", marginTop: "-2rem" }}>
        <Card
          content={t("El-Cuidado")}
          alto="50%"
          ancho="100%"
          className={"home-card-header"}
        />
      </div>
      <div style={{ marginTop: "0.5vh" }}>
        {/* Texto en la parte superior */}
        <p style={{ color: "black" }}>{t("TeCuido.texto")}</p>

        <img
          style={{ marginBottom: "1vh" }}
          src={Recomendaciones_Icono}
          alt={t("TeCuido.Icono")}
          className="imagenRecomendacionesCarta"
        />
      </div>
    </div>
  );
}
export default RecomendacionesCarta;

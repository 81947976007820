import { Link, useLocation } from "react-router-dom";
import "./navbar.css";
import { useTranslation } from "react-i18next";
import { useEffect, useContext, useState, useCallback } from "react";
import Salud_Icono from "../Images/Datos_salud_Icono.png";
import Semanal_Icono from "../Images/Diario_Semanal_Icono.png";
import Autocuidado_Icono from "../Images/Autocuidado_Icono.png";
import Recomendaciones_Icono from "../Images/Recomendaciones_Icono.png";
import Preguntas_Icono from "../Images/informacion_blanco.png";
import Notificacion_Icono_leida from "../Images/notificacion_blanco.png";
import Notificacion_Icono_sin_leer from "../Images/notificacion_sin_leer_blanco.png";
import Evaluacion_Icono from "../Images/Evaluacion_Icono.png";
import Perfil_Icono from "../Images/usuario-de-perfil_blanco.png";
import LogoYLetra from "../Images/LogoYLetra.png";
import UserContext from "../UserContext";
import axios from "axios";

import { MdOutlineArrowDropUp } from "react-icons/md";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { set } from "date-fns";
import { tr } from "date-fns/locale";

const NavBarSuperior = () => {
  const [t] = useTranslation("global");

  const [Notificacion_Icono, setNotificacion_Icono] = useState(
    Notificacion_Icono_leida
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const email = useContext(UserContext);
  const { isAdmin } = useContext(UserContext);
  const location = useLocation();
  const[notifica, setNotifica] = useState(false);

  const consulta_notificaciones = useCallback(() => {
    axios
      .get(t("Recursos.ip") + "/notificacion/avisosnoleidos", {
        params: {
          email: email.email,
        },
      })
      .catch((error) => console.error("Error:", error))
      .then((response_durancion) => {
        if (response_durancion.data) {
          setNotificacion_Icono(Notificacion_Icono_sin_leer);
          setNotifica(true);
        }
      });
  }, [email.email, t]);

  const handleClickNotificaciones = () => {
    setNotificacion_Icono(Notificacion_Icono_leida);
    setNotifica(false);
  };

  useEffect(() => {
    // Verificar si el correo electrónico no es nulo antes de llamar a la función
    if (email.email) {
      consulta_notificaciones();
    }
  }, [consulta_notificaciones, email.email]);

  useEffect(() => {
    // Escucha el evento de cambio de tamaño de la ventana y actualiza el ancho de la ventana
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      // Limpia el evento de cambio de tamaño al desmontar el componente
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function cerrarMenu() {
    var navbar = document.getElementById("navbarNavDropdown");
    var bsCollapse = new window.bootstrap.Collapse(navbar);
    var estado = navbar.classList.contains("show"); // Verificar si el menú está abierto
    setMostrarOpciones(false);
    if (estado) {
      bsCollapse.hide(); // Si está abierto, lo cerramos
    } else {
      bsCollapse.show(); // Si está cerrado, lo abrimos
    }
  }

  const [mostrarOpciones, setMostrarOpciones] = useState(false);

  const toggleOpciones = () => {
    setMostrarOpciones(!mostrarOpciones);
  };

  // Función para determinar si un enlace está activo
  const isActiveLink = (path) => {
    return location.pathname.startsWith(path) ? "active-link" : "";
  };

  return (
    <>
      {windowWidth < 1000 ? (
        <nav className="navbar navbar-expand-lg custom-navbar">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              {/* <img
                  src={Logo}
                  alt={t("Logo")}
                  style={{ width: "2.5rem", marginRight:"10px" }}
                /> 
                 <img
                  src={LogoLetra}
                  alt={t("Logo-Letra")}
                  style={{ width: "10rem", marginTop: "10px" }}
                /> */}
              <img
                src={LogoYLetra}
                alt={t("Logo-Letra")}
                style={{ width: "11rem" }}
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              onClick={cerrarMenu}
            >
              <div className="notification-wrapper">
                <span className="navbar-toggler-icon"></span>
                {/* Círculo rojo de notificación */}
                {notifica && (<span className="notification-indicator"></span>)}
               
              </div>
            </button>

            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
                {" "}
                {/* Utilizamos ml-auto para alinear los elementos a la derecha */}
                {/* <li className="nav-item">
                              <Link className="nav-link active" aria-current="page" to='/'>Home</Link>
                          </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/MiSalud" onClick={cerrarMenu}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ margin: "auto" }}>
                        <img
                          src={Salud_Icono}
                          alt={t("Mis-Datos-de-Salud-Icono")}
                          style={{ width: "2.25rem" }}
                        />
                      </div>
                      <div style={{ margin: "auto" }}>
                        &nbsp;{t("Mis-Datos-de-Salud")}
                      </div>
                    </div>
                  </Link>
                </li>
                <li className="nav-item" onClick={cerrarMenu}>
                  <Link className="nav-link" to="/Cuestionario/9/intentos">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ margin: "auto" }}>
                        <img
                          src={Semanal_Icono}
                          alt={t("Mi-Diario-Semanal-Icono")}
                          style={{ width: "2.25rem" }}
                        />
                      </div>
                      <div style={{ margin: "auto" }}>
                        &nbsp;
                        {t("Mi-Diario-Semanal")}
                      </div>
                    </div>
                  </Link>
                </li>
                <li className="nav-item" onClick={cerrarMenu}>
                  <Link className="nav-link" to="/Mecuido/Recomendados">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ margin: "auto" }}>
                        <img
                          src={Autocuidado_Icono}
                          alt={t("Autocuidado-Icono")}
                          style={{ width: "2.25rem" }}
                        />
                      </div>
                      <div style={{ margin: "auto" }}>
                        &nbsp;
                        {t("Mecuido")}
                      </div>
                    </div>
                  </Link>
                </li>
                <li className="nav-item" onClick={cerrarMenu}>
                  <Link className="nav-link" to="/Tecuido/Recomendados">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ margin: "auto" }}>
                        <img
                          src={Recomendaciones_Icono}
                          alt={t("El-Cuidado-Icono")}
                          style={{ width: "2.25rem" }}
                        />{" "}
                      </div>
                      <div style={{ margin: "auto" }}>
                        &nbsp;
                        {t("El-Cuidado")}
                      </div>
                    </div>
                  </Link>
                </li>
                <li className="nav-item" onClick={cerrarMenu}>
                  <Link className="nav-link" to="/ListaCuestionario">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ margin: "auto" }}>
                        <img
                          src={Evaluacion_Icono}
                          alt={t("Evaluacion-Icono")}
                          style={{ width: "2.25rem" }}
                        />
                      </div>
                      <div style={{ margin: "auto" }}>
                        &nbsp;
                        {t("Evaluacion")}
                      </div>
                    </div>
                  </Link>
                </li>
                <li className="nav-item" onClick={cerrarMenu}>
                  <Link className="nav-link" to="/PreguntasFrecuentes">
                    <img
                      src={Preguntas_Icono}
                      alt={t("Preguntas-frecuentes-Icono")}
                      style={{ width: "2.25rem" }}
                    />{" "}
                    {t("Preguntas-frecuentes")}
                  </Link>
                </li>
                <li className="nav-item" onClick={cerrarMenu}>
                  <Link
                    className="nav-link"
                    to="/MisAvisos"
                    onClick={handleClickNotificaciones}
                  >
                    <img
                      src={Notificacion_Icono}
                      alt={t("Mis-avisos-Icono")}
                      style={{ width: "2.25rem" }}
                    />{" "}
                    {t("Mis-avisos")}
                  </Link>
                </li>
                {/* Nueva opción con un desplegable */}
                <li className="nav-item-apartado" style={{ color: "white" }}>
                  <span className="nav-link " onClick={toggleOpciones}>
                    <img
                      src={Perfil_Icono}
                      alt={t("Mi-apartado-Icono")}
                      style={{ width: "2.25rem" }}
                    />
                    {}
                    &nbsp;{t("Mi-apartado")}{" "}
                    {!mostrarOpciones ? (
                      <MdOutlineArrowDropDown />
                    ) : (
                      <MdOutlineArrowDropUp />
                    )}
                  </span>

                  {mostrarOpciones && (
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/miperfil"
                          onClick={cerrarMenu}
                        >
                          {t("Mi-perfil")}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/CuestionarioSocioDemo"
                          onClick={cerrarMenu}
                        >
                          {t("Informacion-personal")}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/cerrarsesion"
                          onClick={cerrarMenu}
                        >
                          {t("Cerrar-sesion")}
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
            <br></br>
          </div>
        </nav>
      ) : (
        <div className="d-flex justify-content-between">
          <nav className="navbar navbar-expand-lg custom-navbar">
            <div className="container-fluid">
              <Link className="navbar-brand" to="/">
                <img
                  src={LogoYLetra}
                  alt={t("Logo-Letra")}
                  style={{ width: "12rem" }}
                />
                {/* Personalmente creo que queda mejor con las imagenes separadas PERO no queda centrado ergo asi */}
                {/* <img
                  src={Logo}
                  alt={t("Logo")} <li className={`nav-item ${isActiveLink('/Mecuido')}`}>
                  style={{ width: "2.5rem",  marginRight:"10px" }}
                />
                <img
                  src={LogoLetra}
                  alt={t("Logo-Letra")}
                  style={{ width: "10rem", marginTop: "10px",marginRight:"20px" }}
                /> */}
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                style={{ width: "100%" }}
                id="navbarNavDropdown"
              >
                <ul className="navbar-nav ml-auto">
                  {" "}
                  {/* Utilizamos ml-auto para alinear los elementos a la derecha */}
                  {/* <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to='/'>Home</Link>
                        </li> */}
                  <li className={`nav-item ${isActiveLink("/MiSalud")}`}>
                    <Link className="nav-link" to="/MiSalud">
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {windowWidth > 1200 ? (
                          <div style={{ margin: "auto" }}>
                            <img
                              src={Salud_Icono}
                              alt={t("Mis-Datos-de-Salud-Icono")}
                              style={{ width: "2.25rem" }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        <div style={{ margin: "auto" }}>
                          &nbsp;{t("Mis-Datos-de-Salud")}
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${isActiveLink(
                      "/Cuestionario/9/intentos"
                    )}`}
                  >
                    <Link className="nav-link" to="/Cuestionario/9/intentos">
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {windowWidth > 1200 ? (
                          <div style={{ margin: "auto" }}>
                            <img
                              src={Semanal_Icono}
                              alt={t("Mi-Diario-Semanal-Icono")}
                              style={{ width: "2.25rem" }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        <div style={{ margin: "auto" }}>
                          &nbsp;
                          {t("Mi-Diario-Semanal")}
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className={`nav-item ${isActiveLink("/Mecuido")}`}>
                    <Link className="nav-link" to="/Mecuido/Recomendados">
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {windowWidth > 1200 ? (
                          <div style={{ margin: "auto" }}>
                            <img
                              src={Autocuidado_Icono}
                              alt={t("Autocuidado-Icono")}
                              style={{ width: "2.25rem" }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        <div style={{ margin: "auto" }}>
                          &nbsp;
                          {t("Mecuido")}
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className={`nav-item ${isActiveLink("/Tecuido")}`}>
                    <Link className="nav-link" to="/Tecuido/Recomendados">
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {windowWidth > 1200 ? (
                          <div style={{ margin: "auto" }}>
                            <img
                              src={Recomendaciones_Icono}
                              alt={t("El-Cuidado-Icono")}
                              style={{ width: "2.25rem" }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        <div style={{ margin: "auto" }}>
                          &nbsp;
                          {t("El-Cuidado")}
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${isActiveLink("/ListaCuestionario")}`}
                  >
                    <Link className="nav-link" to="/ListaCuestionario">
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {windowWidth > 1200 ? (
                          <div style={{ margin: "auto" }}>
                            <img
                              src={Evaluacion_Icono}
                              alt={t("Evaluacion-Icono")}
                              style={{ width: "2.25rem" }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        <div style={{ margin: "auto" }}>
                          &nbsp;
                          {t("Evaluacion")}
                        </div>
                      </div>
                    </Link>
                  </li>
                  {/* Fin de la nueva opción */}
                </ul>
              </div>
            </div>
          </nav>
          <nav className=" navbar-expand-lg custom-navbar-right">
            <div className="anchonav">
              <ul className="navbar-nav  ">
                <li
                  className={`nav-item ${isActiveLink("/PreguntasFrecuentes")}`}
                >
                  <Link className="nav-link" to="/PreguntasFrecuentes">
                    <div style={{ display: "flex", margin: "auto 5px" }}>
                      <div>
                        <img
                          src={Preguntas_Icono}
                          alt={t("Preguntas-frecuentes-Icono")}
                          style={{ width: "2.25rem" }}
                        />
                      </div>
                      <div style={{ margin: "auto" }}></div>
                    </div>
                  </Link>
                </li>
                <li className={`nav-item ${isActiveLink("/MisAvisos")}`}>
                  <Link
                    className="nav-link"
                    to="/MisAvisos"
                    onClick={handleClickNotificaciones}
                  >
                    <div style={{ display: "flex", margin: "auto 5px" }}>
                      <div>
                        <img
                          src={Notificacion_Icono}
                          alt={t("Mis-avisos-Icono")}
                          style={{ width: "2.25rem" }}
                        />
                      </div>
                      <div style={{ margin: "auto" }}></div>
                    </div>
                  </Link>
                </li>
                {/* Nueva opción con un desplegable */}
                <li
                  className={`nav-item ${isActiveLink(
                    "/miperfil"
                  )} ${isActiveLink("/CuestionarioSocioDemo")} ${isActiveLink(
                    "/cerrarsesion"
                  )}`}
                >
                  <a
                    className="nav-link "
                    href="/#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={toggleOpciones}
                  >
                    <img
                      src={Perfil_Icono}
                      alt={t("Mi-apartado-Icono")}
                      style={{ width: "2.25rem", marginLeft: "4px" }}
                    />
                    {windowWidth > 1200 ? (
                      <>
                        {" "}
                        &nbsp;{t("Mi-apartado")}
                        {!mostrarOpciones ? (
                          <MdOutlineArrowDropDown />
                        ) : (
                          <MdOutlineArrowDropUp />
                        )}
                      </>
                    ) : (
                      <>
                        {!mostrarOpciones ? (
                          <MdOutlineArrowDropDown />
                        ) : (
                          <MdOutlineArrowDropUp />
                        )}
                      </>
                    )}
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link className="dropdown-item" to="/miperfil">
                      {t("Mi-perfil")}
                    </Link>
                    <Link className="dropdown-item" to="/CuestionarioSocioDemo">
                      {t("Informacion-personal")}
                    </Link>
                    { isAdmin === true ?
                      <Link
                        className="dropdown-item"
                        to="/admin"
                      >
                        {t("Administración")}
                      </Link>
                    : <></>
                    }
                    <Link className="dropdown-item" to="/cerrarsesion">
                      {t("Cerrar-sesion")}
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      )}
    </>
  );
};

export default NavBarSuperior;

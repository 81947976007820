import React, { useEffect, useState } from "react";
import "./PreguntaRespuestaCuadrados.css";
import { useTranslation } from "react-i18next";
import "./CheckboxesCuestionarioSD.css";

const SingleOptionCheckboxSD = ({
  pregunta,
  aclaracion,
  respuestas,
  respuestaPrevia = "",
  onRespuestaSubmit,
}) => {
  const [windowWidth, ] = useState(window.innerWidth);
  const [t] = useTranslation("global");

  // Crear el objeto inicial para las respuestas seleccionadas y extraer "Otros" si está presente
  let respuestaSeleccionadaInicial = "";
  let otrosTextosIniciales = "";
  if (respuestaPrevia?.includes("Otros + ")) {
    // Extraer la parte específica de la respuesta "Otros"
    const [, especifico] = respuestaPrevia.split(" + ");
    respuestaSeleccionadaInicial = t("Otros")
    otrosTextosIniciales = especifico.trim();
  } else {
    respuestaSeleccionadaInicial = respuestaPrevia;
  }

  const [respuestaSeleccionada, setRespuestaSeleccionada] = useState(
    respuestaSeleccionadaInicial
  );
  const [otrosTextos, setOtrosTextos] = useState(otrosTextosIniciales);


  const handleRespuestaSubmit = (respuesta) => {
    if (respuesta === t("Otros") || respuesta === t("Otro")) {
      setRespuestaSeleccionada(respuesta);
      onRespuestaSubmit(pregunta, respuesta + " + " + otrosTextos);
    } else {
      setRespuestaSeleccionada(respuesta);
      onRespuestaSubmit(pregunta, respuesta);
    }
  };

  const handleOtrosTextChange = (text) => {
    setOtrosTextos(text);
    setRespuestaSeleccionada(t("Otros"));
    onRespuestaSubmit(pregunta, t("Otros") + " + " + text);
  };

  // Initial call to handleRespuestaSubmit with the initial value
  useEffect(() => {
    handleRespuestaSubmit(respuestaSeleccionada);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  let estiloRespuesta,
    estiloTabla,
    estiloPregunta,
    estiloRespuestasContainer,
    estiloSeparador,
    estiloLinea;

  if (window.innerWidth >= 768) {
    // Estilo para pantalla grande
    estiloRespuesta = {
      padding: "3px",
      marginBottom: "5px",
      marginRight: "15px",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "10%",
    };

    estiloTabla = {
      width: "100%",
      marginLeft: "4%",
    };

    estiloPregunta = {
      fontSize: "20px",
      whiteSpace: "pre-line",
      textAlign: "justify",
      marginLeft: "5%",
    };

    estiloRespuestasContainer = {
      textAlign: "justify",
      display: "flex",
    };

    estiloSeparador = {
      margin: "15px 0",
    };

    estiloLinea = {
      marginLeft: "5px",
      marginBottom: "5px",
    };
  } else {
    // Estilo para pantalla pequeña (móvil)
    estiloRespuesta = {
      padding: "5px",
      marginBottom: "5px",
      display: "flex",
      alignItems: "center",
      marginLeft: "5%"
    };

    estiloTabla = {
      width: "100%",
      marginLeft: "0%",
    };

    estiloPregunta = {
      fontSize: "18px",
      whiteSpace: "pre-line",
      textAlign: "justify",
      marginLeft: "5%",
      marginRight: "3%"
    };

    estiloSeparador = {
      margin: "15px 0",
    };

    estiloLinea = {
      marginLeft: "5px",
      marginBottom: "5px",
    };
  }

  return (
    <div>
      <div style={estiloPregunta}>
        <b>{pregunta}</b>
        {aclaracion}
      </div>
      <table style={estiloTabla}>
        <tbody>
          <tr>
            <td style={estiloRespuestasContainer}>
              {respuestas.map((respuesta, respuestaIndex) => (
                <label key={respuestaIndex} style={estiloRespuesta}>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="checkbox__input"
                      checked={
                        respuesta === respuestaSeleccionada
                      }
                      onChange={() =>
                        handleRespuestaSubmit(respuesta)
                      }
                    />
                    <span className="checkbox__inner"></span>
                  </label>

                  <div className="respuestaChecknoxes">
                    {windowWidth > 800 ? (
                      <>
                        {respuesta.split("\n").map((line, lineIndex) => (
                          <span key={lineIndex} style={estiloLinea}>
                            {line}
                          </span>
                        ))}
                      </>
                    ) : (
                      <span style={estiloLinea}>{respuesta}</span>
                    )}
                    {(respuesta === t("Otros") ||
                      respuesta === t("Otro")) &&
                      respuesta === respuestaSeleccionada && (
                        <input
                          type="text"
                          value={otrosTextos}
                          onChange={(e) =>
                            handleOtrosTextChange(e.target.value)
                          }
                          placeholder={t("Especifique") + "..."}
                          style={{ marginLeft: "10px", width: "150px" }}
                        />
                      )}
                  </div>
                </label>
              ))}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default SingleOptionCheckboxSD;

import React from "react";
import "./AyudaSincronizacion.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import TituloyFlecha from "../../components/TituloyFlecha";

const Ayuda = () => {

  const [t] = useTranslation("global");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="containerAyuda">
        <TituloyFlecha
          title={t("Ayuda")}
          link={"/Login"}
        />

        <div className="contenidoAyuda">
          
          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg1")}
          </h2>
          <p>{t("PreguntasFrecuentes.respuestas.resp1")}</p>
          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg6")}
          </h2>
          
          <p>
            {t("PreguntasFrecuentes.respuestas.resp6")}
            <Link
              to="/politica-privacidad"
              onClick={() => window.scrollTo(0, 0)}
              style={{ color: "#518ea6", textDecoration: "none" }}
            >
              {t("PreguntasFrecuentes.respuestas.resp6,1")}
            </Link>
          </p>
          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg4")}
          </h2>
          <p>{t("PreguntasFrecuentes.respuestas.resp4")}</p>
          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg2")}
          </h2>
          <p>{t("PreguntasFrecuentes.respuestas.resp2")}</p>
          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg7")}
          </h2>
          <p>
            {t("PreguntasFrecuentes.respuestas.resp7")}
            <span style={{ color: "#518ea6" }}>
              {" "}
              {t("PreguntasFrecuentes.respuestas.resp7,1")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Ayuda;

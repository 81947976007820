import React from "react";
import Autocuidado_Icono from "../../Images/Autocuidado_Icono.png";
import Card from "../Card";
import "./MiAutocuidadoCarta.css";
import { useTranslation } from "react-i18next";
function MiAutocuidadoCarta() {
  const [t] = useTranslation("global");
  return (
    <div>
      <div style={{ width: "75%", margin: "0 auto", marginTop: "-2rem" }}>
        <Card
          content={t("Mecuido")}
          alto="50%"
          ancho="100%"
          className={"home-card-header"}
        />
      </div>
      <div style={{ marginTop: "0.5vh" }}>
        {/* Texto en la parte superior */}
        <p style={{ color: "black" }}>{t("MeCuido.texto")}</p>

        <img
          style={{ marginBottom: "1vh" }}
          src={Autocuidado_Icono}
          alt={t("Mecuido-Icono")}
          className="imagenMiAutocuidadoCarta"
        />
      </div>
    </div>
  );
}

export default MiAutocuidadoCarta;

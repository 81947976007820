import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "./CircularProgressBar.css"
import { easeQuadInOut } from "d3-ease";
// import AnimatedProgressProvider from "./AnimatedProgressProvider"

import AnimatedProgressProvider from './AnimatedProgressProvider';



const CircularProgressBarPasos = ({ progress, maxValue, texto, colortexto, colorfondo, colorpath, ancho, alto }) => {
  const trailColor = "#FBECB2";  
  const formatearNumeroConPuntoDeMiles = (numero) => {
    if (numero === null) {
      return 0;
    } else{
    return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");}
  }
    // Verificar si progress es nulo o está vacío, y asignarle el valor 0 en ese caso



/*   const x = useMotionValue(0);
  const [xValue, setXValue] = useState(0);
  useEffect(() => {
    const controls = animate(x, progress, {
      duration: 1,
      ease: easeQuadInOut,
      onUpdate: (latestValue) => setXValue(Math.round(latestValue)),
    });
    return () => controls.stop();
  }, [x, progress]); */

  return (
    <div className="circular-progress-bar" style={{ width: ancho, height: alto }} > {/* Aplica la clase CSS al contenedor */}
      <AnimatedProgressProvider
        valueStart={0}
        valueEnd={progress}
        duration={1}
        easingFunction={easeQuadInOut}
      >
        {(value) => (
          <CircularProgressbar
            value={value}
            maxValue={maxValue}
            text={formatearNumeroConPuntoDeMiles(`${value}`)}
            background
            backgroundPadding={6}
            styles={
              buildStyles({
                backgroundColor: colorfondo,
                textColor: colortexto,
                pathColor: colorpath,
                trailColor: trailColor,
                pathTransition: "none"
              })}
          />
        )}
      </AnimatedProgressProvider>
    </div>

  );
}

export default CircularProgressBarPasos;
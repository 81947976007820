import React from "react";
import "./Ayuda.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import TituloyFlecha from "../../components/TituloyFlecha";

const PreguntasFrecuentes = () => {
  const [t] = useTranslation("global");
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <TituloyFlecha title={t("PreguntasFrecuentes.titulo")} link={"/"} />

        <div className="contenidoAyuda">
          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg9")}
          </h2>
          <p>{t("PreguntasFrecuentes.respuestas.resp9")}</p>
          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg8")}
          </h2>
          <p>{t("PreguntasFrecuentes.respuestas.resp8")}</p>
          <p>{t("PreguntasFrecuentes.respuestas.resp8,1")}</p>
          <p>{t("PreguntasFrecuentes.respuestas.resp8,2")} <a
            href="https://talionis.citic.udc.es/"
            rel="noopener noreferrer"
            style={{ color: '#518ea6' }}
          >
            Web talionis
          </a></p>

          <p>
            {t("PreguntasFrecuentes.respuestas.resp8,3")}
            <a
              href="https://talionis.citic.udc.es/"
              rel="noopener noreferrer"
            >
              Talionis
            </a>
            {t("PreguntasFrecuentes.respuestas.resp8,4")}
            <a
              href="https://lia2.es/"
              rel="noopener noreferrer"
              style={{ color: '#518ea6' }}
            >
              LIA[2]
            </a>
            &nbsp;
            <a
              href="https://citic.udc.es/"
              rel="noopener noreferrer"
              style={{ color: '#518ea6' }}
            >
              y CITIC
            </a>
          </p>
          <p>
            {t("PreguntasFrecuentes.respuestas.resp8,5")}

          </p>
          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg0")}
          </h2>
          <p>
            {t("PreguntasFrecuentes.respuestas.resp0")}
            <Link
              to="/AyudaSincronizacion"
              onClick={() => window.scrollTo(0, 0)}
            >
              {" "}
              <span
                style={{
                  color: "#518ea6",
                  background: "none",
                  border: "none",
                  padding: "0",
                  font: "inherit",
                  cursor: "pointer",
                }}
              >
                {t("Manual-Sincronizacion")}
              </span>
            </Link>
          </p>
          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg1")}
          </h2>
          <p>{t("PreguntasFrecuentes.respuestas.resp1")}</p>

          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg2")}
          </h2>
          <p>{t("PreguntasFrecuentes.respuestas.resp2")}</p>

          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg3")}
          </h2>
          <p>{t("PreguntasFrecuentes.respuestas.resp3")}</p>

          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg4")}
          </h2>
          <p>{t("PreguntasFrecuentes.respuestas.resp4")}</p>

          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg5")}
          </h2>
          <p>{t("PreguntasFrecuentes.respuestas.resp5")}</p>

          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg6")}
          </h2>
          <p>
            {t("PreguntasFrecuentes.respuestas.resp6")}
            <Link
              to="/politica-privacidad"
              onClick={() => window.scrollTo(0, 0)}
            >
              <span
                style={{
                  color: "#518ea6",
                  background: "none",
                  border: "none",
                  padding: "0",
                  font: "inherit",
                  cursor: "pointer",
                }}
              >{t("PreguntasFrecuentes.respuestas.resp6,1")}</span>
            </Link>
          </p>
          <h2 className="subtituloAyuda">
            {t("PreguntasFrecuentes.preguntas.preg7")}
          </h2>
          <p>
            {t("PreguntasFrecuentes.respuestas.resp7")}
            <span
              style={{
                color: "#518ea6",
                background: "none",
                border: "none",
                padding: "0",
                font: "inherit",
                cursor: "pointer",
              }}
            >
              {t("PreguntasFrecuentes.respuestas.resp7,1")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PreguntasFrecuentes;

import React, { useEffect, useState } from "react";
import RellenoRecomendados from "./RellenoRecomendados";
import { useTranslation } from "react-i18next";
import "./PaginacionTemas.css";
const PaginacionTemas = ({ contenidos, tema, titulosPagina }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [t] = useTranslation("global");
  const [pagina, setPagina] = useState(0);
  const [maxpagina, setMaxpagina] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (contenidos) {
      const maxPage = Math.max(
        ...contenidos.map((contenido) => contenido.pagina)
      );
      setMaxpagina(maxPage);
      setPagina(0);
    }
  }, [contenidos]);

  function cambiarPagina(n) {
    const newPage = pagina + n;
    if (newPage >= 0 && newPage <= maxpagina) {
      setPagina(newPage);
    }
  }

  return (
    <div className="paginacion-temas-container">
      {contenidos ? (
        <>
          <h3 className="tituloPaginacionTemas" dangerouslySetInnerHTML={{ __html: titulosPagina[pagina] }}></h3>
          <br></br>
          {/* {pagina === 0 && tema !== "Recomendados" && (
            <div className="video-containerPaginacionTemas">
              <ReactPlayer
                ref={setPlayer}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload", // Quita la opción de descargar
                      crossOrigin: "anonymous",
                    },
                    tracks: [
                      {
                        kind: "subtitles",
                        src:
                          process.env.PUBLIC_URL +
                          "/Videos/Subtitulos/" +
                          tema +
                          ".vtt",
                        srcLang: "español",
                        default: false,
                      },
                    ],
                  },
                }}
                url={process.env.PUBLIC_URL + "/Videos/" + tema + ".mkv"}
                light={process.env.PUBLIC_URL + "/Images/" + "DerechosPersonasCuidadoras.png"} // Miniatura del video
                playing={playing}
                loop={false}
                controls={true}
                onPause={handlePause}
                onPlay={handlePlay}
                className="videointroductorio-paginacion-temas"
              />
              {/* {showButton && !playing && windowWidth > 800 && (
                <button className="play-button" onClick={handlePlayPause}>
                  <FaPlay />
                </button>
              )} 
            </div>
          )} */}
          {windowWidth > 800 ? (
            <div>
              <div className="agrupacionMecuido">

                {contenidos
                  .filter((contenido) => contenido.pagina === pagina)
                  .slice(0, Math.min(4, contenidos.length))
                  .map((contenido, index) => (
                    <RellenoRecomendados
                      key={index}
                      nombreFront={contenido.nombreFront}
                      nombre={contenido.nombre}
                      contenido={contenido.contenido}
                    />
                  ))}

              </div>
              <div className="agrupacionMecuido">
                {contenidos.length > 4 &&
                  contenidos
                    .filter((contenido) => contenido.pagina === pagina)
                    .slice(4)
                    .map((contenido, index) => (
                      <RellenoRecomendados
                        key={index}
                        nombreFront={contenido.nombreFront}
                        nombre={contenido.nombre}
                        contenido={contenido.contenido}
                      />
                    ))}
              </div>
            </div>
          ) : (<div>
            <div className="agrupacionMecuido">
              {contenidos
                .filter((contenido) => contenido.pagina === pagina)
                .slice(0, Math.min(2, contenidos.length))
                .map((contenido, index) => (
                  <RellenoRecomendados
                    key={index}
                    nombreFront={contenido.nombreFront}
                    nombre={contenido.nombre}
                    contenido={contenido.contenido}
                  />
                ))}
            </div>
            <div className="agrupacionMecuido">
              {contenidos.length > 2 &&
                contenidos
                  .filter((contenido) => contenido.pagina === pagina)
                  .slice(2, Math.min(4, contenidos.length))
                  .map((contenido, index) => (
                    <RellenoRecomendados
                      key={index}
                      nombreFront={contenido.nombreFront}
                      nombre={contenido.nombre}
                      contenido={contenido.contenido}
                    />
                  ))}
            </div>
            <div className="agrupacionMecuido">
              {contenidos.length > 4 &&
                contenidos
                  .filter((contenido) => contenido.pagina === pagina)
                  .slice(4, Math.min(6, contenidos.length))
                  .map((contenido, index) => (
                    <RellenoRecomendados
                      key={index}
                      nombreFront={contenido.nombreFront}
                      nombre={contenido.nombre}
                      contenido={contenido.contenido}
                    />
                  ))}
            </div>
            <div className="agrupacionMecuido">
              {contenidos.length > 6 &&
                contenidos
                  .filter((contenido) => contenido.pagina === pagina)
                  .slice(6, Math.min(8, contenidos.length))
                  .map((contenido, index) => (
                    <RellenoRecomendados
                      key={index}
                      nombreFront={contenido.nombreFront}
                      nombre={contenido.nombre}
                      contenido={contenido.contenido}
                    />
                  ))}
            </div>

          </div>)}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <button
              className="botoncircPaginacionTemas"
              onClick={() => cambiarPagina(-1)}
              style={{
                marginRight: "5%",
                visibility: pagina > 0 ? "visible" : "hidden",
              }}
            >
              {t("Anterior")}
            </button>
            <button
              className="botoncircPaginacionTemas"
              onClick={() => cambiarPagina(1)}
              style={{
                marginLeft: "5%",
                visibility: pagina < maxpagina ? "visible" : "hidden",
              }}
            >
              {t("Siguiente")}
            </button>
          </div>
        </>
      ) : (
        <div style={{  marginTop: "20%" }}>
          <b>{t("Conectando-BD")}</b>
        </div>
      )}
    </div>
  );
};

export default PaginacionTemas;

import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

function MultipleOptionCheckboxSD({
  pregunta,
  aclaracion,
  respuestas,
  respuestasPrevias = "",
  onRespuestaSubmit,
}) {
  const [t] = useTranslation("global");

  const initialRespuestas = () => {
    const respuestaPreviasParsed = respuestasPrevias.split?.(" + ") || [];
    const acc = {};
    let otrosValue = "";

    respuestas.forEach(respuesta => {
      const isOtros = respuesta.toLowerCase() === "otros" || respuesta.toLowerCase() === "otro";
      if (isOtros && respuestaPreviasParsed.includes(respuesta)) {
        otrosValue = respuestaPreviasParsed[respuestaPreviasParsed.indexOf(respuesta) + 1] || "";
      }
      acc[respuesta] = respuestaPreviasParsed.includes(respuesta) && !isOtros;
    });

    if (otrosValue) {
      acc[t("Otros")] = otrosValue;
    }

    return acc;
  };

  const [respuestasSeleccionadas, setRespuestasSeleccionadas] = useState(initialRespuestas());
  const prevRespuestasSeleccionadasRef = useRef(respuestasSeleccionadas);
  const [respuestaOtros, setRespuestaOtros] = useState(initialRespuestas()[t("Otros")] || "");
  

  const handleRespuestaSubmit = useCallback((respuestasSeleccionadas) => {  
    // console.log("CheckboxesMultiplesCuestionariosSD - respuestasSeleccionadas: ", respuestasSeleccionadas);
    const seleccionadas = Object.keys(respuestasSeleccionadas)
      .filter( key => respuestasSeleccionadas[key]);
  
    if (seleccionadas.length === 0) {
      onRespuestaSubmit(pregunta, null);
      return;
    }
  
    // Obtener el texto del campo de texto "Otros" si está seleccionado
    const textoOtros = respuestasSeleccionadas[t("Otros")]?.trim?.() || "";
  
    onRespuestaSubmit(
      pregunta,
      seleccionadas.join(" + ") +
        (textoOtros ? (seleccionadas.length > 0 ? " + " : "") + textoOtros : "")
    );
  }, [onRespuestaSubmit, pregunta, t]);


  // Initial call to handleRespuestaSubmit with the initial value
  useEffect(() => {
    handleRespuestaSubmit(respuestasSeleccionadas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Trigger handleRespuestaSubmit when respuestasSeleccionadas changes
  useEffect(() => {
    // Only trigger the effect if respuestasSeleccionadas actually changed
    if (prevRespuestasSeleccionadasRef.current !== respuestasSeleccionadas) {
      handleRespuestaSubmit(respuestasSeleccionadas);
      prevRespuestasSeleccionadasRef.current = respuestasSeleccionadas;
    }
  }, [respuestasSeleccionadas, handleRespuestaSubmit]);

  const handleCheckboxChange = (respuesta) => {
    setRespuestasSeleccionadas((prevRespuestas) => ({
      ...prevRespuestas,
      [respuesta]: !prevRespuestas?.[respuesta],
    }));
  };

  const handleOtrosInputChange = (e) => {
    const valor = e.target.value;
    setRespuestaOtros(valor);
    setRespuestasSeleccionadas((prevRespuestas) => ({
      ...prevRespuestas,
        [t("Otros")]: valor, 
    }));
  };


  let estiloRespuesta,
    estiloTabla,
    estiloPregunta,
    estiloRespuestasContainer,
    estiloSeparador,
    estiloLinea;

  if (window.innerWidth >= 768) {
    // Estilo para pantalla grande
    estiloRespuesta = {
      padding: "5px",
      marginBottom: "5px",
      marginRight: "15px",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
    };

    estiloTabla = {
      width: "90%",
      marginLeft: "4%",
    };

    estiloPregunta = {
      fontSize: "20px",
      whiteSpace: "pre-line",
      textAlign: "justify",
      marginLeft: "5%",
    };

    estiloRespuestasContainer = {
      marginLeft: "4%",
      textAlign: "justify",
      display: "flex",
    };

    estiloSeparador = {
      margin: "15px 0",
    };

    estiloLinea = {
      marginLeft: "5px",
      marginBottom: "5px",
    };
  } else {
    // Estilo para pantalla pequeña (móvil)
    estiloRespuesta = {
      padding: "5px",
      marginBottom: "5px",
      marginRight: "15px",
      display: "flex",
      alignItems: "center",
    };

    estiloTabla = {
      width: "90%",
      marginLeft: "4%",
    };

    estiloPregunta = {
      fontSize: "20px",
      whiteSpace: "pre-line",
      textAlign: "justify",
      marginLeft: "5%",
    };

    estiloSeparador = {
      margin: "15px 0",
    };

    estiloLinea = {
      marginLeft: "5px",
      marginBottom: "5px",
    };
  }

  
  return (
    <div>
      <div style={estiloPregunta}>
        <b>{pregunta}</b>{aclaracion}
      </div>
      <table style={estiloTabla}>
        <tbody>
          <tr>
            <td style={estiloRespuestasContainer}>
              {respuestas.map((respuesta, respuestaIndex) => (
                <label key={respuestaIndex} style={estiloRespuesta}>
                  <input
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    checked={respuestasSeleccionadas[respuesta] || false}
                    onChange={() => handleCheckboxChange(respuesta)}
                  />
                  {respuesta.split("\n").map((line, lineIndex) => (
                    <span key={lineIndex} style={estiloLinea}>
                      {line}
                    </span>
                  ))}
                </label>
              ))}
              {respuestasSeleccionadas[t("Otros")] && (
                <div style={{ display: "flex", alignItems: "center", marginTop: "-0.5%" }}>
                  <input
                    type="text"
                    value={respuestaOtros}
                    onChange={(e) => handleOtrosInputChange(e)}
                    placeholder={t("Especifique") + "..."}
                    style={{ width:"150px" }}
                  />
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}


export default MultipleOptionCheckboxSD;

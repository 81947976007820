import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import * as d3TimeFormat from "d3-time-format";
import "./CustomBarChartSueno.css";
import { useTranslation } from "react-i18next";
import { timeFormatDefaultLocale } from "d3-time-format";

const CustomBarChartSueno = ({ data, dateDisplayType }) => {
  const chartRef = useRef();
  const [hoveredData, setHoveredData] = useState(null);
  const [t] = useTranslation("global");

  const locale = {
    dateTime: "%A, %e de %B de %Y, %X",
    date: "%d/%m/%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: [
      t("DiasSemana.Domingo"),
      t("DiasSemana.Lunes"),
      t("DiasSemana.Martes"),
      t("DiasSemana.Miercoles"),
      t("DiasSemana.Jueves"),
      t("DiasSemana.Viernes"),
      t("DiasSemana.Sabado"),
    ],
    shortDays: [
      t("shortDiasSemana.Domingo"),
      t("shortDiasSemana.Lunes"),
      t("shortDiasSemana.Martes"),
      t("shortDiasSemana.Miercoles"),
      t("shortDiasSemana.Jueves"),
      t("shortDiasSemana.Viernes"),
      t("shortDiasSemana.Sabado"),
    ],
    months: [
      t("Meses.Enero"),
      t("Meses.Febrero"),
      t("Meses.Marzo"),
      t("Meses.Abril"),
      t("Meses.Mayo"),
      t("Meses.Junio"),
      t("Meses.Julio"),
      t("Meses.Agosto"),
      t("Meses.Septiembre"),
      t("Meses.Octubre"),
      t("Meses.Noviembre"),
      t("Meses.Diciembre"),
    ],
    shortMonths: [
      t("shortMeses.Enero"),
      t("shortMeses.Febrero"),
      t("shortMeses.Marzo"),
      t("shortMeses.Abril"),
      t("shortMeses.Mayo"),
      t("shortMeses.Junio"),
      t("shortMeses.Julio"),
      t("shortMeses.Agosto"),
      t("shortMeses.Septiembre"),
      t("shortMeses.Octubre"),
      t("shortMeses.Noviembre"),
      t("shortMeses.Diciembre"),
    ],
  };
  timeFormatDefaultLocale(locale);

  useEffect(() => {
    if (!chartRef.current) return;

    const svg = d3.select(chartRef.current);
    svg.selectAll("*").remove();

    const margin = { top: 20, right: 20, bottom: 60, left: 40 };
    const width = 600 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    const parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S");

    const xScale = d3.scaleBand();

    if (dateDisplayType === "3") {
      const uniqueDates = data
        .map((d) => {
          const endDate = d.horaFin ? parseTime(d.horaFin) : null;
          const startDate = d.horaInicio ? parseTime(d.horaInicio) : null;
          const dateToUse = endDate || startDate;

          if (!dateToUse) {
            console.warn("Hora de inicio o fin no válida en el dato:", d);
            return null;
          }

          return d3TimeFormat.timeFormat("%m")(dateToUse); // Solo mes (número)
        })
        .filter(Boolean);

      const sortedMonths = [...new Set(uniqueDates)].sort();

      xScale.domain(sortedMonths).range([0, width]).padding(0.2);
    } else if (dateDisplayType === "2") {
      const firstDate = parseTime(data[0]?.horaFin || data[0]?.horaInicio);
      const daysInMonth = new Date(
        firstDate?.getFullYear() || new Date().getFullYear(),
        (firstDate?.getMonth() || 0) + 1,
        0
      ).getDate();
      const daysArray = Array.from({ length: daysInMonth }, (v, i) => i + 1);

      xScale.domain(daysArray).range([0, width]).padding(0.2);
    } else if (dateDisplayType === "1") {
      const uniqueDays = data.map((d) => {
        const endDate = parseTime(d.horaFin) || parseTime(d.horaInicio);
        const dayOfWeek = d3TimeFormat.timeFormat("%A")(endDate);
        const dayOfMonth = endDate.getDate();
        return `${dayOfWeek} ${dayOfMonth}`;
      });

      xScale.domain(uniqueDays).range([0, width]).padding(0.2);
    }

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.duracionTotal) + 100])
      .range([height, 0]);

    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const xAxis = d3.axisBottom(xScale);
    if (dateDisplayType === "3") {
      xAxis.tickFormat((d) => locale.months[parseInt(d, 10) - 1]);
    } else if (dateDisplayType === "2") {
      xAxis.tickFormat((d) => `${d}`);
    } else if (dateDisplayType === "1") {
      xAxis.tickFormat((d) => d);
    }

    chart
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => {
        const endDate = d.horaFin ? parseTime(d.horaFin) : null;
        const startDate = d.horaInicio ? parseTime(d.horaInicio) : null;
        const dateToUse = endDate || startDate;

        if (!dateToUse) {
          return null;
        }

        if (dateDisplayType === "3") {
          return xScale(d3TimeFormat.timeFormat("%m")(dateToUse));
        } else if (dateDisplayType === "2") {
          return xScale(dateToUse.getDate());
        } else {
          const dayOfWeek = d3TimeFormat.timeFormat("%A")(dateToUse);
          const dayOfMonth = dateToUse.getDate();
          return xScale(`${dayOfWeek} ${dayOfMonth}`);
        }
      })
      .attr("width", xScale.bandwidth())
      .attr("fill", "#BEAFFF")
      .attr("y", height)
      .attr("height", 0)
      .on("mouseover", function (event, d) {
        d3.select(this).attr("fill", "#756AB6");
        setHoveredData(d);
      })
      .on("mouseout", function () {
        d3.select(this).attr("fill", "#BEAFFF");
        setHoveredData(null);
      })
      .transition()
      .duration(1000)
      .attr("y", (d) => yScale(d.duracionTotal))
      .attr("height", (d) => height - yScale(d.duracionTotal));

    chart
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(xAxis)
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("transform", "rotate(-45)")
      .attr("dy", "0.5em")
      .attr("dx", "-0.5em");

    chart.append("g").call(d3.axisLeft(yScale));
  }, [data, dateDisplayType]);

  const convertirMinutosAHoras = (minutos) => {
    if (isNaN(minutos) || minutos < 0) {
      return "Formato de tiempo no válido";
    }

    const horas = Math.floor(minutos / 60);
    const minutosRestantes = Math.ceil(minutos % 60);

    if (minutos === 0) {
      return "0 h 0 min";
    }

    return `${horas} h ${minutosRestantes} min`.trim();
  };

  const parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S");

  const width = 600;
  const height = 425;

  return (
    <div className="anchoventana">
      <svg
        ref={chartRef}
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="xMidYMid meet"
        style={{ height: "100%", width: "100%" }}
      />

      <div style={{ fontSize: "15px" }}>
        {hoveredData ? (
          <div>
            <strong>{t("Duración total")}:</strong>{" "}
            {convertirMinutosAHoras(hoveredData.duracionTotal)} 
            <strong>{t("Sueño ligero")}:</strong>{" "}
            {convertirMinutosAHoras(hoveredData.duracionSuenoLigero)}
            <strong>{t("Sueño profundo")}:</strong>{" "}
            {convertirMinutosAHoras(hoveredData.duracionSuenoProfundo)} <br />
            <strong>{t("REM")}:</strong>{" "}
            {convertirMinutosAHoras(hoveredData.duracionSuenoREM)}
            <strong>{t("Despierto/a")}:</strong>{" "}
            {convertirMinutosAHoras(hoveredData.duracionDespierto)} <br />
            
            {dateDisplayType !== "3" ? (
              <div>
                <strong>{t("Hora de acostarse")}:</strong>{" "}
            {d3TimeFormat.timeFormat("%H:%M %-d %B")(
              parseTime(hoveredData.horaInicio)
            )}{" "}
           
            <strong>{t("Hora de despertarse")}:</strong>{" "}
            {d3TimeFormat.timeFormat("%H:%M %-d %B")(
              parseTime(hoveredData.horaFin)
            )}
            </div>):(<div> </div>)}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CustomBarChartSueno;

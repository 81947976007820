import React, { useState, useEffect } from "react";
import { BsTrash3, BsPencilSquare } from "react-icons/bs";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"; // Importa Link desde React Router
import { FaSquare } from "react-icons/fa";
import TituloyFlecha from "../../components/TituloyFlecha";

import "./usuariosAdmin.css";
import BorrarUsuarioModal from "./components/BorrarUsuarioModal";
import EtiquetaUsuarioAdmin from "./components/EtiquetaUsuarioAdmin";
import IdPilotoModal from "./components/IdPilotoModal";
import SelectEtiquetaUsuarioAdmin from "./components/SelectEtiquetaUsuarioAdmin";

const UsuariosAdmin = () => {
  const { t, i18n } = useTranslation("global");
  const locale = i18n.language;
  const [usuarios, setUsuarios] = useState([]);
  const [orden, setOrden] = useState("sueño"); // Estado para el criterio de ordenación
  const [isLoading, setIsLoading] = useState(true); // Estado para mostrar el indicador de carga
  const intlDate = new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const isValidDate = (dateString) => {
    return !isNaN(Date.parse(dateString));
  };

  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const updateTags = (newTags) => {
    const tagsToCreate = newTags.filter(tag => !tags.some(t => t.label === tag.label));
    const tagsToDelete = tags.filter(tag => !newTags.some(t => t.label === tag.label));

    tagsToCreate.forEach(tag => {
      axios.post(`${t("Recursos.ip")}/etiqueta`, {
        etiqueta: tag.label
      }).catch((error) => console.error("Error creating tag:", error))
      .then(response => {
        const createdTag = response.data;
        setTags((prevTags) => [...prevTags,
          { value: createdTag.id, label: createdTag.nombre }]);
      });
    });

    tagsToDelete.forEach(tag => {
      axios.delete(`${t("Recursos.ip")}/etiqueta/${tag.value}`)
        .catch((error) => console.error("Error deleting tag:", error))
        .then(() => {
          setTags((prevTags) => prevTags.filter(t => t.label !== tag.label));
        });
    });
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showIdPilotoModal, setShowIdPilotoModal] = useState(false);
  const [userSelected, setUserSelected] = useState(null);
  
  const checkIdInList = (usuario) => {
    return usuario.id_piloto && usuario.id_piloto.trim() !== "";
  };


  useEffect(() => {
    setIsLoading(true); // Muestra el indicador de carga
    axios.get(`${t("Recursos.ip")}/accounts/usuariosAdmin`)
      .catch((error) => console.error("Error al obtener los cuidadores:", error))
      .then((response) => {
        if (!response || !response.data) {
          return console.error("Respuesta vacía o sin datos");
        }
        setUsuarios(response.data.sort((a, b) => {
          if (a.fecha_sueno === null && b.fecha_sueno !== null) return 1;
          if (a.fecha_sueno !== null && b.fecha_sueno === null) return -1;
          if (a.fecha_sueno === null && b.fecha_sueno === null) return 0;
          return (
            new Date(isValidDate(b.fecha_sueno) ? b.fecha_sueno : 0) -
            new Date(isValidDate(a.fecha_sueno) ? a.fecha_sueno : 0)
          );
        }));
      }).finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  useEffect(() => {
      axios.get(`${t("Recursos.ip")}/etiqueta/findAll`)
      .catch((error) => console.error("Error al obtener los cuidadores:", error))
      .then((response) => {
        if (!response || !response.data) {
          return console.error("No se pudieron obtener las etiquetas");
        }
        setTags(response.data.map((tag) => ({ value: tag.id, label: tag.nombre })));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const handleOrder = (orden) => {
    setUsuarios((oldUsuarios) => {
      let cuidadoresOrdenados;

      switch (orden) {
        case "pasos":
          cuidadoresOrdenados = oldUsuarios.sort((a, b) => {
            if (a.fecha_pasos === null && b.fecha_pasos !== null) return 1;
            if (a.fecha_pasos !== null && b.fecha_pasos === null) return -1;
            if (a.fecha_pasos === null && b.fecha_pasos === null) return 0;
            return (
              new Date(isValidDate(b.fecha_pasos) ? b.fecha_pasos : 0) -
              new Date(isValidDate(a.fecha_pasos) ? a.fecha_pasos : 0)
            );
          });
          break;

        case "sueño":
          cuidadoresOrdenados = oldUsuarios.sort((a, b) => {
            if (a.fecha_sueno === null && b.fecha_sueno !== null) return 1;
            if (a.fecha_sueno !== null && b.fecha_sueno === null) return -1;
            if (a.fecha_sueno === null && b.fecha_sueno === null) return 0;
            return (
              new Date(isValidDate(b.fecha_sueno) ? b.fecha_sueno : 0) -
              new Date(isValidDate(a.fecha_sueno) ? a.fecha_sueno : 0)
            );
          });
          break;

        case "pulsaciones":
          cuidadoresOrdenados = oldUsuarios.sort((a, b) => {
            if (a.fecha_pulsaciones === null && b.fecha_pulsaciones !== null) return 1;
            if (a.fecha_pulsaciones !== null && b.fecha_pulsaciones === null) return -1;
            if (a.fecha_pulsaciones === null && b.fecha_pulsaciones === null) return 0;
            return (
              new Date(isValidDate(b.fecha_sueno) ? b.fecha_sueno : 0) -
              new Date(isValidDate(a.fecha_sueno) ? a.fecha_sueno : 0)
            );
          });
          break;

        case "nombre":
          // Ordenar alfabéticamente por nombre
          cuidadoresOrdenados = oldUsuarios.sort((a, b) =>
            a.nombre.localeCompare(b.nombre)
          );
          break;
        case "idPiloto":
          // Ordenar alfabéticamente por id_piloto, colocando los null o undefined al final
          cuidadoresOrdenados = oldUsuarios.sort((a, b) => {
            if (!a.id_piloto && b.id_piloto) return 1; // Si `a.id_piloto` es null o undefined, va al final
            if (a.id_piloto && !b.id_piloto) return -1; // Si `b.id_piloto` es null o undefined, `a` va antes
            if (!a.id_piloto && !b.id_piloto) return 0; // Ambos son null o undefined, son iguales

            // Extraer el prefijo y el número de id_piloto
            const regex = /([A-Za-z]+)(\d+)/; // Divide en letras y números
            const matchA = a.id_piloto.match(regex);
            const matchB = b.id_piloto.match(regex);

            if (matchA && matchB) {
              // Comparar prefijo primero (si es necesario)
              const prefixComparison = matchA[1].localeCompare(matchB[1]);
              if (prefixComparison !== 0) return prefixComparison;

              // Comparar los números de forma numérica
              const numA = parseInt(matchA[2], 10);
              const numB = parseInt(matchB[2], 10);
              return numA - numB;
            }

            // Si no coinciden con el patrón regex, comparar como texto normal
            return a.id_piloto.localeCompare(b.id_piloto);
          });
          break;

        case "id":
          cuidadoresOrdenados = oldUsuarios.sort((a, b) => a.id - b.id);
          break;

        default:
          cuidadoresOrdenados = oldUsuarios; // Si no coincide con ningún caso, no hace nada
          break;
      }

      return cuidadoresOrdenados;
    });
    setOrden(orden);
  };

  const download_Pasos_CSV = async (login,nombre, id) => {
    try {
      const response = await axios.get(
        t("Recursos.ip") + "/pasos/descargarPasos",
        {
          params: { cuidadorId: id },
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${id}_pasos.csv`;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar el archivo", error);
    }
  };

  const download_Sueno_CSV = async (login, nombre,id) => {
    try {
      const response = await axios.get(
        t("Recursos.ip") + "/sueno/descargarSuenos",
        {
          params: { email: login },
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${id}_sueño.csv`;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar el archivo", error);
    }
  };

  const download_Pulsaciones_CSV = async (login, nombre, id) => {
    try {
      const response = await axios.get(
        t("Recursos.ip") + "/pulsaciones/descargarPulsaciones",
        {
          params: { email: login },
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${id}_pulsaciones.csv`;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar el archivo", error);
    }
  };

  const download_estadoAnimo_CSV = async (id) => {
    try {
      const response = await axios.get(
        t("Recursos.ip") + "/estadoanimo/descargarEstadosAnimo",
        {
          params: { cuidadorId: id },
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${id}_estadoAnimo.csv`;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar el archivo", error);
    }
  };

  const formatDate = (value) => {
    if(!value) return "N/A";
    const date = new Date(value);
    if (isNaN(date)) return value;
    return intlDate.format(date);
  };

  function colorfecha(dateString, idPiloto = "") {
    try {
      const date = new Date(dateString);
      const currentDate = new Date();
      if (isNaN(date.getTime()))
        throw new Error("Fecha inválida");

      date.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);
      const diffInMs = currentDate - date;
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

      if(isDone(idPiloto)) return "on-time";

      if (diffInDays > 30)
        return "late-30";
      else if (diffInDays > 15)
        return "late-15";
      return "on-time";
    } catch (error) {
      return "on-time";
    }
  }

  const isDone = (idPiloto) => {
    if([
      "ADP1", "ADP2", "ADP3", "ADP4", "ADP5", "ADP6", "ADP7",
      "ADP8", "ADP9", "ADP10", "ADP11", "ADP12", "ADP13", "ADP14",
      "ADP15", "ADP16", "ASP7", "ASP5", "ASP3", "ASP6", "ASP1",
      "ASP2", "ASP4","ASP8","AFP2","AFP3","AFP4","AFP5","AFP6","AFP7","AFP8",
      "AFP10","APP1","APP4","APP5", "AFP26", "AFP27", "AFP28", "AFP29",
      "AFP9", "AFP12", "AFP14", "AFP15", "AFP16", "AFP17", "AFP18",
      "AFP19", "AFP23", "AFP11", "AFP24", "PAP2", "AFP22", "PAP1",
      "AFP13", "AFP20", "AFP21", "AFP25"
    ].includes(idPiloto))
      return "done";
    else return "";
  };

  return (
    <div>
      <TituloyFlecha title={t("Usuarios")} link={"/Admin"} />
      <div className="admin-page">
        <BorrarUsuarioModal
          userSelected={userSelected}
          setUserSelected={setUserSelected}
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
        />
        <IdPilotoModal
          userSelected={userSelected}
          setUserSelected={setUserSelected}
          showModal={showIdPilotoModal}
          setShowModal={setShowIdPilotoModal}
        />
        <div className="table-containerAdmin">
          {/* <p>Bienvenido a la sección de administración.</p> */}
          {isLoading ? (
            <div className="spinner"></div>
          ) : (
            <>
              <div className="table-settings">
                <div className="table-settings-row">
                  <div className="table-settings-item">
                    Antigüedad de datos:
                    {/* <FaSquare className="on-time" /> menos de 15 días */}
                    <FaSquare className="late-15" /> más de 15 días
                    <FaSquare className="late-30" /> más de 30 días
                  </div>
                  <div className="table-settings-item">
                    Número de usuarios:
                    <strong>
                      { usuarios.filter(
                        (usuario) => selectedTags.length === 0 ||
                          usuario?.etiquetas.some((tag) =>
                            selectedTags.some((selected) => selected.value === tag.id))
                      ).length }
                    </strong>
                  </div>
                </div>
                <div className="table-settings-row">
                  <div>
                    <label className="table-settings-item">
                      Filtrar por:
                      <SelectEtiquetaUsuarioAdmin
                        tags={tags}
                        selectedTags={selectedTags}
                        setSelectedTags={setSelectedTags}
                      />
                    </label>
                    <label className="table-settings-item">
                      Ordenar por:
                      <select
                        value={orden}
                        onChange={(e) => handleOrder(e.target.value)}
                      >
                        <option value="pasos">
                          Fecha de sincronización de pasos
                        </option>
                        <option value="sueño">
                          Fecha de sincronización de sueño
                        </option>
                        <option value="pulsaciones">
                          Fecha de sincronización de pulsaciones
                        </option>
                        <option value="nombre">Nombre</option>
                        <option value="idPiloto">ID del piloto</option>
                        <option value="id">ID del usuario</option>
                      </select>
                    </label>
                  </div>
                </div>
              </div>
              <div className="tableAdmin-wrapper">
                <table className="tableAdmin">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>ID Piloto</th>
                      <th>Nombre</th>
                      <th>Correo</th>
                      <th>Sueño</th>
                      <th>Pasos</th>
                      <th>Pulsaciones</th>
                      <th>Descargas de datos</th>
                      <th>Etiquetas</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usuarios
                      .filter(
                        (usuario) => selectedTags.length === 0 ||
                          usuario?.etiquetas.some((tag) => selectedTags.some((selected) => selected.value === tag.id))
                      )
                      .map((usuario) => (
                        <tr key={usuario.id} className={"" + isDone(usuario.id_piloto)}>
                          <td>{usuario.id}</td>
                          <td>{usuario.id_piloto}</td>
                          <td>{usuario.nombre}</td>
                          <td>{usuario.login}</td>
                          <td className={colorfecha(usuario.fecha_sueno, usuario.id_piloto)}>
                            {formatDate(usuario.fecha_sueno)}
                          </td>
                          <td className={colorfecha(usuario.fecha_pasos, usuario.id_piloto)}>
                            {formatDate(usuario.fecha_pasos)}
                          </td>
                          <td className={colorfecha(usuario.fecha_pulsaciones, usuario.id_piloto)}>
                            {formatDate(usuario.fecha_pulsaciones)}
                          </td>
                          <td>
                            <div className="row-downloads">
                              <Link to={`/IntentosAdmin/${usuario.id}`}>
                                <button>
                                  Formularios
                                </button>
                              </Link>
                              <button
                                onClick={() =>
                                  download_Sueno_CSV(
                                    usuario.login,
                                    usuario.nombre,
                                    usuario.id,
                                  )
                                }
                              >
                                Sueño
                              </button>
                              <button
                                onClick={() =>
                                  download_Pasos_CSV(    usuario.login,
                                    usuario.nombre,
                                    usuario.id,)
                                }
                              >
                                Pasos
                              </button>
                              <button
                                onClick={() =>
                                  download_Pulsaciones_CSV(
                                    usuario.login,
                                    usuario.nombre,
                                    usuario.id,
                                  )
                                }
                              >
                                Pulsaciones
                              </button>
                              <button
                                onClick={() =>
                                  download_estadoAnimo_CSV(
                                    usuario.id,
                                  )
                                }
                              >
                                Ánimo
                              </button>
                            </div>
                          </td>
                          <td>
                            <EtiquetaUsuarioAdmin
                              tags={tags}
                              setTags={updateTags}
                              user={usuario}
                            />
                          </td>
                          <td>
                            <div className="row-actions">
                              <button
                                className="action-delete"
                                onClick={() => {setUserSelected(usuario); setShowDeleteModal(true)}}
                              >
                                <BsTrash3 size={20}/>
                              </button>
                              <button
                                onClick={() => {setUserSelected(usuario); setShowIdPilotoModal(true)}}
                              >
                                <BsPencilSquare size={20}/>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UsuariosAdmin;

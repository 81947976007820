import { useEffect, useContext, useState } from "react";
import UserContext from "../../UserContext";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Card from "../../components/Card";
import Bars from "../../components/Graficos/BarsChart";
import { useTranslation } from "react-i18next";
import "./PasosPage.css";
import TituloyFlecha from "../../components/TituloyFlecha";

const Pasos = () => {
  const email = useContext(UserContext);

  const [chartData, setChartData] = useState(null); // Estado para controlar los datos del gráfico
  const [selectedDate, setSelectedDate] = useState();
  const [t] = useTranslation("global");
  const [pasosArray, setPasosArray] = useState([]);
  const [timestampsArray, setTimestampsArray] = useState(null);

  const [nfecha, setNfecha] = useState(0);
  const [distancia, setDistancia] = useState(null);
  const [tiempo, setTiempo] = useState(null);
  const { contenido } = useParams();

  const [finprocesado, setFinprocesado] = useState(false);

  const [colorFondoboton1, setColorFondoboton1] = useState("#b9ddd7");
  const [colorFondoboton2, setColorFondoboton2] = useState("#b9ddd7");
  const [colorFondoboton3, setColorFondoboton3] = useState("#b9ddd7");
  const [colorFondoboton4, setColorFondoboton4] = useState("#b9ddd7");

  const [colorTexto1, setColorTexto1] = useState("black");
  const [colorTexto2, setColorTexto2] = useState("black");
  const [colorTexto3, setColorTexto3] = useState("black");
  const [colorTexto4, setColorTexto4] = useState("black");

  const [arrayagrupadoano, setArrayagrupadoano] = useState(null);
  const [listadias, setListadias] = useState(null);
  const [valores_dia, setValores_dia] = useState(null);
  const [minutos_dia, setMinutos_dia] = useState(null);
  const [fecha, setFecha] = useState(null);
  const [periodicidad] = useState(30); //no sé por qué pero tienes que poner la mitad de lo que quieres que se muestre en el eje x :)
  const [textocuadro, setTextocuadro] = useState(t("Actividad-diaria-media"));
  const [diariossinagrupar, setDiariossinagrupar] = useState(null);

  const locale = {
    dateTime: "%A, %e de %B de %Y, %X",
    date: "%d/%m/%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: [
      t("DiasSemana.Domingo"),
      t("DiasSemana.Lunes"),
      t("DiasSemana.Martes"),
      t("DiasSemana.Miercoles"),
      t("DiasSemana.Jueves"),
      t("DiasSemana.Viernes"),
      t("DiasSemana.Sabado"),
    ],
    months: [
      t("Meses.Enero"),
      t("Meses.Febrero"),
      t("Meses.Marzo"),
      t("Meses.Abril"),
      t("Meses.Mayo"),
      t("Meses.Junio"),
      t("Meses.Julio"),
      t("Meses.Agosto"),
      t("Meses.Septiembre"),
      t("Meses.Octubre"),
      t("Meses.Noviembre"),
      t("Meses.Diciembre"),
    ],
    shortMonths: [
      t("shortMeses.Enero"),
      t("shortMeses.Febrero"),
      t("shortMeses.Marzo"),
      t("shortMeses.Abril"),
      t("shortMeses.Mayo"),
      t("shortMeses.Junio"),
      t("shortMeses.Julio"),
      t("shortMeses.Agosto"),
      t("shortMeses.Septiembre"),
      t("shortMeses.Octubre"),
      t("shortMeses.Noviembre"),
      t("shortMeses.Diciembre"),
    ],
  };

  useEffect(() => {
    if (contenido === "dia") {
      if (valores_dia && minutos_dia && finprocesado === true) {
        try {
          setChartData(getChartData());
        } catch (error) {
          setChartData(null);
        }
        
      }
    } else if (pasosArray && timestampsArray && finprocesado === true) {
      try {
        setChartData(getChartData());
      } catch (error) {
        setChartData(null);
      }
    }
  }, [
    valores_dia,
    minutos_dia,
    pasosArray,
    timestampsArray,
    finprocesado,
    nfecha,
  ]);

  useEffect(() => {
    if (contenido) {
      // Cambiar estilos y texto según el contenido
      switch (contenido) {
        case "semana":
          setColorFondoboton1("#b9ddd7");
          setColorFondoboton2("#518ea6");
          setColorFondoboton3("#b9ddd7");
          setColorFondoboton4("#b9ddd7");
          setColorTexto1("black");
          setColorTexto2("white");
          setColorTexto3("black");
          setColorTexto4("black");
          setTextocuadro(t("Actividad-diaria-media-semana"));
          break;
        case "mes":
          setColorFondoboton1("#b9ddd7");
          setColorFondoboton2("#b9ddd7");
          setColorFondoboton3("#518ea6");
          setColorFondoboton4("#b9ddd7");
          setColorTexto1("black");
          setColorTexto2("black");
          setColorTexto3("white");
          setColorTexto4("black");
          setTextocuadro(t("Actividad-diaria-media-mes"));
          break;
        case "ano":
          setColorFondoboton1("#b9ddd7");
          setColorFondoboton2("#b9ddd7");
          setColorFondoboton3("#b9ddd7");
          setColorFondoboton4("#518ea6");
          setColorTexto1("black");
          setColorTexto2("black");
          setColorTexto3("black");
          setColorTexto4("white");
          setTextocuadro(t("Actividad-diaria-media-ano"));
          break;
        default:
          setColorFondoboton1("#518ea6");
          setColorFondoboton2("#b9ddd7");
          setColorFondoboton3("#b9ddd7");
          setColorFondoboton4("#b9ddd7");
          setColorTexto1("white");
          setColorTexto2("black");
          setColorTexto3("black");
          setColorTexto4("black");
          setTextocuadro(t("Actividad-diaria-total"));
      }
    }

    // Verificar si el email no es nulo antes de hacer consultas
    if (email) {
      setFinprocesado(false);
      setNfecha(0);

      // Realizamos las consultas según el valor de contenido
      consulta_distancia();
      consulta_tiempo();
      if (contenido === "dia") {
        consulta_pasos();
      } else {
        consulta_pasos_agrupados();
      }
    }
  }, [email, contenido, t]); // Dependencias importantes

  const formatearNumeroConPuntoDeMiles = (numero) => {
    if (numero === null) {
      return 0;
    } else {
      return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  };

  const consulta_pasos = async () => {
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 730);

    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedOneWeekAgo = oneWeekAgo.toISOString().split("T")[0];

    try {
      const response = await axios.get(t("Recursos.ip") + "/pasos", {
        params: {
          email: email.email,
          startTime: formattedOneWeekAgo,
          endTime: formattedCurrentDate,
        },
      });

      console.log("Sin agrupar", response.data);

      // Obtener los arrays de pasos y timestamps de la respuesta
      const pasosArray = response.data.map((item) => item.numeropasos);
      const timestampsArray = response.data.map(
        (item) => new Date(item.timestamp)
      );

      // Dividir los timestamps por día
      const dividedTimestampArrays = divideDatesByDay(timestampsArray);

      // Actualizar los estados con los datos divididos
      setPasosArray(pasosArray);
      setTimestampsArray(timestampsArray);

      const listad = agruparPorDiaYOrdenar(response.data);
      setListadias(listad);
      setFecha(listad[0].fecha);
      const valores = obtenernumeropasosPorPosicion(listad, 0);
      setValores_dia(calcularSumaCadaIntervalo(valores, periodicidad));
      setMinutos_dia(minutosDelDiaConIntervalo(periodicidad));

      // console.log("dividedPasosArrays", dividedPasosArrays);

      setSelectedDate(Object.keys(dividedTimestampArrays)[nfecha]);
      setFinprocesado(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const consulta_pasos_agrupados = async () => {
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 720);

    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedOneWeekAgo = oneWeekAgo.toISOString().split("T")[0];

    try {
      const response = await axios.get(t("Recursos.ip") + "/pasos/grouped", {
        params: {
          email: email.email,
          startTime: formattedOneWeekAgo,
          endTime: formattedCurrentDate,
          granularidad: 1, // 1 por dias, 2 por semana y 3 por mes
        },
      });
      console.log("Agrupados", response.data);
      let arrayagrupado = null;
      switch (contenido) {
        case "semana":
          arrayagrupado = combinarArraysDias(
            generarArrayDiasaSemana(new Date()),
            response.data
          );
          setPasosArray(agruparPorSemanas(arrayagrupado, "numeropasos"));
          setTimestampsArray(
            convertirFormato(agruparPorSemanas(arrayagrupado, "timestamp"))
          );
          break;
        case "mes":
          // console.log("Meses", agruparPorMes(response.data));
          arrayagrupado = combinarArraysDias(
            generarArrayDias(UltimoDiaDelMes(new Date())),
            response.data
          );
          setPasosArray(agruparPorMeses(arrayagrupado, "numeropasos"));
          setTimestampsArray(
            convertirFormato(agruparPorMeses(arrayagrupado, "timestamp"))
          );
          break;
        case "ano":
          console.log("Ano", response.data);
          setDiariossinagrupar(response.data);
          arrayagrupado = agruparPorMes(response.data).reverse();
          setArrayagrupadoano(arrayagrupado);
          // console.log("Ano", arrayagrupado);
          setPasosArray(arrayagrupado[nfecha].map((obj) => obj.numeropasos));
          setTimestampsArray(convertirFormatoMes(arrayagrupado[nfecha], locale));
          break;
        default:
          arrayagrupado = combinarArraysDias(
            generarArrayDiasaSemana(new Date()),
            response.data
          );
          setPasosArray(agruparPorSemanas(arrayagrupado, "numeropasos"));
          setTimestampsArray(agruparPorSemanas(arrayagrupado, "timestamp"));
      }

      setFinprocesado(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function agruparPorMes(datos) {
    //ESTO SOLO VA CON 2024 ACTUALMENTE PERO QUEDAN 7 DIAS PARA EL PILOTO Y LO VA A CORREGIR QUIEN YO ME SÉ, pues al final me toco a mi xD
    // Inicializar un array de 24 elementos, uno para cada mes en un rango de dos años
    const pasosPorMes = Array(24).fill(0);

    // Obtener el año de inicio como el año anterior al del primer dato
    const añoInicio = new Date().getFullYear() - 1;

    datos.forEach((dato) => {
      const fecha = new Date(dato.timestamp);
      const mes = fecha.getMonth(); // Mes (0: enero, 1: febrero, ..., 11: diciembre)
      const año = fecha.getFullYear();

      // Calcular una clave única para el mes dentro del rango de dos años
      const clave = (año - añoInicio) * 12 + mes;

      // Sumar el número de pasos al mes correspondiente, asegurando que clave esté dentro del rango de 24 meses
      if (clave >= 0 && clave < 24) {
        pasosPorMes[clave] += dato.numeropasos;
      }
    });

    // Generar el resultado en el formato deseado como un array de arrays (dos años)
    const resultado = [
      pasosPorMes.slice(0, 12).map((totalPasos, indice) => {
        const fecha = new Date(añoInicio, indice + 1, 1); // Asegurando que los meses comiencen desde enero (indice + 1)
        return {
          numeropasos: totalPasos,
          timestamp: fecha.toISOString(),
        };
      }),
      pasosPorMes.slice(12, 24).map((totalPasos, indice) => {
        const fecha = new Date(añoInicio + 1, indice + 1, 1); // Asegurando que los meses comiencen desde enero (indice + 1)
        return {
          numeropasos: totalPasos,
          timestamp: fecha.toISOString(),
        };
      }),
    ];

    return resultado;
  }

  const consulta_distancia = async () => {
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 720);
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedOneWeekAgo = oneWeekAgo.toISOString().split("T")[0];

    try {
      const response = await axios.get(t("Recursos.ip") + "/distancia", {
        params: {
          email: email.email,
          startTime: formattedOneWeekAgo,
          endTime: formattedCurrentDate,
        },
      });
      console.log("Distancia: ", response.data);
      setDistancia(response.data);

      //setFecha(formatearFecha(response.data[0].fecha));
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const consulta_tiempo = async () => {
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 720);
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const formattedOneWeekAgo = oneWeekAgo.toISOString().split("T")[0];

    try {
      const response = await axios.get(t("Recursos.ip") + "/tiempoactivo", {
        params: {
          email: email.email,
          startTime: formattedOneWeekAgo,
          endTime: formattedCurrentDate,
        },
      });
      console.log("Tiempo: ", response.data);
      setTiempo(response.data);

      //setFecha(formatearFecha(response.data[0].fecha));
    } catch (error) {
      console.error("Error:", error);
    }
  };
  function convertirFormato(data) {
    const formattedData = data
      .map((arr) =>
        arr
          .map((timestamp) => {
            if (!timestamp) return undefined;

            const [fecha] = timestamp.split("T");
            const [año, mes, dia] = fecha.split("-");
            return `${dia}/${mes}/${año}`;
          })
          .filter((formattedTimestamp) => formattedTimestamp !== undefined)
      )
      .filter((arr) => arr.length > 0);
    return formattedData;
  }

  function agruparPorDiaYOrdenar(array) {
    // Utilizamos reduce para agrupar por día
    const agrupadoPorDia = array.reduce((resultado, elemento) => {
      // Extraemos la fecha del timestamp
      const fecha = new Date(elemento.timestamp).toLocaleDateString();

      // Si la fecha no está en el resultado, la inicializamos con un array vacío
      if (!resultado[fecha]) {
        resultado[fecha] = [];
      }

      // Agregamos el elemento al array correspondiente a la fecha
      resultado[fecha].push(elemento);

      return resultado;
    }, {});

    // Obtenemos las fechas únicas y las ordenamos de manera descendente
    const fechasOrdenadas = Object.keys(agrupadoPorDia).sort(
      (a, b) => new Date(b) - new Date(a)
    );

    // Creamos un array final ordenado por fecha
    const resultadoFinal = fechasOrdenadas.map((fecha) => ({
      fecha,
      datos: agrupadoPorDia[fecha],
    }));

    return resultadoFinal;
  }
  function obtenernumeropasosPorPosicion(listaCompleta, posicion) {
    // Obtener el conjunto de datos correspondiente a la posición indicada
    const datosDiaSeleccionado = listaCompleta[posicion]?.datos;

    if (datosDiaSeleccionado) {
      // Aplicar la función numeropasos_ultimodia al conjunto de datos del día seleccionado
      const resultadoDia = numeropasos_ultimodia(datosDiaSeleccionado);

      // Devolver el resultado
      return resultadoDia;
    } else {
      ////Console.log("No se encontraron datos para la posición indicada.");
      return [];
    }
  }
  function numeropasos_ultimodia(data) {
    // Obtén la fecha más reciente
    const latestDate = new Date(
      Math.max(...data.map((item) => new Date(item.timestamp)))
    );

    // Crea un objeto para almacenar los valores de datos para cada minuto del día
    const minuteData = {};

    // Llena el objeto con valores existentes de datos
    data.forEach((item) => {
      const itemDate = new Date(item.timestamp);
      if (itemDate.toDateString() === latestDate.toDateString()) {
        const hours = itemDate.getHours();
        const minutes = itemDate.getMinutes();
        const key = `${hours < 10 ? "0" + hours : hours}:${
          minutes < 10 ? "0" + minutes : minutes
        }`;
        minuteData[key] = item.numeropasos;
      }
    });

    // Crea el array resultante con 1440 elementos, rellenando con 0 cuando no hay datos
    const resultArray = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j++) {
        const key = `${i < 10 ? "0" + i : i}:${j < 10 ? "0" + j : j}`;
        const numeropasos = minuteData[key] || 0;
        resultArray.push({
          timestamp: `${latestDate.getFullYear()}-${
            latestDate.getMonth() + 1
          }-${latestDate.getDate()} ${key}:00.001`,
          numeropasos,
        });
      }
    }
    return resultArray;
  }
  const minutosDelDiaConIntervalo = (intervaloMinutos) => {
    const minutos = [];

    for (let hora = 0; hora < 24; hora++) {
      for (let minuto = 0; minuto < 60; minuto += intervaloMinutos) {
        const horaFormateada = hora < 10 ? `0${hora}` : `${hora}`;
        const minutoFormateado = minuto < 10 ? `0${minuto}` : `${minuto}`;
        minutos.push(`${horaFormateada}:${minutoFormateado}`);
      }
    }

    return minutos;
  };
  function calcularSumaCadaIntervalo(datos, minutosIntervalo) {
    const sumaArray = [];
    let sum = 0;

    for (let i = 0; i < datos.length; i++) {
      const valor = datos[i].numeropasos;

      // Si el valor no es 0, agrégalo a la suma
      if (valor !== 0) {
        sum += valor;
      }

      if (
        i === datos.length - 1 ||
        (i > 0 && (i + 1) % minutosIntervalo === 0)
      ) {
        const media = sum;
        sumaArray.push(media);
        sum = 0;
      }
    }

    return sumaArray;
  }
  function convertirFormatoMes(data, locale) {
    return data.map((obj) => {
      const timestamp = obj.timestamp;
      const [fecha] = timestamp.split("T"); // Extraer solo la parte de la fecha
      const [, mes] = fecha.split("-"); // Obtener solo el mes (segundo elemento)
      
      // Convertir el número del mes a un índice de 0 a 11 para acceder al array locale.months
      const nombreMes = locale.months[parseInt(mes, 10) - 1];
      
      return nombreMes;
    });
  }
  

  function agruparPorSemanas(datos, propiedad = "todos") {
    // Ordenar el array por timestamp
    datos.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    // Inicializar el array de resultado
    const resultado = [];

    // Iterar sobre los datos
    datos.forEach((dato) => {
      // Obtener el día de la semana (0: domingo, 1: lunes, ..., 6: sábado)
      const diaSemana = new Date(dato.timestamp).getDay();

      // Verificar si es lunes (día de la semana 1)
      if (diaSemana === 1) {
        // Inicializar el array para la nueva semana
        resultado.push([]);
      }

      // Determinar qué propiedad agregar al resultado
      if (propiedad === "todos") {
        // Si se solicitan todos los datos, agregar el objeto completo
        resultado[resultado.length - 1].push({ ...dato });
      } else {
        // Agregar la propiedad específica al resultado
        resultado[resultado.length - 1].push(dato[propiedad]);
      }
    });

    return resultado;
  }

  const UltimoDiaDelMes = (fecha) => {
    // Obtener el último día del mes
    const ultimoDia = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);

    return ultimoDia;
  };

  function agruparPorMeses(datos, propiedad = "todos") {
    // Ordenar el array por timestamp
    datos.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    // Inicializar el array de resultado para 24 meses (dos años)
    const resultado = Array.from({ length: 24 }, () => []);

    // Obtener el año de inicio a partir del primer dato
    const añoInicio = new Date(datos[0].timestamp).getFullYear();

    // Iterar sobre los datos
    datos.forEach((dato) => {
      // Obtener el mes y el año del dato
      const fecha = new Date(dato.timestamp);
      const mes = fecha.getMonth(); // Mes (0: enero, 1: febrero, ..., 11: diciembre)
      const año = fecha.getFullYear();

      // Calcular una clave única para el mes dentro de los dos años
      const clave = (año - añoInicio) * 12 + mes;

      // Determinar qué propiedad agregar al resultado
      if (propiedad === "todos") {
        // Si se solicitan todos los datos, agregar el objeto completo
        resultado[clave].push({ ...dato });
      } else {
        // Agregar solo la propiedad específica al resultado
        resultado[clave].push(dato[propiedad]);
      }
    });

    console.log("Resultado", resultado);
    return eliminarSubarraysVacios(resultado);
  }

  function eliminarSubarraysVacios(arr) {
    // Filtrar el array para eliminar los subarrays vacíos
    const resultado = arr.filter((subarray) => subarray.length > 0);

    // Determinar la longitud máxima de los subarrays no vacíos
    const longitudMaxima = resultado.reduce(
      (max, subarray) => Math.max(max, subarray.length),
      0
    );

    // Rellenar los subarrays con longitud menor que la máxima con subarrays vacíos
    resultado.forEach((subarray) => {
      while (subarray.length < longitudMaxima) {
        subarray.push(undefined); // Puedes cambiar undefined por cualquier valor deseado
      }
    });

    return resultado;
  }
  function generarArrayDiasaSemana(fechaActual) {
    const añoActual = fechaActual.getFullYear();
    const diaActual = fechaActual.getDay();
    const primerDiaAño = new Date(añoActual - 1, 0, 1);
    const primerLunesAño = new Date(
      añoActual - 1,
      0,
      1 + ((8 - primerDiaAño.getDay()) % 7)
    );
    const diasTranscurridos = Math.ceil(
      (fechaActual - primerLunesAño) / (24 * 60 * 60 * 1000)
    );
    const diasPorSemana = 7;
    const arrayDias = [];

    for (let i = 0; i < diasTranscurridos + diasPorSemana - diaActual; i++) {
      const fecha = new Date(primerLunesAño);
      fecha.setDate(fecha.getDate() + i);

      if (fecha.getFullYear() > añoActual) {
        break;
      }

      const year = fecha.getFullYear();
      const month = (fecha.getMonth() + 1).toString().padStart(2, "0");
      const day = fecha.getDate().toString().padStart(2, "0");
      const hours = "00";
      const minutes = "00";

      const timestamp = `${year}-${month}-${day}T${hours}:${minutes}:00`;

      arrayDias.push({
        numeropasos: 0,
        timestamp,
      });
    }
    console.log("ArrayDiasSemana", arrayDias);
    return arrayDias;
  }

  function generarArrayDias(fechaActual) {
    const añoActual = fechaActual.getFullYear();
    const primerDiaAño = new Date(añoActual - 1, 0, 1); // Enero del año anterior
    const arrayDias = [];

    for (
      let fecha = primerDiaAño;
      fecha <= fechaActual;
      fecha.setDate(fecha.getDate() + 1) // Incrementar día a día
    ) {
      const year = fecha.getFullYear();
      const month = (fecha.getMonth() + 1).toString().padStart(2, "0");
      const day = fecha.getDate().toString().padStart(2, "0");

      const timestamp = `${year}-${month}-${day}T00:00:00`;

      arrayDias.push({
        numeropasos: 0,
        timestamp,
      });
    }

    console.log("ArrayDias Mes", arrayDias);
    return arrayDias;
  }

  function combinarArraysDias(arrayAno, arrayDias) {
    // Iterar a través de cada semana en el array de semanas

    for (const dia of arrayAno) {
      // Encontrar el índice del día en el array de días
      const indiceDia = arrayDias.findIndex(
        (d) => d.timestamp === dia.timestamp
      );

      // Si se encuentra el día, sustituir el elemento
      if (indiceDia !== -1) {
        arrayAno[arrayAno.indexOf(dia)] = arrayDias[indiceDia];
      }
    }
    console.log("ArrayAno", arrayAno);
    return arrayAno;
  }

  function divideDatesByDay(datesArray) {
    const groupedDates = datesArray.reduce((result, date) => {
      const formattedDate = `${date.getDate()}-${
        date.getMonth() + 1
      }-${date.getFullYear()}`;
      const formattedTime = `${date.getHours()}:${date.getMinutes()}`;

      if (!result[formattedDate]) {
        result[formattedDate] = [];
      }

      result[formattedDate].push(formattedTime);

      return result;
    }, {});

    return groupedDates;
  }
  function cambiarNfecha(n) {
    const maxNfecha =
      contenido === "dia"
        ? (listadias?.length || 0) - 1
        : (pasosArray?.length || 0) - 1;

    if ((n < 0 && nfecha > 0) || (n > 0 && nfecha < maxNfecha)) {
      setNfecha(nfecha + n);

      if (
        contenido === "dia" &&
        listadias &&
        nfecha + n >= 0 &&
        nfecha + n < listadias.length
      ) {
        setFecha(listadias[nfecha + n].fecha);
        const valores = obtenernumeropasosPorPosicion(listadias, nfecha + n);
        setValores_dia(calcularSumaCadaIntervalo(valores, periodicidad));
      }
      if ( contenido==="ano" && arrayagrupadoano && nfecha + n >= 0 && nfecha + n < arrayagrupadoano.length) {
        setPasosArray(arrayagrupadoano[nfecha + n].map((obj) => obj.numeropasos));
        setTimestampsArray(convertirFormatoMes(arrayagrupadoano[nfecha + n], locale));

      }
    }
  }

  function obtenerAño(fecha) {
    try {
      const [, , year] = fecha.split("/"); // Desestructura y obtiene el año (tercer elemento)
      return parseInt(year, 10); // Convierte el año a número (base 10)
    } catch (error) {
      return null; // Devuelve null si hay un error
    }
  
  }

  function formatFecha(opcion) {
    if (nfecha < 0 || nfecha >= (timestampsArray?.length || 0)) {
      return ""; // Si nfecha está fuera de los límites, devolver una cadena vacía o un valor predeterminado.
    }

    switch (opcion) {
      case "semana":
        const currentYear = new Date().getFullYear(); // Obtener el año actual

        const parseDate = (dateString) => {
          const [day, month] = dateString.split("/").map(Number);
          return new Date(currentYear, month - 1, day); // Restamos 1 al mes ya que en JavaScript los meses van de 0 a 11
        };

        const startDate = parseDate(
          [...timestampsArray].reverse()[nfecha]?.[0] || ""
        );
        const endDate = parseDate(
          [...timestampsArray].reverse()[nfecha]?.[6] || ""
        );

        if (!startDate || !endDate) return ""; // Si las fechas no están definidas, devolver cadena vacía

        const startDay = startDate.getDate();
        const startMonth = locale.shortMonths[startDate.getMonth()];
        const endDay = endDate.getDate();
        const endMonth = locale.shortMonths[endDate.getMonth()];
        const year = obtenerAño([...timestampsArray].reverse()[nfecha][0]);

        return (
          <>
            {`${startDay} ${startMonth} - ${endDay} ${endMonth}`}
            <br />
            {year}
          </>
        );
      case "mes":
        console.log("Mes",  Array.isArray(timestampsArray), timestampsArray);
        return [...timestampsArray].reverse()[nfecha].map((label) => {
          //Esta linea es para ignora el warning pero realmente necesito dia para separarlo
          // eslint-disable-next-line no-unused-vars
          const [dia, mes] = label.split("/");
          const nombreMes = locale.months[parseInt(mes, 10) - 1];
          const year = obtenerAño([...timestampsArray].reverse()[nfecha][0]);
          return (
            <>
              {nombreMes} <br />
              {year}
            </>
          );
        })[0];
      

      case "ano":
        const fechaActual = new Date();
        const añoActual = fechaActual.getFullYear()-nfecha;
        return añoActual;

      default:
        return fecha;
    }
  }
  function obtenerUltimoValorValido(timestampsArray, nfecha) {
    const arrayInvertido = [...timestampsArray].reverse();
    const elementos = arrayInvertido[nfecha];

    // Verificamos los últimos 4 elementos del array en orden descendente
    for (let i = 1; i <= 4; i++) {
      const elemento = elementos[elementos.length - i];
      if (elemento !== undefined) {
        return elemento; // Retorna el primer valor que no es undefined
      }
    }

    return null; // Retorna null si todos son undefined
  }

  function distanciaMostrada() {
    let total;
    switch (contenido) {
      case "semana":
        total = mediaMetrosEnRango(
          distancia,
          convertirFormatoFecha([...timestampsArray].reverse()[nfecha][0]),
          convertirFormatoFecha(
            obtenerUltimoValorValido(timestampsArray, nfecha)
          )
        );
        break;
      case "mes":
        total = mediaMetrosEnRango(
          distancia,
          convertirFormatoFecha([...timestampsArray].reverse()[nfecha][0]),
          convertirFormatoFecha(
            obtenerUltimoValorValido(timestampsArray, nfecha)
          )
        );
        break;
      case "ano":
        total = mediaMetrosEnRango(
          distancia,
          obtenerDiaAño(false),
          obtenerDiaAño(true)
        );
        break;
      default:
        total = mediaMetrosEnRango(
          distancia,
          convertirFormatoFecha(formatFecha(contenido)),
          convertirFormatoFecha(formatFecha(contenido))
        );
    }

    return total === 0 ? null : total; // Si no hay datos, devolver null
  }

  function tiempoMostrado(array) {
    let diasConPasos = 0;
    if (contenido === "ano") {
      array = agruparPorMesDatos(diariossinagrupar);
    }
    array.forEach((valor) => {
      if (valor > 0) {
        diasConPasos++; // Incrementa el contador solo para días con pasos
      }
    });

    switch (contenido) {
      case "semana":
        return (
          sumarMinutosEnRango(
            tiempo,
            convertirFormatoFecha([...timestampsArray].reverse()[nfecha][0]),
            convertirFormatoFecha(
              obtenerUltimoValorValido(timestampsArray, nfecha)
            )
          ) / diasConPasos
        );

      case "mes":
        return (
          sumarMinutosEnRango(
            tiempo,
            convertirFormatoFecha([...timestampsArray].reverse()[nfecha][0]),
            convertirFormatoFecha(
              obtenerUltimoValorValido(timestampsArray, nfecha)
            )
          ) / diasConPasos
        );

      case "ano":
        return (
          sumarMinutosEnRango(
            tiempo,
            obtenerDiaAño(false),
            obtenerDiaAño(true)
          ) / diasConPasos
        );

      default:
        return sumarMinutosEnRango(
          tiempo,
          convertirFormatoFecha(formatFecha(contenido)),
          convertirFormatoFecha(formatFecha(contenido))
        );
    }
  }

  function agruparPorMesDatos(datos) {
    const yearActual = new Date().getFullYear()-nfecha; // Obtener el año actual
    
    return datos
      .filter((dato) => {
        const yearDato = new Date(dato.timestamp).getFullYear(); // Obtener el año del dato
        return yearDato === yearActual; // Filtrar solo los datos del año actual
      })
      .map((dato) => dato.numeropasos); // Obtener solo los valores de numeropasos de los datos filtrados
  }
  
  function pasosMostrado(array) {
    let total;
    let sumaPasos = 0;
    let diasConPasos = 0;
    if (contenido === "ano") {
      array = agruparPorMesDatos(diariossinagrupar);
    }

    array.forEach((valor) => {
      if (valor > 0) {
        // Considera solo los días con actividad
        sumaPasos += valor;
        diasConPasos++; // Incrementa el contador solo para días con pasos
      }
    });
    switch (contenido) {
      case "semana":
        total = sumaPasos / diasConPasos;
        break;
      case "mes":
        total = sumaPasos / diasConPasos;
        break;
      case "ano":
        total = sumaPasos / diasConPasos; //obtenerTotalDias(obtenerPosicionesConNumero(array));
        break;
      default:
        total = sumaPasos;
    }
    if (isNaN(total)) {
      total = 0;
    }

    return total;
  }

  function convertirFormatoFecha(fecha) {
    if (!fecha) return null; // o cualquier valor predeterminado que desees usar

    const fechaParts = fecha.split(/[/-]/); // Permitir tanto "/" como "-" como separadores

    if (fechaParts.length >= 2) {
      const [dd, mm, yyyy] = fechaParts;

      // Obtener el año actual si no se proporciona
      const year = yyyy
        ? yyyy.padStart(4, "0")
        : new Date().getFullYear().toString();

      // Asegurar que dd y mm tengan dos dígitos
      const ddFormatted = dd.padStart(2, "0");
      const mmFormatted = mm.padStart(2, "0");

      return `${year}-${mmFormatted}-${ddFormatted}`;
    } else {
      console.error("Error: formato de fecha no válido.");
      return null;
    }
  }

  function obtenerDiaAño(obtenerUltimoDia = false) {
    const fecha = new Date();
    const añoActual = fecha.getFullYear()-nfecha;
    const primerDia = new Date(añoActual, 0, 1);
    const ultimoDia = new Date(añoActual + 1, 0, 0);

    const formatoFecha = (date) => {
      const dd = String(date.getDate()).padStart(2, "0");
      const mm = String(date.getMonth() + 1).padStart(2, "0"); // Sumar 1 porque los meses van de 0 a 11
      const yyyy = date.getFullYear();
      return `${yyyy}-${mm}-${dd}`;
    };

    return obtenerUltimoDia ? formatoFecha(ultimoDia) : formatoFecha(primerDia);
  }

  function mediaMetrosEnRango(fechas, rangoInicio, rangoFin) {
    const formatoCorrecto = fechas.every((fecha) => {
      return /^\d{4}-\d{2}-\d{2}$/.test(fecha.fecha);
    });

    if (!formatoCorrecto) {
      console.error(
        "Error: El formato de las fechas en los datos no es correcto."
      );
      return 0; // Cambiado para devolver 0 en caso de error
    }

    const fechasEnRango = fechas.filter((fecha) => {
      return fecha.fecha >= rangoInicio && fecha.fecha <= rangoFin;
    });

    if (fechasEnRango.length === 0) {
      // Si no hay datos en el rango, retornar 0
      return 0;
    }

    let totalMetrosEnRango = 0;
    let conteoSumasNoCero = 0;

    fechasEnRango.forEach((fecha) => {
      totalMetrosEnRango += fecha.metros;
      if (fecha.metros !== 0) {
        conteoSumasNoCero += 1;
      }
    });

    return conteoSumasNoCero === 0 ? 0 : totalMetrosEnRango / conteoSumasNoCero;
  }

  function sumarMinutosEnRango(fechas, rangoInicio, rangoFin) {
    // Validar el formato de las fechas en los datos
    const formatoCorrecto = fechas.every((fecha) => {
      return /^\d{4}-\d{2}-\d{2}$/.test(fecha.fecha);
    });

    if (!formatoCorrecto) {
      console.error(
        "Error: El formato de las fechas en los datos no es correcto."
      );
      return 0;
    }

    // Filtrar las fechas que están en el rango
    const fechasEnRango = fechas.filter((fecha) => {
      return fecha.fecha >= rangoInicio && fecha.fecha <= rangoFin;
    });

    // Sumar los minutos para las fechas en el rango
    const totalMinutosEnRango = fechasEnRango.reduce((total, fecha) => {
      return total + fecha.minutos;
    }, 0);

    return totalMinutosEnRango;
  }

  function convertirMinutosAHoras(minutos) {
    if (isNaN(minutos) || minutos < 0) {
      return "0 min";
    }

    const horas = Math.floor(minutos / 60);
    const minutosRestantes = Math.floor(minutos % 60); // Redondeamos hacia abajo los minutos

    if (minutos === 0) {
      return "0 h 0 min";
    }

    let resultado = "";

    if (horas > 0) {
      resultado += `${horas} h `;
    }

    if (minutosRestantes > 0) {
      resultado += `${minutosRestantes} min`;
    }

    return resultado.trim(); // Eliminamos espacios al final si no hay minutos
  }

  const getChartData = () => {
    // Crear copias invertidas de los arrays
    let reversedLabels = null;
    let reversedPasosData = null;
    switch (contenido) {
      case "dia":
        return {
          labels: minutos_dia,
          datasets: [
            {
              label: t("Pasos"),
              data: valores_dia,
              tension: 0.5,
              fill: true,
              borderColor: "#F8DE22",
              backgroundColor: "#F8DE22",
              pointRadius: 1,
              pointBorderColor: "#F8DE22",
              pointBackgroundColor: "#F8DE22",
            },
          ],
        };

        case "semana":
          reversedLabels = [...timestampsArray]
            .reverse()[nfecha]
            .map((date) => {
              const [day, month, year] = date.split("/"); // Extraer día, mes, y año
              const fecha = new Date(`${year}-${month}-${day}`); // Crear un objeto de fecha
              const nombreDia = locale.days[fecha.getDay()]; // Obtener el nombre del día (usando locale)
              return `${nombreDia} ${day}`; // Combinar nombre del día y número
            });
          reversedPasosData = [...pasosArray].reverse()[nfecha];
          break;
        
      case "ano":
        
        reversedLabels = [...timestampsArray];
        reversedPasosData = [...pasosArray];

        break;
      default:
        reversedLabels = [...timestampsArray]
        .reverse()[nfecha]
        .map((date) => {
          const [day] = date.split("/"); // Solo obtener el día
          return day; // Retornar el número del día como etiqueta
        });
      
        reversedPasosData = [...pasosArray].reverse()[nfecha];
    }

    return {
      labels: reversedLabels,
      datasets: [
        {
          label: "Pasos",
          data: reversedPasosData,
          tension: 0.5,
          fill: true,
          borderColor: "#F8DE22",
          backgroundColor: "#F8DE22",
          pointRadius: 0.1,
          pointBorderColor: "#F8DE22",
          pointBackgroundColor: "#F8DE22",
        },
      ],
    };
  };

  const misoptions = {
    plugins: {
      legend: {
        display: false, // Deshabilita la leyenda (incluido el botón para ocultar líneas)
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 100,
        title: {
          display: true,
          text: t("Pasos"),
          color: "black",
        },
      },
      x: {
        title: {
          display: true,
          text:
            contenido === "dia"
              ? t("Hora")
              : contenido === "semana" || contenido === "mes"
              ? t("dia")
              : contenido === "ano"
              ? t("Mes")
              : "",
        },
        ticks: { color: "black" },
      },
    },
  };

  
  return (
    <div>
      <TituloyFlecha title={t("Actividad")} link={"/MiSalud"} />

      <div
        style={{
          marginTop: "1%",
          marginBottom: "1%",
          margin: window.innerWidth <= 768 ? "3%" : "1%",
        }}
      >
        <Link to="/MiActividadFisica/dia">
          <button
            className="botongranuralidadPasosPage"
            style={{ backgroundColor: colorFondoboton1, color: colorTexto1 }}
            onClick={() => {setChartData(null);setTimestampsArray(null);}}
            disabled={contenido==="dia"}
          >
            {t("Dia")}
          </button>
        </Link>
        <Link to="/MiActividadFisica/semana">
          <button
            className="botongranuralidadPasosPage"
            style={{ backgroundColor: colorFondoboton2, color: colorTexto2 }}
            onClick={() => {setChartData(null);setTimestampsArray(null);}}
            disabled={contenido==="semana"}
          >
            {t("Semana")}
          </button>
        </Link>
        <Link to="/MiActividadFisica/mes">
          <button
            className="botongranuralidadPasosPage"
            style={{ backgroundColor: colorFondoboton3, color: colorTexto3 }}
            onClick={() => {setChartData(null);setTimestampsArray(null);}}
            disabled={contenido==="mes"}
          >
            {t("Mes")}
          </button>
        </Link>
        <Link to="/MiActividadFisica/ano">
          <button
            className="botongranuralidadPasosPage"
            style={{ backgroundColor: colorFondoboton4, color: colorTexto4 }}
            onClick={() => {setChartData(null);setTimestampsArray(null);}}
            disabled={contenido==="ano"}
          >
            {t("Ano")}
          </button>
        </Link>
      </div>
      <div className="containerBodyPasos">
        <div className="containerGrafFechaPasos">
        {chartData &&((Array.isArray(timestampsArray)&& timestampsArray.length<30 && contenido==="mes" )|| contenido!=="mes")  ? (
            <div style={{ height: "100%", width: "100%" }}>
              <div className="centerPasosPage1">
                {(nfecha < Object.keys(timestampsArray || {}).length - 1 &&
                  contenido !== "dia" && contenido!=="ano") ||
                (nfecha < Object.keys(listadias || {}).length - 1 &&
                  contenido === "dia") ||  (Array.isArray(arrayagrupadoano) && nfecha < arrayagrupadoano.length - 1) ? (
                  <div style={{ visibility: "visible", marginRight: "12%" }}>
                    <button
                      className="botoncircPasosPage"
                      onClick={() => cambiarNfecha(1)}
                    >
                      {t("Anterior")}
                    </button>
                  </div>
                ) : (
                  <div style={{ visibility: "hidden" }}>
                    <button onClick={() => cambiarNfecha(1)}>
                      {t("Anterior")}
                    </button>
                  </div>
                )}
                <p
                  style={{
                    fontSize: "1.1rem",
                    color: "black",
                    width: "150%",
                    marginLeft: "-25%",
                  }}
                >
                  {<b>{formatFecha(contenido)}</b>}
                </p>
                {nfecha > 0 ? (
                  <div style={{ visibility: "visible", marginLeft: "12%" }}>
                    <button
                      className="botoncircPasosPage"
                      onClick={() => cambiarNfecha(-1)}
                    >
                      {t("Siguiente")}
                    </button>
                  </div>
                ) : (
                  <div style={{ visibility: "hidden" }}>
                    <button onClick={() => cambiarNfecha(-1)}>
                      {t("Siguiente")}
                    </button>
                  </div>
                )}
              </div>

              <div className="graficoPasosPage">
                <Bars data={chartData} options={misoptions} />
              </div>
            </div>
          ) : selectedDate ? (
            <p>
              {" "}
              <b>{t("Conectando-BD")}</b>{" "}
            </p>
          ) : (
            <p>
              {" "}
              <b> {t("Conectando-BD")}</b>{" "}
            </p>
          )}
        </div>
        <div className="centerPasosPage2" style={{ marginBottom: "2%" }}>
          <Card
            content={
              <div style={{ color: "black" }}>
                <h3
                  style={{ textAlign: "center", marginTop: "0.5%" }}
                  className="titulocardsPasosPage"
                >
                  {textocuadro}
                </h3>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1%",
                  }}
                >
                  <div style={{ flex: 1, textAlign: "left", marginLeft: "3%" }}>
                    <b> {t("Distancia")}</b>
                    <br />
                    {distancia && chartData ? (
                      <div>
                        {distanciaMostrada() !== null
                          ? (distanciaMostrada() / 1000).toLocaleString(
                              "es-ES",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : 0}{" "}
                        km{" "}
                      </div>
                    ) : (
                      <div>{t("Conectando-BD")}</div>
                    )}
                  </div>

                  <div
                    style={{
                      flex: 1,
                      textAlign: "right",
                      marginRight: "3%",
                    }}
                  >
                    <b>{t("Tiempo-activo")}</b>
                    <br />
                    {tiempo && chartData ? (
                      <div>
                        {convertirMinutosAHoras(
                          tiempoMostrado(chartData.datasets[0].data)
                        )}{" "}
                      </div>
                    ) : (
                      <div>{t("Conectando-BD")}</div>
                    )}
                  </div>
                </div>
                <div style={{ textAlign: "center", marginTop: "0%" }}>
                  <b>{t("Pasos")}</b>
                  <br />
                  {chartData ? (
                    <div>
                      {formatearNumeroConPuntoDeMiles(
                        pasosMostrado(
                          chartData.datasets[0].data
                        ).toLocaleString("es-ES", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                      )}{" "}
                    </div>
                  ) : (
                    <div>{t("Conectando-BD")}</div>
                  )}
                </div>
              </div>
            }
            className={"cardInfoPasosPage"}
          ></Card>
        </div>
      </div>
    </div>
  );
};
export default Pasos;
